import { Box, styled } from "@mui/material";

const LayerPoopover = styled(Box)(() => ({
  position: "absolute",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  padding: "8px",
  borderRadius: "8px",
  background: "#24263c",
  backdropFilter: "blur(25px)",
  fontSize: "12px",
  zIndex: 999,
}));

const MapLayerPopover: React.FC<{
  selectedMapObject: any;
  clickedCoordinates: any;
}> = ({ selectedMapObject, clickedCoordinates }) => {
  return (
    <>
      {selectedMapObject !== null && selectedMapObject?.properties?.portname ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          {selectedMapObject?.properties?.portname} Port
        </LayerPoopover>
      ) : null}
      {selectedMapObject !== null && selectedMapObject?.properties?.Country ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          {selectedMapObject?.properties?.Country}
        </LayerPoopover>
      ) : null}
      {selectedMapObject !== null &&
      selectedMapObject?.properties?.lastSignal &&
      selectedMapObject?.properties?.location ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          {selectedMapObject?.properties?.location} (AIS Station)
        </LayerPoopover>
      ) : null}
      {selectedMapObject !== null && selectedMapObject?.properties?.cable_id ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          <div>Submarine Cable</div>
          <div>Name: {selectedMapObject?.properties?.name}</div>
          <div>Length: {selectedMapObject?.properties?.length}</div>
          <div>Owners: {selectedMapObject?.properties?.owners}</div>
        </LayerPoopover>
      ) : null}
      {selectedMapObject !== null &&
      selectedMapObject?.properties?.Name &&
      selectedMapObject?.properties?.tessellate ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          <div>Submarine Cable</div>
          <div>Name: {selectedMapObject?.properties?.Name}</div>
        </LayerPoopover>
      ) : null}
      {selectedMapObject !== null &&
      selectedMapObject?.properties?.name &&
      selectedMapObject?.properties?.tessellate ? (
        <LayerPoopover
          sx={{
            left: clickedCoordinates.x,
            top: clickedCoordinates.y,
          }}
        >
          <div>{selectedMapObject?.properties?.name}</div>
        </LayerPoopover>
      ) : null}
    </>
  );
};
export default MapLayerPopover;

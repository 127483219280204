import React, { ReactNode } from "react";
import { Box, Button } from "@mui/material";
import styles from "./Sidebar.module.scss";

export type NavItem = {
  id: string;
  icon: React.ReactNode;
  component: React.ReactNode;
};

interface Props {
  menuList: NavItem[];
  active: number;
  onClick: (index: number) => void;
  children?: ReactNode;
}

const Sidebar = ({ menuList, active, onClick, children }: Props) => {
  return (
    <Box className={styles.sidebar}>
      <Box>
        <nav>
          <ul className={styles.sidebarIconsButtonWrap}>
            {menuList?.map((item, index) => {
              return (
                <li
                  aria-hidden="true"
                  key={index}
                  className={styles.sidebarIconsButton}
                >
                  <Button
                    sx={{
                      backgroundColor:
                        active === index ? "#111326" : "transparent",
                    }}
                    className={styles.sidebarButton}
                    onClick={() => onClick(index)}
                  >
                    {item.icon}
                  </Button>
                </li>
              );
            })}
          </ul>
        </nav>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
export default Sidebar;

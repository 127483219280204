import {
  Box,
  Checkbox,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Select,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from "@mui/icons-material";
import styles from "./SelectCheckbox.module.scss";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SelectCheckbox = ({
  id,
  options,
  title,
  onChange,
  disableOption = [],
  defaultDisable = false,
  value,
  ref,
  label,
  Unavailable,
}: any) => {
  const valuesList = Array.isArray(value)
    ? value.map(({ label }: any) => label)
    : [];
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      className={styles.shipFiltersDetail}
    >
      <span className={styles.shipFiltersLabel}>{title}</span>
      <Select
        id={id}
        multiple
        value={value.map((val: any) => val.label)}
        onChange={(event) => {
          event.stopPropagation();
          onChange && onChange(event.target.value);
        }}
        input={<OutlinedInput />}
        displayEmpty
        renderValue={(selected: any) => {
          if (selected.length === 0)
            return <p className={styles.shipFiltersPlaceholder}>{label}</p>;
          return selected.join(", ");
        }}
        className={styles.shipFilterSelect}
        classes={{
          select: styles.inputSelect,
          icon: styles.inputIcon,
        }}
        label={label}
        ref={ref}
        inputProps={{
          MenuProps: {
            PaperProps: {
              sx: {
                backgroundColor: "#26262c",
                border: "1px solid #FFFFFF40",
                boxShadow: "0px 4px 4px 0px #00000040",
              },
            },
          },
        }}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option.label}
            value={option.label}
            className={styles.shipFilterOptions}
            classes={{ selected: styles.shipFilterOptionsSelected }}
            disabled={
              (defaultDisable && disableOption.includes(option?.value)) ||
              !(defaultDisable || disableOption.includes(option?.type))
            }
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={valuesList.includes(option.label)}
              value={option.label}
              className={styles.checkboxMain}
              classes={{
                checked: styles.checkboxChecked,
              }}
            />
            <ListItemText
              className={styles.shipFilterOptionsText}
              primary={`${option.label}${
                Unavailable.includes(option.label) ? "(Unavailable)" : ""
              }`}
            />
          </MenuItem>
        ))}
        {!defaultDisable && disableOption.length !== 2 && (
          <p className={styles.shipFilterUnavailable}>
            Unavailable based on the data source(s) selected.
          </p>
        )}
      </Select>
    </Box>
  );
};

export default SelectCheckbox;

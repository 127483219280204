import { useEffect, useState } from "react";
import TextInput from "../../../../../components/TextInput/TextInput";
import Label from "../../../../../components/Label/Label";
import SelectInput from "../../../../../components/SelectInput/SelectInput";
import { FuturePathParams } from "../FuturePathPrediction";
import { useSnackbar } from "../../../../../context/SnackbarContext";
import { FUTURE_PATH_PREDICITION_TYPE } from "../../../../../utils/Constants";
import { Box, Button } from "@mui/material";
import styles from "./FuturePathPredictionModal.module.scss";

const FuturePathPredictionModal = ({
  futurePathParams,
  setFuturePathParams,
  open,
  setOpen,
  EditFuturePathParams,
  setEditFuturePathParams,
  handleSubmit,
}: any) => {
  const [futurePathFormData, setFuturePathFormData] =
    useState<FuturePathParams>({});
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (futurePathParams) setFuturePathFormData(futurePathParams);
  }, [futurePathParams]);

  const { setSnackbarMessage, setSnackbarSeverity, setIsSnackBarOpen } =
    useSnackbar();
  const handleChange = (name: string, value: string) => {
    setFuturePathFormData({
      ...futurePathFormData,
      [name]: value,
    });
    setIsSaved(true);
  };

  const handleSubmitFormData = () => setFuturePathParams(futurePathFormData);
  const formValidation = () => {
    const { lat, lon, heading, length, ship_type, speed } = futurePathFormData;

    const result = !(lat && lon && heading && length && ship_type && speed);
    if (result) {
      setSnackbarMessage("Please fill all the fields");
      setSnackbarSeverity("error");
      setIsSnackBarOpen(true);
    }
    return !result;
  };

  return (
    <Box className={styles.futurePathBody}>
      {open === "editFuturePath" && (
        <Box>
          {EditFuturePathParams && (
            <Box
              display="grid"
              gridTemplateColumns="repeat(2,1fr)"
              className={styles.EditPathMain}
            >
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Latitude" />
                <TextInput
                  id="latitude"
                  name="lat"
                  type="number"
                  value={futurePathFormData.lat}
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Longtitude" />
                <TextInput
                  id="longtitude"
                  name="lon "
                  type="number"
                  value={futurePathFormData.lon}
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Speed" />
                <TextInput
                  id="speed"
                  name="speed"
                  type="number"
                  value={futurePathFormData.speed}
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Length" />
                <TextInput
                  id="length"
                  name="length"
                  type="number"
                  value={futurePathFormData.length}
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
              >
                <Label label="Heading" />
                <TextInput
                  id="heading"
                  name="heading"
                  type="number"
                  value={futurePathFormData.heading}
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainInput}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.editMainWrapper}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Label label="Ship Type" />
                <SelectInput
                  id="shipType"
                  name="ship_type"
                  value={
                    futurePathFormData?.ship_type?.toLowerCase() || "other"
                  }
                  onChange={(e: any) =>
                    handleChange(e.target.name, e.target.value)
                  }
                  className={styles.editMainSelect}
                  optionsArray={FUTURE_PATH_PREDICITION_TYPE}
                />
              </Box>
              <Button
                variant="outlined"
                className={styles.editMainButton}
                onClick={() => {
                  setFuturePathFormData(futurePathParams);
                  setIsSaved(false);
                }}
              >
                Reset to Default
              </Button>
              {isSaved && (
                <Button
                  variant="outlined"
                  className={styles.editMainButton}
                  onClick={() => {
                    setEditFuturePathParams(false);
                    handleSubmitFormData();
                  }}
                >
                  Save & Hide Parameters
                </Button>
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (formValidation()) {
                    handleSubmit();
                    setOpen("futurePathLoader");
                    setEditFuturePathParams(false);
                  }
                }}
                className={styles.futureBodyViewButton}
              >
                View Future Path Prediction
              </Button>
              {!EditFuturePathParams && (
                <Button
                  variant="outlined"
                  className={styles.futureBodyEditButton}
                  onClick={() => {
                    setEditFuturePathParams(true);
                  }}
                >
                  Edit Parameters
                </Button>
              )}
            </Box>
            <p className={styles.futureBodyText}>
              This action could take upto a few minutes
            </p>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FuturePathPredictionModal;

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CustomSlider from "../../../../components/ShipTools/CustomSlider/CustomSlider";
import { Box } from "@mui/material";
import { PathData } from "../../MainMap";
import moment from "moment";

const PathPlayback = ({
  selectedPath,
  sliderValue,
  setSliderValue,
  pathRef,
}: {
  selectedPath: PathData[];
  sliderValue: number;
  setSliderValue: Dispatch<SetStateAction<number>>;
  pathRef: React.MutableRefObject<number | null>;
}) => {
  const [showPlayIcon, setShowPlayIcon] = useState<boolean | undefined>(true);
  const [playbackSpeed, setPlaybackSpeed] = useState<string>("600");
  const [intervalReference, setIntervalReference] =
    useState<NodeJS.Timeout | null>(null);
  const [valueChangedByDragging, setValueChangedByDragging] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      sliderValue === selectedPath.length - 1 ||
      sliderValue === selectedPath.length
    ) {
      clearInterval(intervalReference as NodeJS.Timeout);
      setIntervalReference(null);
    } else {
      if (!showPlayIcon && valueChangedByDragging) {
        clearInterval(intervalReference as NodeJS.Timeout);
        setIntervalReference(null);
        playSlider();
      } else {
        if (pathRef.current) {
          pathRef.current = sliderValue;
        }
      }
    }
    setValueChangedByDragging(false);
  }, [sliderValue]);

  useEffect(() => {
    if (intervalReference && !showPlayIcon) {
      clearInterval(intervalReference);
      setIntervalReference(null);
      playSlider();
    }
  }, [playbackSpeed]);

  const playSlider = () => {
    setShowPlayIcon(false);
    let newValue = sliderValue;
    const intervalRef = setInterval(() => {
      if (pathRef.current === null) {
        clearInterval(intervalRef);
        setSliderValue(() => 0);
        setIntervalReference(null);
        setPlaybackSpeed("600");
        setValueChangedByDragging(false);
        setShowPlayIcon(true);
        return;
      }
      setSliderValue(() => newValue + 1);
      newValue++;
    }, +playbackSpeed);
    setIntervalReference(intervalRef);
    if (pathRef.current === null) {
      pathRef.current = newValue;
    }
  };

  const pauseSlider = () => {
    setShowPlayIcon(true);
    clearInterval(intervalReference as NodeJS.Timeout);
  };

  const handlePlayPause = (play: boolean | undefined) => {
    play ? playSlider() : pauseSlider();
  };

  const handleSliderChange = (event: any, newValue: number) => {
    setValueChangedByDragging(true);
    setSliderValue(() => newValue);
  };

  const label = selectedPath[sliderValue]?.timestamp
    ? `${moment
        .utc(selectedPath[sliderValue]?.timestamp * 1000)
        .format("DD/MM/YYYY HH:mm:ss")} UTC`
    : "";

  return (
    <Box>
      <CustomSlider
        sliderTitle="Drag slider or click play to view ship's path playback."
        showPlaybackSpeed={true}
        playbackSpeed={playbackSpeed}
        handleChange={(speed: string) => {
          setPlaybackSpeed(speed);
        }}
        showPlayIcon={showPlayIcon}
        handleSliderChange={handleSliderChange}
        handlePlayPause={handlePlayPause}
        sliderValue={sliderValue}
        maxValue={selectedPath?.length - 1}
        label={label}
      />
    </Box>
  );
};

export default PathPlayback;

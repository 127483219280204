import { useState } from "react";
import { Box } from "@mui/material";
import EstimatedLocationLoader from "./EstimatedLocationLoader/EstimatedLocationLoader";
import EstimatedLocationSlider, {
  EstimatedLoactionErrorMessage,
} from "./EstimatedLocationSlider/EstimatedLocationSlider";
import SelectDateRangePicker from "../../../../components/SelectDateRangePicker/SelectDateRangePicker";
import dayjs from "dayjs";

export type EstimatedLocationOpenType =
  | "selectDate"
  | "openLoder"
  | "openSlider";

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

const EstimatedLocation: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [open, setOpen] = useState<EstimatedLocationOpenType>("selectDate");
  const handleSelectDateClick = () => setOpen("openLoder");
  const [datesWithNoLoactionData, setDatesWithNoLoactionData] = useState<
    string[]
  >([]);
  const [allDatesAreMissingLoactions, setAllDatesAreMissingLoactions] =
    useState(false);

  return (
    <Box>
      {open === "selectDate" && (
        <SelectDateRangePicker
          startDateTitle="Start Date"
          endDateTitle="End Date"
          buttonName="View"
          dateRange={dateRange}
          setDateRange={setDateRange}
          handleClick={handleSelectDateClick}
        />
      )}
      {open === "openLoder" && (
        <EstimatedLocationLoader
          setOpen={setOpen}
          open={open}
          dateRange={dateRange}
          setDatesWithNoLoactionData={setDatesWithNoLoactionData}
          setAllDatesAreMissingLoactions={setAllDatesAreMissingLoactions}
        />
      )}
      {open === "openSlider" && (
        <>
          {allDatesAreMissingLoactions &&
            dayjs(dateRange.startDate).isSame(dateRange.endDate) && (
              <EstimatedLoactionErrorMessage message="No data is available on the selected date" />
            )}
          <SelectDateRangePicker
            startDateTitle="Start Date"
            endDateTitle="End Date"
            buttonName="Update"
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleClick={handleSelectDateClick}
          />
          <EstimatedLocationSlider
            dateRange={dateRange}
            datesWithNoLocationData={datesWithNoLoactionData}
            allDatesAreMissingLoactions={allDatesAreMissingLoactions}
          />
        </>
      )}
    </Box>
  );
};

export default EstimatedLocation;

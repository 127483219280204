import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./MFA.module.scss";
import { Box, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../../App";
import { DocumentData, doc, getDoc, updateDoc } from "firebase/firestore";
import { api } from "../../services/api";

const MFA = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<DocumentData | null>(null);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const [userSecret, setUserSecret] = useState<string | null>(null);
  const [mfaCode, setMfaCode] = useState(Array(6).fill(""));
  const [mfaError, setMfaError] = useState<boolean>(false);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
        } else {
          console.log("No such document!");
        }
      } else {
        setUserInfo(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const registerForMFA = async () => {
    try {
      const response = await api.post("/mfa/register", {
        email: user?.email,
        uid: user?.uid,
      });
      setQrCodeUrl(response.data.qrCodeUrl);
      setUserSecret(response.data.userSecret)
    } catch (error) {
      setMfaError(true);
      console.error("Error registering for MFA:", error);
    }
  };

  const verifyUser = async (): Promise<void> => {
    if (user) {
      try {
        const response = await api.post("/mfa/verify", {
          uid: user?.uid,
          token: mfaCode.join(""),
          userSecret
        });
        if (response.data.valid) {
          localStorage.setItem("isMFAVerified", "true");
          navigate("/theia");
        } else {
          setMfaError(true);
        }
        console.log(
          response.data.valid ? "Login successful!" : "Login failed."
        );
      } catch (error) {
        setMfaError(true);
        console.error("Error logging in:", error);
      }
    }
  };

  const loginForMFA = async (): Promise<void> => {
    if (user) {
      try {
        const response = await api.post("/mfa/login", {
          uid: user?.uid,
          token: mfaCode.join(""),
        });
        if (response.data.valid) {
          localStorage.setItem("isMFAVerified", "true");
          navigate("/theia");
        } else {
          setMfaError(true);
        }
        console.log(
          response.data.valid ? "Login successful!" : "Login failed."
        );
      } catch (error) {
        setMfaError(true);
        console.error("Error logging in:", error);
      }
    }
  };

  useEffect(() => {
    if (user && userInfo && !userInfo?.secret) {
      registerForMFA();
    }
  }, [userInfo, user]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setMfaError(false);
    const value = e.target.value;
    if (/^\d{0,1}$/.test(value)) {
      const updatedCode = [...mfaCode];
      updatedCode[index] = value;
      setMfaCode(updatedCode);

      if (value !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLElement>, index: number) => {
    if (e.key === "Backspace" && index > 0 && mfaCode[index] === "") {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <Box>
      <form
        className={styles.detailInputWrapper}
        onSubmit={(e) => {
          e.preventDefault();
          qrCodeUrl ? verifyUser() : loginForMFA();
        }}
      >
        <Box className={styles.inputWrapperMain}>
          <Box className={styles.inputWrapper}>
            <Typography variant="h6" sx={{ fontSize: "22px", color: "white" }}>
              MFA Required
            </Typography>
            {qrCodeUrl && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  maxWidth: 400,
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "14px",
                    marginBottom: "7px",
                    marginTop: "7px",
                  }}
                >
                  Scan the QR code with your authenticator App
                </Typography>
                <img
                  className={styles.qrCodeImg}
                  src={qrCodeUrl}
                  alt="QR Code"
                />
              </Box>
            )}
            <Typography
              sx={{
                textAlign: "center",
                color: "white",
                fontSize: "14px",
                marginTop: "12px",
              }}
            >
              Enter Code generated by your authenticator app.
            </Typography>
            <Box
              className={styles.textFieldWrapper}
              sx={{
                margin: qrCodeUrl ? "0px 0px 13px 0px" : "15px 0px 13px 0px",
              }}
            >
              {mfaCode.map((digit, index) => (
                <Box
                  className={
                    mfaError ? styles.errorTextField : styles.textField
                  }
                  key={index}
                  style={{ marginRight: index === 2 ? "10px" : "0" }}
                >
                  <TextField
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: {
                        textAlign: "center",
                        height: "40px",
                        color: "white",
                        backgroundColor: "#080913",
                      },
                    }}
                    autoComplete="off"
                    id={`code-${index}`}
                    type="text"
                    name={`code-${index}`}
                    value={digit}
                    variant="standard"
                    onChange={(e) => handleChange(e, index)}
                    inputRef={(e) => (inputRefs.current[index] = e)}
                    onKeyDown={(e) => handleKeyPress(e, index)}
                  />
                </Box>
              ))}
            </Box>
            {mfaError && (
              <Typography sx={{ color: "red", marginTop: "-12px" }}>
                Invalid MFA Code
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={styles.submitButton}>
          <LoadingButton
            variant="outlined"
            className={styles.buttonWrap}
            type="submit"
          >
            {mfaCode.every((digit) => digit !== "")
              ? "Submit"
              : `${6 - mfaCode.filter((digit) => digit).length} digits left`}
          </LoadingButton>
          {/* {errMessage && (
            <p className={styles.errorMessage}>
              {errMessage}
              <InfoOutlined />
            </p>
          )} */}
        </Box>
      </form>
    </Box>
  );
};

export default MFA;

const ListenForOutsideClicks = ({
  listening,
  setListening,
  menuRef,
  handleOutsideClick,
}: any) => {
  return () => {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    const handleClickOutside = (evt: any) => {
      const cur = menuRef?.current;
      const node = evt.target;
      if (cur && !cur.contains(node) && handleOutsideClick) {
        handleOutsideClick();
      }
    };

    [`click`, `touchstart`].forEach((type) => {
      document.addEventListener(type, handleClickOutside);
    });

    return () => {
      [`click`, `touchstart`].forEach((type) => {
        document.removeEventListener(type, handleClickOutside);
      });
    };
  };
};

export default ListenForOutsideClicks;

import React from "react";
import { BunkeringInterface, ShipDetailsInterFace } from "../MainMap";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import TabsWithTable from "../../../components/Sidebar/TabsWithTable";

interface ShipProps {
  ships: { [key: string]: ShipDetailsInterFace | BunkeringInterface };
  handleTableRowClick: (id: string, type: string, tabIndex?: string) => void;
  getEventDetails: (eventType: string) => {
    name: string;
    color: string;
  };
}

const isBunkeringInterface = (
  ship: ShipDetailsInterFace | BunkeringInterface
): ship is BunkeringInterface => {
  return (
    "synmax_ship_id_1" in ship &&
    "synmax_ship_id_2" in ship &&
    ship.synmax_ship_id_1 !== undefined &&
    ship.synmax_ship_id_2 !== undefined
  );
};

const MyShips: React.FC<ShipProps> = ({
  ships,
  handleTableRowClick,
  getEventDetails,
}) => {
  const columns = ["name", "ctry", "type", "port", "event"];

  const mapShipToRow = (
    ship: ShipDetailsInterFace | BunkeringInterface
  ):
    | { [key: string]: string | number | React.ReactNode }
    | { [key: string]: string | number | React.ReactNode }[] => {
    const ship1EventDetails =
      ship.type === "opticalSTS" && ship?.ship1_status
        ? getEventDetails(ship?.ship1_status.toLowerCase() || "unknown")
        : getEventDetails(ship.type || "unknown");
    const ship2EventDetails =
      ship.type === "opticalSTS" && ship?.ship2_status
        ? getEventDetails(ship?.ship2_status.toLowerCase() || "unknown")
        : getEventDetails(ship.type || "unknown");

    if (isBunkeringInterface(ship)) {
      return [
        {
          id: ship.synmax_ship_id,
          name: ship.synmax_ship_id_1
            ? ship.type === "opticalSTS" && !ship[ship.synmax_ship_id_1]?.name
              ? ship[ship.synmax_ship_id_1]?.object_id
              : ship[ship.synmax_ship_id_1]?.name
            : "N/A",
          ctry:
            ship.synmax_ship_id_1 && ship[ship.synmax_ship_id_1]?.flag ? (
              <FlagIcon
                size={20}
                code={ship[ship.synmax_ship_id_1].flag as FlagIconCode}
              />
            ) : (
              "---"
            ),
          type: ship.synmax_ship_id_1
            ? ship[ship.synmax_ship_id_1]?.ship_type
            : "N/A",
          port: null,
          event: (
            <span style={{ color: ship1EventDetails.color }}>
              {ship.type === "opticalSTS"
                ? "Bunkering"
                : ship1EventDetails.name}
              {ship?.ship1_status ? `(${ship?.ship1_status})` : ""}
            </span>
          ),
          tabIndex: "1",
        },
        {
          id: ship.synmax_ship_id,
          name: ship.synmax_ship_id_2
            ? ship.type === "opticalSTS" && !ship[ship.synmax_ship_id_2]?.name
              ? ship[ship.synmax_ship_id_2]?.object_id
              : ship[ship.synmax_ship_id_2]?.name
            : "N/A",
          ctry:
            ship.synmax_ship_id_2 && ship[ship.synmax_ship_id_2]?.flag ? (
              <FlagIcon
                size={20}
                code={ship[ship.synmax_ship_id_2].flag as FlagIconCode}
              />
            ) : (
              "---"
            ),
          type: ship.synmax_ship_id_2
            ? ship[ship.synmax_ship_id_2]?.ship_type
            : "N/A",
          port: null,
          event: (
            <span style={{ color: ship2EventDetails.color }}>
              {ship.type === "opticalSTS"
                ? "Bunkering"
                : ship2EventDetails.name}
              {ship?.ship2_status ? `(${ship?.ship2_status})` : ""}
            </span>
          ),
          tabIndex: "2",
        },
      ];
    } else {
      return {
        id: ship.synmax_ship_id,
        name: ship.type === "unattributed" ? ship.object_id : ship.name,
        ctry: ship?.flag ? (
          <FlagIcon size={20} code={ship.flag as FlagIconCode} />
        ) : (
          "---"
        ),
        type: ship?.ship_type || "N/A",
        port: null,
        event: (
          <span style={{ color: ship1EventDetails.color }}>
            {ship1EventDetails.name}
          </span>
        ),
      };
    }
  };

  const mapRows = Object.values(ships).map(mapShipToRow);
  const rows = mapRows.flat();

  const recentRows = Object.values(ships)
    .reverse()
    .map((ship) => {
      const ship1EventDetails =
        ship.type === "opticalSTS" && ship?.ship1_status
          ? getEventDetails(ship?.ship1_status.toLowerCase() || "unknown")
          : getEventDetails(ship.type || "unknown");
      const ship2EventDetails =
        ship.type === "opticalSTS" && ship?.ship2_status
          ? getEventDetails(ship?.ship2_status.toLowerCase() || "unknown")
          : getEventDetails(ship.type || "unknown");

      if (isBunkeringInterface(ship)) {
        return [
          {
            id: ship.synmax_ship_id,
            name: ship.synmax_ship_id_1
              ? ship.type === "opticalSTS" && !ship[ship.synmax_ship_id_1]?.name
                ? ship[ship.synmax_ship_id_1]?.object_id
                : ship[ship.synmax_ship_id_1]?.name
              : "N/A",
            ctry:
              ship.synmax_ship_id_1 && ship[ship.synmax_ship_id_1]?.flag ? (
                <FlagIcon
                  size={20}
                  code={ship[ship.synmax_ship_id_1].flag as FlagIconCode}
                />
              ) : (
                "---"
              ),
            type: ship.synmax_ship_id_1
              ? ship[ship.synmax_ship_id_1]?.ship_type
              : "N/A",
            port: null,
            event: (
              <span style={{ color: ship1EventDetails.color }}>
                {ship.type === "opticalSTS"
                  ? "Bunkering"
                  : ship1EventDetails.name}
                {ship?.ship1_status ? `(${ship?.ship1_status})` : ""}
              </span>
            ),
            tabIndex: "1",
          },
          {
            id: ship.synmax_ship_id,
            name: ship.synmax_ship_id_2
              ? ship.type === "opticalSTS" && !ship[ship.synmax_ship_id_2]?.name
                ? ship[ship.synmax_ship_id_2]?.object_id
                : ship[ship.synmax_ship_id_2]?.name
              : "",
            ctry:
              ship.synmax_ship_id_2 && ship[ship.synmax_ship_id_2]?.flag ? (
                <FlagIcon
                  size={20}
                  code={ship[ship.synmax_ship_id_2].flag as FlagIconCode}
                />
              ) : (
                "---"
              ),
            type: ship.synmax_ship_id_2
              ? ship[ship.synmax_ship_id_2]?.ship_type
              : "N/A",
            port: null,
            event: (
              <span style={{ color: ship2EventDetails.color }}>
                {ship.type === "opticalSTS"
                  ? "Bunkering"
                  : ship2EventDetails.name}
                {ship?.ship2_status ? `(${ship?.ship2_status})` : ""}
              </span>
            ),
            tabIndex: "2",
          },
        ];
      } else {
        return {
          id: ship.synmax_ship_id,
          name: ship.type === "unattributed" ? ship.object_id : ship.name,
          ctry: ship?.flag ? (
            <FlagIcon size={20} code={ship.flag as FlagIconCode} />
          ) : (
            "---"
          ),
          type: ship?.ship_type || "N/A",
          port: null,
          event: (
            <span style={{ color: ship1EventDetails.color }}>
              {ship1EventDetails.name}
            </span>
          ),
        };
      }
    })
    .flat();

  const tabData = {
    "1": rows,
    "2": recentRows,
  };

  return (
    <TabsWithTable
      columns={columns}
      tabLabels={["My Ships", "Recently Viewed"]}
      tabData={tabData}
      handleRowClick={handleTableRowClick}
      type="ship"
    />
  );
};

export default MyShips;

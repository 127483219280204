import React from "react";
import SYNMAX_LOGO from "../../assets/synmax-logo.png";
import styles from "../Auth/Auth.module.scss"
import { Navigate, Outlet, useLocation } from "react-router";
import { getAuth } from "firebase/auth";

const MFAWrapper = () => {
  let auth = getAuth();
  let isloggedIn = auth.currentUser as any;
  const location = useLocation();
  const isMFAVerified = localStorage.getItem("isMFAVerified");

  if (isloggedIn && isMFAVerified === "true") {
    return <Navigate to="theia" />;
  } else if (!isloggedIn) {
    return <Navigate to="login" />;
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginOverlay}></div>
      <div className={styles.loginWrap}>
        <img src={SYNMAX_LOGO} alt="synmax-logo" />
        <Outlet/>
      </div>
    </div>
  );
};

export default MFAWrapper;

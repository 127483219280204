import React, { memo, useEffect, useMemo, useState } from "react";
import Overview from "./Overview/Overview";
import Attribution from "./Attribution/Attribution";
import EventTimeline from "./EventTimeline/EventTimeline";
import Ownership from "./Ownership/Ownership";
import CustomSelectComponent from "../../../components/CustomSelect/CustomSelect";
import PathSettingMenu from "./Ownership/PathSettingMenu/PathSettingMenu";
import ShipImg from "../../../assets/Ship.jpg";
import {
  Box,
  CircularProgress,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  tabsClasses,
  tooltipClasses,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Close,
  NotificationsOutlined,
  StarBorderOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import {
  SATELLITE_IMAGERY_TOOLS,
  SHIP_TOOL_DROPDOWN,
} from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setMinimizePathToolModal,
  setOpenPathToolModal,
} from "../MainMap.store";
import { setFuturePathIntialValue } from "../ShipTools/FuturePathPrediction/FuturePathPrediction.store";
import { AllDetailsTabs, BunkeringInterface, ShipImages } from "../MainMap";
import TrendingShipIcon from "../../../assets/icons/TrendingShip";
import CopyToClipboard from "../../../components/CopyToClipboard";
import moment from "moment";
import TabDetailsIcon from "../../../assets/icons/TabDetails";
import SatelliteIcon from "../../../assets/icons/Satellite";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import SanctionDetails from "./Sanction Details/SanctionDetails";
import styles from "./ShipDetails.module.scss";

export interface EventData {
  event: string;
  timestamp: string;
  type: string;
  port?: string;
  mmsi?: number;
  status?: string;
  duration?: string;
  ship2Attri?: string;
  ship2ObjId?: string;
}

const ShipDetails = ({
  shipDetailTabValue,
  handleShipDetailsTabChange,
  shipDetailsTabs,
  handleShipDetailsTabClose,
  setShipDetailsTabs,
  setShipDetailTabValue,
  satelliteImageTimeline,
  fetchSatelliteImages,
  isSatelliteImageLoading,
  bunkeringValue,
  setBunkeringValue,
  getEventDetails,
  shipPaths,
  setShipPaths,
  pathRef,
  handleViewPathAnalysis,
  handleDownloadXLS,
  handleSatelliteImageClick,
  hoveredId,
  setHoveredId
}: any) => {
  const dispatch = useDispatch();
  const { openPathToolModal, minimizePathToolModal } = useSelector(
    (state: RootState) => state.mainMap
  );
  const { intervalId } = useSelector(
    (state: RootState) => state.futurePathPrediction
  );
  const { user } = useSelector((state: RootState) => state.theia);
  const isSuperUser = useMemo(() => {
    const superRoles = ["super_user", "admin", "co-worker"];
    return superRoles.includes(user?.role || "");
  }, [user?.role]);

  const [value, setValue] = useState("1");
  const [openPathSettingAnchor, setOpenPathSettingAnchor] =
    useState<null | HTMLElement>(null);
  const [openPathSettings, setOpenPathSettings] = useState<boolean>(false);
  const [selectedSateliteOption, setSelectedSateliteOption] =
    useState("closeModal");
  const [lastSelectedOption, setLastSelectedOption] = useState<any>("");
  const [isPathAnalysisVisible, setIsPathAnalysisVisible] = useState(false);
  const [satelliteImageModal, setSatelliteImageModal] = useState<boolean>(false);

  const openPathSetting = (event: any) => {
    setOpenPathSettingAnchor(event.currentTarget);
    setOpenPathSettings(true);
  };

  const closePathSetting = () => {
    setOpenPathSettingAnchor(null);
    setOpenPathSettings(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) =>
    setValue(newValue);

  const onSatelliteOptionChange = async (
    e: SelectChangeEvent<string>,
    tabShipId: string,
    tabShipName: string
  ) => {
    setSelectedSateliteOption(e.target.value);
    if (e.target.value === "satelliteTimeline") {
      setShipDetailTabValue(`sat:${tabShipId}`);
      if (satelliteImageTimeline.hasOwnProperty(`sat:${tabShipId}`)) return;
      setShipDetailsTabs((prevShipDetailsTabs: AllDetailsTabs[]) => [
        ...prevShipDetailsTabs,
        {
          tabType: "satelliteImage",
          synmax_ship_id: `sat:${tabShipId}`,
          name: `Satellite Imagery Timeline - ${tabShipName}`,
        },
      ]);
      await fetchSatelliteImages(
        tabShipId,
        `Satellite Imagery Timeline - ${tabShipName}`
      );
    }
  };
  useEffect(() => {
    setValue("1");
    setSelectedSateliteOption("");
    setLastSelectedOption("");
    dispatch(
      setOpenPathToolModal({
        path: false,
        pathAnalysis: false,
        extendedPath: false,
        futurePrediction: false,
        pathPlayback: false,
        estimatedLocation: false,
        downloadXLS: false,
      })
    );
    clearInterval(intervalId);
    dispatch(setFuturePathIntialValue());
    if (shipDetailTabValue === null || shipDetailTabValue === undefined) return;
    if (!shipDetailTabValue?.includes("sim:")) setHoveredId(null);
    let shipId = "";
    if (shipDetailTabValue.includes("_")) {
      const tabArray = shipDetailsTabs?.filter(
        (item: AllDetailsTabs) => item.synmax_ship_id === shipDetailTabValue
      );
      shipId =
        bunkeringValue === "1"
          ? tabArray[0]?.synmax_ship_id_1 || ""
          : tabArray[0]?.synmax_ship_id_2 || "";
    } else {
      shipId = shipDetailTabValue;
    }
    setIsPathAnalysisVisible(shipPaths?.[shipId]?.analysis ? true : false);
  }, [shipDetailTabValue, bunkeringValue]);

  const handleSetSelectedOption = (val: string) => {
    if (val === "pathPlayback") pathRef.current = 0;
    if (val === "pathAnalysis") handleViewPathAnalysis();
    if (val === "downloadXLS") handleDownloadXLS();
    if (minimizePathToolModal[val]) {
      dispatch(
        setMinimizePathToolModal({
          ...minimizePathToolModal,
          [val]: false,
        })
      );
    }
    if (val === "path" || val === "pathAnalysis" || val === "downloadXLS") {
      dispatch(
        setOpenPathToolModal({
          ...openPathToolModal,
          [val]: true,
        })
      );
    } else {
      dispatch(
        setOpenPathToolModal({
          ...openPathToolModal,
          [val]: true,
          path: false,
          pathAnalysis: false,
          downloadXLS: false,
        })
      );
    }

    setLastSelectedOption(val);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.ShipDetailsMain}
    >
      <Tabs
        value={shipDetailTabValue}
        onChange={handleShipDetailsTabChange}
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile
        classes={{
          root: styles.shipDetailsTabsWrap,
          flexContainer: styles.shipDetailsTabsFlexContainer,
          scrollButtonsHideMobile: styles.scrollBtn,
          indicator: styles.shipTabIndicator,
        }}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            "&.Mui-disabled": { opacity: 0.3 },
          },
        }}
      >
        {shipDetailsTabs.map((tab: AllDetailsTabs) => {
          return (
            <Tab
              value={tab.synmax_ship_id}
              className={styles.shipDetailsTab}
              classes={{
                selected: styles.tabSelected,
              }}
              key={tab.synmax_ship_id}
              label={
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  className={styles.shipDetailsTabItem}
                >
                  {tab.tabType === "ship" ? (
                    <TrendingShipIcon size={10} />
                  ) : tab.tabType === "satelliteImage" ? (
                    <SatelliteIcon size={10} />
                  ) : (
                    <TabDetailsIcon size={10} />
                  )}
                  {tab.type === "unattributed"
                    ? tab.object_id
                    : tab.type === "AISSTS" || tab.type === "opticalSTS"
                    ? "Bunkering"
                    : tab.name}
                  <Close
                    className={styles.tabCloseBtn}
                    onClick={() =>
                      handleShipDetailsTabClose(tab.synmax_ship_id, tab.tabType)
                    }
                  />
                </Box>
              }
            />
          );
        })}
      </Tabs>
      {shipDetailsTabs.map((detailTab: AllDetailsTabs | BunkeringInterface) => {
      
        if (detailTab.synmax_ship_id === shipDetailTabValue) {
          let isUnattributed = detailTab.type === "unattributed";
          const isSatelliteImagesTimeline = detailTab.tabType === "satelliteImage";
          const isSpoofing =  detailTab.type === "spoofing";
          const isSanctioned = detailTab.type === "sanctioned";
          const isBunkering =
            detailTab.type === "AISSTS" || detailTab.type === "opticalSTS";
          const isAISSTS =  detailTab.type === "AISSTS";
          const isAIS = detailTab.type === "AIS";
          const isObservationShip = detailTab?.isObservationShip; 
          const id =
            bunkeringValue === "2"
              ? detailTab?.synmax_ship_id_2
              : detailTab?.synmax_ship_id_1;
          const tab: AllDetailsTabs =
            isBunkering &&
            detailTab.hasOwnProperty("synmax_ship_id_1") &&
            detailTab.hasOwnProperty("synmax_ship_id_2") &&
            id
              ? (detailTab as BunkeringInterface)[id]
              : detailTab;
          isUnattributed = tab.type === 'unattributed' || tab.attribution === null;
          const bunkeringEvent =
            detailTab?.ship1_status && bunkeringValue === "1"
              ? `(${detailTab?.ship1_status})`
              : detailTab?.ship2_status && bunkeringValue === "2"
              ? `(${detailTab?.ship2_status})`
              : "";
          const eventDetails =
            detailTab.type === "opticalSTS"
              ? getEventDetails(
                  bunkeringEvent.replace(/[()]/g, "").toLowerCase() || "unknown"
                )
              : getEventDetails(detailTab.type || "unknown");
              const pathData = 
              isUnattributed || isSatelliteImagesTimeline
                ? []
                : detailTab.type === "opticalSTS" && id
                ? shipPaths?.[id]?.path || []
                : shipPaths[detailTab.synmax_ship_id]?.path || [];
            
             const finalPathPoint = pathData[pathData.length - 1];
            
          return detailTab.tabType === "ship" ||
            detailTab.tabType === "event" ? (
            <>
              {isBunkering && (
                <TabContext value={bunkeringValue}>
                  <TabList
                    className={styles.shipDetailsTab}
                    classes={{
                      indicator: styles.shipTabIndicator,
                      flexContainer: styles.shipTabContainer,
                    }}
                    onChange={(e, newValue) => {
                      setBunkeringValue(newValue);
                    }}
                  >
                    <Tab
                      className={styles.shipTab}
                      classes={{ selected: styles.shipTabSelected }}
                      label="Ship 1"
                      value={"1"}
                    />
                    <Tab
                      className={styles.shipTab}
                      classes={{ selected: styles.shipTabSelected }}
                      label="Ship 2"
                      value={"2"}
                    />
                  </TabList>
                </TabContext>
              )}
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                className={styles.ShipDetailsHead}
                key={tab.synmax_ship_id}
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap={2}
                  className={styles.headWrap}
                >
                  <Box className={styles.shipImage}>
                    <img src={ShipImg} alt="ShipImg" />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.headDetailWrap}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      className={styles.countryWrap}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={1}
                        className={styles.countryName}
                      >
                        <h5 className={styles.countryNameHead}>
                          {tab.name ?? "No Info"}
                        </h5>
                        <Box className={styles.countryFlag}>
                          {tab?.flag && (
                            <FlagIcon
                              code={tab.flag as FlagIconCode}
                              style={{ height: "100%", width: "100%" }}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap={2}
                        className={styles.notifications}
                      >
                        {/* <NotificationsOutlined
                          className={styles.notificationsIcon}
                        />
                        <StarBorderOutlined
                          className={styles.notificationsIcon}
                        /> */}
                        <TuneOutlined
                          onClick={openPathSetting}
                          className={`${styles.notificationsIcon}  ${
                            openPathSettings && styles.active
                          }`}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      className={styles.shipDetailWrap}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={styles.shipDetail}
                      >
                        <label className={styles.detailLabel}>Event</label>
                        <span
                          className={styles.detailValue}
                          style={{ color: eventDetails.color }}
                        >
                          {detailTab.type === "opticalSTS"
                            ? "Bunkering"
                            : eventDetails.name}
                          {bunkeringEvent}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={styles.shipDetail}
                      >
                        <label className={styles.detailLabel}>IMO</label>
                        <span className={styles.detailValue}>
                          {tab.imo ?? "No Info"}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={styles.shipDetail}
                      >
                        <label className={styles.detailLabel}>MMSI</label>
                        <span className={styles.detailValue}>
                          {tab.mmsi ?? "No Info"}
                        </span>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        className={styles.shipDetail}
                      >
                        <label className={styles.detailLabel}>Object ID</label>
                        <span className={styles.detailValue}>
                          {tab.object_id ?? "No Info"}
                        </span>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      className={styles.shipDetail}
                    >
                      <label className={styles.detailLabel}>
                        SynMax Ship ID
                      </label>
                      <span className={styles.detailValue}>
                        {tab?.type !== "unattributed" && tab.synmax_ship_id ? (
                          <>
                            {isObservationShip
                              ? tab.synmax_ship_id.substring(
                                  tab.synmax_ship_id.indexOf("_") + 1
                                )
                              : tab.synmax_ship_id}
                            <CopyToClipboard data={tab.synmax_ship_id} />
                          </>
                        ) : (
                          "No Info"
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.shipToolWrap}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.shipTool}
                  >
                    <CustomSelectComponent
                      label="Ship Tools"
                      title={
                        isUnattributed ||
                        !tab.attribution ||
                        tab.attribution === "None" ? (
                          <Tooltip
                            title="Unavailable for Unattributed Ship"
                            placement="right"
                          >
                            <span>Select a path tool</span>
                          </Tooltip>
                        ) : (
                          "Select a path tool"
                        )
                      }
                      MenuItems={(isSuperUser
                        ? SHIP_TOOL_DROPDOWN
                        : SHIP_TOOL_DROPDOWN.filter(
                            (item: { value: string }) =>
                              item.value !== "downloadXLS"
                          )
                      ).map((item: { value: string }) =>
                        item.value !== "futurePrediction"
                          ? {
                              ...item,
                              disabled: isUnattributed,
                            }
                          : item
                      )}
                      selectedOption={
                        Object.keys(openPathToolModal).some(
                          (key) => openPathToolModal[key]
                        )
                          ? lastSelectedOption
                          : ""
                      }
                      onClick={(val: any) => handleSetSelectedOption(val)}
                      isPathAnalysisVisible={isPathAnalysisVisible}
                      setIsPathAnalysisVisible={setIsPathAnalysisVisible}
                    />
                    <CustomSelectComponent
                      label="Satellite Imagery Tools"
                      title="Select a satellite imagery tool"
                      MenuItems={SATELLITE_IMAGERY_TOOLS.map((item) =>
                        item.value === "satelliteTimeline"
                          ? {
                              ...item,
                              disabled: isUnattributed,
                            }
                          : item
                      )}
                      onChange={(e: any) => {
                        onSatelliteOptionChange(
                          e,
                          isObservationShip
                            ? tab.synmax_ship_id.substring(
                                tab.synmax_ship_id.indexOf("_") + 1
                              )
                            : tab.synmax_ship_id,
                          tab?.name || ""
                        );
                      }}
                      selectedOption={selectedSateliteOption}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={styles.shipTabMain}>
                <TabContext value={value}>
                  <TabList
                    className={styles.shipDetailsTab}
                    classes={{ indicator: styles.shipTabIndicator }}
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    scrollButtons={true}
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        "&.Mui-disabled": { opacity: 0.3 },
                      },
                    }}
                  >
                    <Tab
                      className={styles.shipTab}
                      classes={{ selected: styles.shipTabSelected }}
                      label="Overview"
                      value="1"
                    />
                    <Tab
                      className={styles.shipTab}
                      classes={{ selected: styles.shipTabSelected }}
                      // label="Attribution"
                      value="2"
                      disabled={isSpoofing || isSanctioned || isAISSTS || isAIS}
                      label={
                        isSpoofing || isSanctioned || isAISSTS ? (
                          <Tooltip
                            title="Attribution is unavailable for this ship"
                            placement="bottom"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: "2px",
                                    },
                                },
                              },
                            }}
                          >
                            <span>Attribution</span>
                          </Tooltip>
                        ) : (
                          "Attribution"
                        )
                      }
                    />
                    <Tab
                      className={styles.shipTab}
                      classes={{
                        selected: styles.shipTabSelected,
                        disabled: styles.shipTabDisabled,
                      }}
                      label={
                        isUnattributed ? (
                          <Tooltip
                            title="Event Timeline is unavailable for unattributed ship"
                            placement="bottom"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: "2px",
                                    },
                                },
                              },
                            }}
                          >
                            <span>Event Timeline</span>
                          </Tooltip>
                        ) : (
                          "Event Timeline"
                        )
                      }
                      value="3"
                      disabled={isUnattributed}
                    />
                    <Tab
                      className={styles.shipTab}
                      classes={{
                        selected: styles.shipTabSelected,
                        disabled: styles.shipTabDisabled,
                      }}
                      label={
                        isUnattributed ? (
                          <Tooltip
                            title="Ownership is unavailable for unattributed ship"
                            placement="bottom"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: "2px",
                                    },
                                },
                              },
                            }}
                          >
                            <span>Ownership</span>
                          </Tooltip>
                        ) : (
                          "Ownership"
                        )
                      }
                      value="4"
                      disabled={isUnattributed}
                    />
                    <Tab
                      className={styles.shipTab}
                      classes={{
                        selected: styles.shipTabSelected,
                        disabled: styles.shipTabDisabled,
                      }}
                      label={
                        tab.sanctions_list === null ? (
                          <Tooltip
                            title="Sanction Details is unavailable for this ship"
                            placement="bottom"
                            slotProps={{
                              popper: {
                                sx: {
                                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                      marginTop: "2px",
                                    },
                                },
                              },
                            }}
                          >
                            <span>Sanction Details</span>
                          </Tooltip>
                        ) : (
                          "Sanction Details"
                        )
                      }
                      value="5"
                      disabled={tab.sanctions_list === null}
                    />
                  </TabList>
                  <TabPanel
                    value="1"
                    className={styles.tabPanel}
                    style={isBunkering ? { height: "calc(100vh - 548px)" } : {}}
                  >
                    <Overview
                      tabDetails={tab}
                      bunkeringValue={bunkeringValue}
                      setBunkeringValue={setBunkeringValue}
                      finalPathPoint={finalPathPoint}
                      satelliteImageModal={satelliteImageModal}
                      setSatelliteImageModal={setSatelliteImageModal}
                    />
                  </TabPanel>
                  <TabPanel
                    value="2"
                    className={styles.tabPanel}
                    style={isBunkering ? { height: "calc(100vh - 548px)" } : {}}
                  >
                    <Attribution tabDetails={tab} />
                  </TabPanel>
                  <TabPanel
                    value="3"
                    className={styles.tabPanel}
                    style={isBunkering ? { height: "calc(100vh - 548px)" } : {}}
                  >
                    <EventTimeline tabId={tab.synmax_ship_id} />
                  </TabPanel>
                  <TabPanel
                    value="4"
                    className={styles.tabPanel}
                    style={isBunkering ? { height: "calc(100vh - 548px)" } : {}}
                  >
                    <Ownership tabDetails={tab} />
                  </TabPanel>
                  <TabPanel value="5" className={styles.tabPanel}>
                    <SanctionDetails
                      shipId={
                        tab.synmax_ship_id ||
                        (tab.attribution ? tab.attribution : tab.synmax_ship_id)
                      }
                    />
                  </TabPanel>
                </TabContext>
              </Box>
              {!isUnattributed && (
                <PathSettingMenu
                  handleClose={closePathSetting}
                  anchorEl={openPathSettingAnchor}
                  open={openPathSettings}
                  id={tab.synmax_ship_id}
                  shipPaths={shipPaths}
                  setShipPaths={setShipPaths}
                />
              )}
            </>
          ) : detailTab.tabType === "satelliteImage" ? (
            <Box sx={{ padding: "13px" }}>
              <Typography className={styles.satelliteImageInfo}>
                Select an satellite image to view ship’s location at the
                imagery’s captured time and its details.
              </Typography>
              <Box className={styles.imageContainer}>
                {isSatelliteImageLoading ? (
                  <CircularProgress className={styles.loader} />
                ) : (
                  Object.keys(satelliteImageTimeline).length > 0 &&
                  shipDetailTabValue in satelliteImageTimeline &&
                  satelliteImageTimeline[shipDetailTabValue].map(
                    (ship: ShipImages, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: "4px",
                          backgroundColor:
                            hoveredId === +ship?.object_id
                              ? "#00A3E34D"
                              : "transparent",
                        }}
                      >
                        <Box sx={{ padding: "8px" }}>
                          <Box
                            sx={{ borderRadius: "4px" }}
                            onMouseEnter={() => {
                              setHoveredId(+ship?.object_id);
                            }}
                            onMouseLeave={() => setHoveredId(null)}
                            onClick={() =>
                              handleSatelliteImageClick(
                                +ship?.object_id,
                                ship?.name
                              )
                            }
                          >
                            <img
                              className={styles.satelliteImage}
                              src={ship.image}
                              width={"100%"}
                              height={226}
                              alt="ship"
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            className={styles.shipDetail}
                          >
                            <label className={styles.detailLabel}>
                              Image Captured Time
                            </label>
                            <span className={styles.detailValue}>
                              {moment(ship.acquired).toISOString() ?? "No Info"}
                            </span>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              margin: "10px 5px 0 0",
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              className={styles.shipDetail}
                            >
                              <label className={styles.detailLabel}>
                                Longitude
                              </label>
                              <span className={styles.detailValue}>
                                {ship.longitude ?? "No Info"}
                              </span>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              className={styles.shipDetail}
                            >
                              <label className={styles.detailLabel}>
                                Latitude
                              </label>
                              <span className={styles.detailValue}>
                                {ship.latitude ?? "No Info"}
                              </span>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              className={styles.shipDetail}
                            >
                              <label className={styles.detailLabel}>OID</label>
                              <span className={styles.detailValue}>
                                {ship.object_id ?? "No Info"}
                              </span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  )
                )}
              </Box>
            </Box>
          ) : (
            <></>
          );
        } else if (
          (detailTab.tabType === "ship" || detailTab.tabType === "event") &&
          !detailTab.synmax_ship_id
        ) {
          return <CircularProgress className={styles.loader} />;
        }
        return null;
      })}
    </Box>
  );
};

export default memo(ShipDetails);

import { Box } from "@mui/material";
import dayjs from "dayjs";
import Label from "../Label/Label";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarTodayOutlined } from "@mui/icons-material";
import styles from "./CustomDatePicker.module.scss";

const CustomDatePicker = ({
  title,
  value,
  setValue,
  minDate,
  maxDate,
}: any) => {
  const dateIcon = (props: any) => {
    return (
      <Box {...props} className={styles.iconWrap}>
        <CalendarTodayOutlined
          fontSize="small"
          color="primary"
          className={styles.icon}
        />
      </Box>
    );
  };

  return (
    <Box className={styles.customDatePicker}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Label label={title} />
        <DatePicker
          value={dayjs(value)}
          onChange={(newValue) => setValue(newValue)}
          className={styles.datePickerMain}
          slots={{
            openPickerIcon: dateIcon,
          }}
          minDate={minDate && dayjs(minDate)}
          maxDate={maxDate && dayjs(maxDate)}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDatePicker;

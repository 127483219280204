import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { EditOutlined, Close, ShareOutlined } from "@mui/icons-material";
import styles from "./WorkCard.module.scss";

interface WorkCardType {
  work: string;
  addTabList?: (value: string, id: string) => void;
  workStationName: string;
  thumbnail?: string;
  setWorkStationName: (value: string) => void;
  setWorkStationId: (value: string) => void;
  setIsEditWorkStation: (value: boolean) => void;
  setIsDeleteWorkStation: (value: boolean) => void;
  setIsShareWorkStation: (value: boolean) => void;
  folderId?: string;
  setFolderId?: ((value: string) => void) | undefined;
}

const WorkCard = ({
  work,
  addTabList,
  workStationName,
  thumbnail,
  setWorkStationName,
  setWorkStationId,
  setIsEditWorkStation,
  setIsDeleteWorkStation,
  setIsShareWorkStation,
  folderId,
  setFolderId,
}: WorkCardType) => {
  return (
    <Box
      className={styles.dashboardWrapper}
      key={`work-${work}`}
      display="flex"
      position="relative"
    >
      <Card
        className={styles.homeDashbordCard}
        onClick={() => addTabList?.(workStationName, work)}
      >
        <CardMedia
          component="img"
          height="280"
          image={
            thumbnail
              ? thumbnail
              : "https://www.shutterstock.com/shutterstock/photos/2079579424/display_1500/stock-vector-dark-black-tirana-city-area-vector-background-map-streets-and-water-cartography-illustration-2079579424.jpg"
          }
          alt={workStationName}
          className={styles.dashbordCardMedia}
        />
        <CardContent className={styles.dashbordCardContent}>
          <Typography className={styles.CardContentHead} variant="h5">
            {workStationName}
          </Typography>
          <Typography className={styles.CardContentTime} variant="h6">
            Edited 5 hours ago
          </Typography>
        </CardContent>
      </Card>
      <Box display="flex">
        <Tooltip
          title="Share"
          classes={{
            tooltip: styles.tooltipButton,
            arrow: styles.tooltipArrowButton,
          }}
          arrow
        >
          <ShareOutlined
            className={styles.headerTabShareIcon}
            onClick={() => {
              setIsShareWorkStation(true);
              setWorkStationName(workStationName);
              setWorkStationId(work);
              if (folderId !== undefined && setFolderId !== undefined)
                setFolderId(folderId);
            }}
          />
        </Tooltip>
        <Tooltip
          title="Edit"
          classes={{
            tooltip: styles.tooltipButton,
            arrow: styles.tooltipArrowButton,
          }}
          arrow
        >
          <EditOutlined
            className={styles.headerTabEditIcon}
            onClick={() => {
              setIsEditWorkStation(true);
              setWorkStationName(workStationName);
              setWorkStationId(work);
              if (folderId !== undefined && setFolderId !== undefined)
                setFolderId(folderId);
            }}
          />
        </Tooltip>
        <Tooltip
          title="Delete"
          classes={{
            tooltip: styles.tooltipButton,
            arrow: styles.tooltipArrowButton,
          }}
          arrow
        >
          <Close
            className={styles.headerTabDeleteIcon}
            onClick={() => {
              setIsDeleteWorkStation(true);
              setWorkStationName(workStationName);
              setWorkStationId(work);
              if (folderId !== undefined && setFolderId !== undefined)
                setFolderId(folderId);
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default WorkCard;

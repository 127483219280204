import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import styles from "./SelectInput.module.scss";

interface SelectInputProps {
  id?: string;
  name: string;
  value: string;
  onChange: (e: any) => void;
  optionsArray: { value: string; label: string }[];
  className?: string;
  props?: any;
  inputProps?: any;
}

const SelectInput = ({
  id,
  name,
  value,
  onChange,
  optionsArray,
  className,
  inputProps,
  ...props
}: SelectInputProps) => {
  return (
    <Select
      className={`${styles.selectInputComponent} ${className}`}
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      displayEmpty
      IconComponent={KeyboardArrowDown}
      classes={{
        select: styles.selectInnerWrap,
        outlined: styles.selectOutlined,
        icon: styles.selectIcon,
      }}
      MenuProps={{
        classes: {
          root: styles.selectInputRoot,
          paper: styles.selectInputMenu,
          list: styles.selectInputList,
        },
      }}
      inputProps={inputProps}
      {...props}
    >
      {optionsArray.map((item, index) => (
        <MenuItem
          value={item.value}
          key={index}
          className={styles.selectInputMenuItem}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectInput;

import React, { FormEvent } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styles from "../../Dialog/Dialog.module.scss";

interface CreateEditFolderDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  buttonName: string;
  addEditFolder: (value: string) => void;
  oldFolderName?: string;
}

const CreateEditFolderDialog = ({
  open,
  setOpen,
  title,
  buttonName,
  addEditFolder,
  oldFolderName,
}: CreateEditFolderDialogProps) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const projectName = formJson.project_name;
          addEditFolder(projectName);
          handleClose();
        },
      }}
      className={styles.dialogMain}
      classes={{ paper: styles.dialogWrap }}
    >
      <DialogTitle className={styles.dialogHead}>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="project_name"
          label="Project Name"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={oldFolderName}
          InputLabelProps={{
            classes: {
              root: styles.dialogLabel,
              focused: styles.dialogLabelFocused,
            },
          }}
          InputProps={{
            classes: {
              root: styles.dialogInput,
              input: styles.test,
              focused: styles.dialogInputFocused,
            },
          }}
          className={styles.dialogTextFild}
        />
      </DialogContent>
      <DialogActions>
        <Button className={styles.button} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={styles.buttonCreate} type="submit">
          {buttonName}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditFolderDialog;

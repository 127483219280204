import React from "react";

const ExtendedPathIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 11.5L4.5 11.5L4.5 15" stroke="white" />
      <path d="M11 1.5L14.5 1.5L14.5 5" stroke="white" />
      <path d="M6 6.5L9.5 6.5L9.5 10" stroke="white" />
      <path d="M1.5 14.5L14.5 1.5" stroke="white" />
    </svg>
  );
};

export default ExtendedPathIcon;

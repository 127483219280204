import { changeLoaderState, globalStateReducer } from "../redux/globalState.store";
import { store } from "../redux/store";
import { api } from "../services/api";
import {
  AddFolderType,
  AddWorkStationType,
  DeleteFolderType,
  DeleteWorkStationType,
  UpdateFolderType,
  UpdateWorkStationType,
} from "./Common.store";

//FOLDER
export const addFolder = async (data: AddFolderType) => {
  const response = await api.post("/folders", data);
  return response.data;
};

export const updateFolder = async (data: UpdateFolderType) => {
  const response = await api.put("/folders", data);
  return response.data;
};

export const deleteFolder = async (data: DeleteFolderType) => {
  const response = await api.delete(
    `/folders/${data.folderId}?userId=${data.userId}`
  );
  return response.data;
};

//WORK STATION
export const addWorkStation = async (data: AddWorkStationType) => {
  const response = await api.post("/work-stations", data);
  return response.data;
};

export const updateWorkStation = async (data: UpdateWorkStationType) => {
  const response = await api.put("/work-stations", data);
  return response.data;
};

export const deleteWorkStation = async (data: DeleteWorkStationType) => {
  const response = await api.delete(
    `/work-stations/${data.folderId}/${data.workStationId}?userId=${data.userId}&tabId=${data.tabId}`
  );
  return response.data;
};

//FETCH ALL DATA
export const fetchFolderWork = async (userId: string) => {
 
  store.dispatch(changeLoaderState(true))
  const response = await api.get(`/projects?userId=${userId}`);
  store.dispatch(changeLoaderState(false))
  return response.data;
};

//USER
export const fetchUsers = async (userId: string) => {
  const response = await api.get(`/users/${userId}`);
  return response.data;
};

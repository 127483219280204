import Button from "@mui/material/Button";
import styles from "./CustomButton.module.scss";
import { CircularProgress } from "@mui/material";

interface CustomButtonProps {
  id?: string;
  buttonName: string;
  onClick?: any;
  className?: string;
  variant?: string;
  props?: any;
  loading?: boolean;
}

const CustomButton = ({
  id,
  buttonName,
  onClick,
  className,
  variant = "contained",
  loading = false,
  props,
}: CustomButtonProps) => {
  return (
    <Button
      id={id}
      variant={variant}
      onClick={onClick}
      className={`${styles.customButtonComponent}  ${className}`}
      classes={{ outlined: styles.buttonOutlined }}
      disabled={loading}
      startIcon={
        loading && (
          <CircularProgress
            classes={{ circle: styles.circularSpiner }}
            size={15}
          />
        )
      }
      {...props}
    >
      {buttonName}
    </Button>
  );
};

export default CustomButton;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addShared,
  updateShared,
  deleteShared,
  fetchShared,
} from "./Shared.service";
import { AlertColor } from "@mui/material";
import { deleteTabData, updateTabData } from "../../layout/Header/Header.store";

export type AddSharedType = {
  userId: string;
  workStationName: string;
};

export type UpdateSharedType = {
  userId: string;
  workStationId: string;
  newWorkStationName?: string;
  newWorkStationMode?: string;
  isWorkStationDate?: boolean;
  tabId?: string;
  isTabId?: boolean;
};

export type DeleteSharedType = {
  userId: string;
  workStationId: string;
  tabId?: string;
};

export type SharedType = {
  [key: string]: {
    createdAt: Date | any;
    id: string;
    name: string;
    mode: string;
    tabId: string;
  };
};

export const addSharedData = createAsyncThunk(
  "shared/addSharedData",
  async (SharedData: AddSharedType) => {
    try {
      const data = await addShared(SharedData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSharedData = createAsyncThunk(
  "shared/updateSharedData",
  async (SharedData: UpdateSharedType, { dispatch, getState }: any) => {
    try {
      const data = await updateShared(SharedData);

      if (SharedData.tabId && data.updateShared.newWorkStationName) {
        const userId = getState()?.theia?.user?.uid;
        const updatedTabData = {
          userId,
          tabId: SharedData.tabId,
          newWorkStationName: data.updateShared.newWorkStationName,
        };
        dispatch(updateTabData(updatedTabData));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSharedData = createAsyncThunk(
  "shared/deleteSharedData",
  async (SharedData: DeleteSharedType, { dispatch, getState }: any) => {
    try {
      const data = await deleteShared(SharedData);

      if (SharedData.tabId) {
        const userId = getState()?.theia?.user?.uid;
        const deleteTab = {
          userId,
          tabId: SharedData.tabId,
        };
        dispatch(deleteTabData(deleteTab));
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSharedData = createAsyncThunk(
  "shared/fetchSharedData",
  async (userId: string) => {
    try {
      const data = await fetchShared(userId);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const sharedSlice = createSlice({
  name: "shared",
  initialState: {
    shared: {} as SharedType,
    loading: false,
    error: "",
    isSharedFetch: false,
    isShared: false,
    snackbarMessage: "",
    snackbarSeverity: "success" as AlertColor | undefined,
    isSnackBarOpen: false,
  },
  reducers: {
    setIsShared: (state, action) => {
      state.isShared = action.payload;
    },
    setIsSharedSnackBarOpen: (state, action) => {
      state.isSnackBarOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //ADD SHARED
      .addCase(addSharedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSharedData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        const addSharedData = action.payload.addSharedData;
        state.shared = {
          ...state.shared,
          [addSharedData.collectionId]: {
            id: addSharedData.id,
            name: addSharedData.name,
            createdAt: addSharedData.createdAt,
          },
        };
        state.snackbarMessage = action.payload.msg;
        state.snackbarSeverity = "success";
        state.isSnackBarOpen = true;
      })
      .addCase(addSharedData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      //UPDATE SHARED
      .addCase(updateSharedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateSharedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const updateShared = action.payload.updateShared;
          const workStation = state.shared;
          state.loading = false;
          if (workStation[updateShared.workStationId]) {
            if (updateShared.currentDateTime) {
              workStation[updateShared.workStationId].createdAt =
                updateShared.currentDateTime;
            } else if (updateShared.isTabId) {
              workStation[updateShared.workStationId].tabId =
                updateShared.tabId;
            } else {
              workStation[updateShared.workStationId].name =
                updateShared.newWorkStationName;
              workStation[updateShared.workStationId].mode =
                updateShared.newWorkStationMode;
              state.snackbarMessage = action.payload.msg;
              state.snackbarSeverity = "success";
              state.isSnackBarOpen = true;
            }
          }
        }
      )
      .addCase(
        updateSharedData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //DELETE SHARED
      .addCase(deleteSharedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteSharedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const shared = state.shared;
          const deleteSharedId = action.payload.deleteSharedId;
          state.loading = false;
          state.snackbarMessage = action.payload.msg;
          state.snackbarSeverity = "error";
          state.isSnackBarOpen = true;

          if (shared[deleteSharedId]) {
            delete shared[deleteSharedId];
          }
        }
      )
      .addCase(
        deleteSharedData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //GET SHARED
      .addCase(fetchSharedData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchSharedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.shared = action.payload;
          state.isSharedFetch = true;
        }
      )
      .addCase(
        fetchSharedData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { setIsShared, setIsSharedSnackBarOpen } = sharedSlice.actions;

export const sharedReducer = sharedSlice.reducer;

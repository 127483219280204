import { useEffect, useState } from "react";
import { HuePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  ASPIRE_TYPES,
  COUNTRY_CODES,
  SHIP_DATA_SOURCE,
  SHIP_FILTER_PROVIDER,
  SHIP_MOVING_OPTIONS,
  SHIP_SERVICE_PROVIDER_LIST,
} from "../../../../utils/Constants";
import { ShipSvgs } from "../../../../utils/ShipSvgs";
import SelectCheckbox from "../../../../components/SelectCheckbox/SelectCheckbox";
import { setChangeSaved } from "../../Theia.store";
import { updateAllProjects } from "../../../../common/Common.store";
import { Checkbox, Slider, Autocomplete, Box, Button } from "@mui/material";
import { ChevronLeft, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./ShipFilters.module.scss";
import SelectInput from "../../../../components/SelectInput/SelectInput";

interface ShipFiltersProps {
  currentEventTypeFilter: string | null | any;
  setCurrentEventTypeFilter: (value: string | null) => void;
  updatedFilterValue: (shipIndex: any, value: any) => void;
  currentFilter: any;
  currentEventTypeFilterOptions: any;
  setCurrentEventTypeFilterOptions: any;
  ref?: any;
  intialStateForFilterProvider: any;
  setIntialStateForFilterProvider: any;
}

const ShipFilters = ({
  currentEventTypeFilter,
  setCurrentEventTypeFilter,
  updatedFilterValue,
  currentFilter,
  currentEventTypeFilterOptions,
  setCurrentEventTypeFilterOptions,
  ref,
  intialStateForFilterProvider,
  setIntialStateForFilterProvider,
}: ShipFiltersProps) => {
  const dispatch = useDispatch();
  const { allProjects } = useSelector((state: RootState) => state.common);
  const { tabs, selectedTabId } = useSelector(
    (state: RootState) => state.header
  );

  const [shipProvider, setShipProvider] = useState(
    SHIP_SERVICE_PROVIDER_LIST.filter(
      (data) =>
        currentEventTypeFilterOptions[currentEventTypeFilter].providerFilters &&
        currentEventTypeFilterOptions[currentEventTypeFilter]?.providerFilters[
          data.value
        ]
    )
  );
  const initailStateForDataSource = SHIP_DATA_SOURCE.filter((dataSource) =>
    SHIP_SERVICE_PROVIDER_LIST.some(
      (dataProvider: any) =>
        dataProvider.type === dataSource.value &&
        currentEventTypeFilterOptions[currentEventTypeFilter].providerFilters &&
        currentEventTypeFilterOptions[currentEventTypeFilter].providerFilters[
          dataProvider.value
        ]
    )
  );

  const [shipDataSource, setShipDataSource] = useState(
    initailStateForDataSource
  );
  const [shipData, setShipData] = useState(initailStateForDataSource);

  useEffect(() => {
    filterProviders(shipProvider);
    // setIntialStateForFilterProvider({
    //   ...intialStateForFilterProvider,
    //   [currentEventTypeFilter]: shipProvider,
    // });
  }, [shipProvider]);

  const filterProviders = (value: any) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    const updatedFilterState = {
      ...currentEventTypeFilterOptions,
      [currentEventTypeFilter]: {
        ...currentEventTypeFilterOptions[currentEventTypeFilter],
        ...(value &&
          value.length >= 0 &&
          value.reduce(
            (acc: any, item: any) => {
              acc.providerFilters[item?.value.toLowerCase()] = item.checked;
              return acc;
            },
            {
              providerFilters: {
                planetscope: false,
                sentinel1: false,
                sentinel2: false,
              },
            }
          )),
      },
    };
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };

    setCurrentEventTypeFilterOptions(updatedFilterState);
    dispatch(updateAllProjects(updatedProjects));
    dispatch(setChangeSaved(false));
  };

  const handleChangeShipDataSource = (newValue: any) => {
    const selected = newValue.map((value: any) =>
      SHIP_DATA_SOURCE.find((option) => option.label === value)
    );
    const shipData =
      shipDataSource.length < selected.length
        ? [
            ...shipProvider,
            ...SHIP_SERVICE_PROVIDER_LIST.filter(
              (data) =>
                selected.length &&
                selected[selected.length - 1].value === data.type
            ),
          ]
        : shipProvider.filter(
            (data: any) => selected.length && selected[0].value === data.type
          );
    setShipProvider(shipData);
    setShipData(selected);
    setShipDataSource(selected);
  };

  const handleChangeShipServiceProvider = (newValue: any) => {
    const selected = newValue.map((value: any) =>
      SHIP_SERVICE_PROVIDER_LIST.find((option) => option.label === value)
    );
    setShipProvider(selected);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.shipFiltersMain}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={styles.shipFiltersHead}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span
          onClick={(e) => {
            e.stopPropagation();
            setCurrentEventTypeFilter(null);
          }}
        >
          <ChevronLeft /> Go back
        </span>
        <Close
          className={styles.icon}
          onClick={(e) => {
            e.stopPropagation();
            setCurrentEventTypeFilter(null);
          }}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        className={styles.shipFiltersWrapper}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          className={styles.shipFiltersDetail}
        >
          <span className={styles.shipFiltersLabel}>Marker Color</span>

          <Box
            display="flex"
            alignItems="center"
            className={styles.colorPickerMain}
            gap={1}
          >
            {ShipSvgs({
              color: currentFilter.markerColor,
              stroke: "#201f1f",
              type: "shipSVG",
            })}
            <HuePicker
              color={currentFilter.markerColor}
              onChange={(e) => {
                updatedFilterValue("markerColor", e.hex);
              
              }}
              className={styles.colorPicker}
            />
          </Box>
        </Box>
        {SHIP_FILTER_PROVIDER.includes(currentEventTypeFilter) && (
          <>
            <SelectCheckbox
              id="SHIP_DATA_SOURCE"
              options={SHIP_DATA_SOURCE}
              title="Show ships from below data source(s):"
              value={shipData}
              onChange={(newValue: any) => handleChangeShipDataSource(newValue)}
              defaultDisable={true}
              ref={ref}
              label="Ship Data Sources"
              disableOption={currentEventTypeFilter === "dark" ? ["sar"] : []}
              Unavailable={currentEventTypeFilter === "dark" ? ["SAR"] : []}
            />
            <SelectCheckbox
              id="SHIP_SERVICE_PROVIDER_LIST"
              options={SHIP_SERVICE_PROVIDER_LIST}
              title="Show ships from below service provider(s):"
              value={shipProvider}
              disableOption={shipDataSource?.map((data: any) => data.value)}
              onChange={(newValue: any) =>
                handleChangeShipServiceProvider(newValue)
              }
              label="Ship Data Service Provider"
              Unavailable={
                currentEventTypeFilter === "dark" ? ["Sentinel-2"] : []
              }
            />
          </>
        )}
        {currentEventTypeFilter !== "unattributed" && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <span className={styles.shipFiltersLabel}>
              Show ships matching below country only:
            </span>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={COUNTRY_CODES}
              value={currentFilter.country as any}
              onChange={(event, newValue) => {
                event.stopPropagation();
                updatedFilterValue("country", newValue);
              }}
              autoHighlight
              classes={{
                popper: styles.shipFiltersListWrap,
                paper: styles.shipFiltersListHead,
                listbox: styles.shipFiltersListBox,
              }}
              className={styles.countrySelectMain}
              renderOption={(props, { code, label }) => {
                return (
                  <Box
                    component="li"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    {...props}
                    className={`${styles.shipFiltersList} ${
                      code.toLowerCase() ===
                      currentFilter.country?.code?.toLowerCase()
                        ? styles.active
                        : ""
                    }`}
                  >
                    <img
                      src={`https://flagcdn.com/48x36/${code.toLowerCase()}.png`}
                      alt=""
                      className={styles.listImage}
                    />
                    <span className={`${styles.listLabel}`}>{label} </span>
                  </Box>
                );
              }}
              renderInput={(params) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={styles.countrySelect}
                    ref={params.InputProps.ref}
                    style={{ display: 'flex', justifyContent: 'space-between'}}
                  >
                    <div>
                      {currentFilter.country && (
                        <img
                          className={styles.countrySelectImage}
                          src={`https://flagcdn.com/48x36/${currentFilter.country.code.toLowerCase()}.png`}
                          alt=""
                        />
                      )}
                      <input
                        type="text"
                        {...params.inputProps}
                        id="datamanagerSearchInput"
                        placeholder="Search By Country"
                        className={styles.countryInput}
                      />
                    </div>

                    <div style={{cursor: 'pointer'}} onClick={() =>   updatedFilterValue("country", { code: "", label: "" })}>
                      <CloseIcon
                        fontSize="small"
                        className={styles.markerClose}
                      />
                    </div>
                  </Box>
                );
              }}
            />
          </Box>
        )}
        {currentEventTypeFilter !== "unattributed" && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <span className={styles.shipFiltersLabel}>
              Show ships matching below type only:
            </span>
            <Autocomplete
              disablePortal
              id="ship-type"
              options={ASPIRE_TYPES}
              value={currentFilter.type as any}
              onChange={(event, newValue) => {
                event.stopPropagation();
                updatedFilterValue("type", newValue.label);
              }}
              autoHighlight
              ListboxProps={{ style: { maxHeight: 160 } }}
              classes={{
                popper: styles.shipFiltersListWrap,
                paper: styles.shipFiltersListHead,
                listbox: styles.shipFiltersListBox,
              }}
              className={styles.countrySelectMain}
              renderOption={(props, { label }) => {
                return (
                  <Box
                    component="li"
                    display="flex"
                    alignItems="center"
                    gap={1}
                    {...props}
                    className={styles.shipFiltersList}
                  >
                    <span className={`${styles.listLabel}`}>{label} </span>
                  </Box>
                );
              }}
              renderInput={(params) => {
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    className={styles.countrySelect}
                    ref={params.InputProps.ref}
                  >
                    <input
                      type="text"
                      {...params.inputProps}
                      id="datamanagerSearchInput"
                      placeholder="Search Type"
                      className={styles.countryInput}
                    />
                    <span className={`${styles.listLabel}`}></span>
                  </Box>
                );
              }}
            />
          </Box>
        )}
        {currentEventTypeFilter === "unattributed" && (
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <span className={styles.shipFiltersLabel}>Is Moving:</span>
            <SelectInput
              id="shipType"
              name="ship_type"
              value={currentFilter.isMoving || "none"}
              onChange={(e: any) => {
                e.stopPropagation();
                updatedFilterValue("isMoving", e.target.value);
              }}
              className={styles.editMainSelect}
              optionsArray={SHIP_MOVING_OPTIONS}
              inputProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      backgroundColor: "#26262c",
                      border: "1px solid #FFFFFF40",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      color: "#fff",
                      "& .MuiMenuItem-root": {
                        padding: "6px 12px",
                        fontFamily: '"Inter", sans-serif',
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "17px",
                        gap: "6px",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#00a3e34d",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#26262c",
                          "&:hover": {
                            backgroundColor: "#00a3e34d",
                          },
                        },
                      },
                    },
                  },
                },
              }}
            />
          </Box>
        )}
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, minmax(130px, 1fr))"
          gap={2}
          className={styles.shipFiltersDetail}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            className={styles.sliderWrapper}
          >
            <span className={styles.shipFiltersLabel}>Vessel Length</span>
            <Slider
              max={500}
              getAriaLabel={() => "Vessel Length"}
              value={currentFilter.length}
              onChange={(e: any, newValue: any) =>
                updatedFilterValue("length", newValue)
              }
              valueLabelDisplay="auto"
              className={styles.slider}
              classes={{
                rail: styles.sliderRail,
                track: styles.sliderTrack,
                thumb: styles.sliderThumb,
              }}
            />
          </Box>

          {currentEventTypeFilter !== "unattributed" ? (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              className={styles.sliderWrapper}
            >
              <span className={styles.shipFiltersLabel}>Vessel Width</span>
              <Slider
                max={100}
                getAriaLabel={() => "Vessel Length"}
                value={currentFilter.width}
                onChange={(e: any, newValue: any) =>
                  updatedFilterValue("width", newValue)
                }
                valueLabelDisplay="auto"
                className={styles.slider}
                classes={{
                  rail: styles.sliderRail,
                  track: styles.sliderTrack,
                  thumb: styles.sliderThumb,
                }}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              className={styles.sliderWrapper}
            >
              <span className={styles.shipFiltersLabel}>Heading</span>
              <Slider
                max={360}
                getAriaLabel={() => "Heading"}
                value={currentFilter.heading}
                onChange={(e: any, newValue: any) =>
                  updatedFilterValue("heading", newValue)
                }
                valueLabelDisplay="auto"
                className={styles.slider}
                classes={{
                  rail: styles.sliderRail,
                  track: styles.sliderTrack,
                  thumb: styles.sliderThumb,
                }}
              />
            </Box>
          )}
        </Box>
        {currentEventTypeFilter !== "unattributed" && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            className={styles.shipFiltersDetail}
          >
            <Checkbox
              size="medium"
              checked={currentFilter.noImoShips}
              onChange={(e) =>
                updatedFilterValue("noImoShips", !currentFilter.noImoShips)
              }
              className={styles.checkboxMain}
              classes={{ checked: styles.checkboxChecked }}
            />
            <span className={styles.shipFiltersLabel}>
              Show ship without IMO
            </span>
          </Box>
        )}
        {currentEventTypeFilter === "spoofing" && (
          <>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={currentFilter.subType["footprint-spoofing"]}
                onChange={(e) =>
                  updatedFilterValue("subType", {
                    ...currentFilter.subType,
                    "footprint-spoofing":
                      !currentFilter.subType["footprint-spoofing"],
                  })
                }
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with Footprint only
              </span>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={currentFilter.subType["optical-spoofing"]}
                onChange={(e) =>
                  updatedFilterValue("subType", {
                    ...currentFilter.subType,
                    "optical-spoofing":
                      !currentFilter.subType["optical-spoofing"],
                  })
                }
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with Optical only
              </span>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={currentFilter.subType["ais-spoofing"]}
                onChange={(e) =>
                  updatedFilterValue("subType", {
                    ...currentFilter.subType,
                    "ais-spoofing": !currentFilter.subType["ais-spoofing"],
                  })
                }
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show ships with AIS only
              </span>
            </Box>
          </>
        )}
        {currentEventTypeFilter !== "spoofing" &&
          currentEventTypeFilter !== "unattributed" && (
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              className={styles.shipFiltersDetail}
            >
              <Checkbox
                size="medium"
                checked={currentFilter.sanctionedShips}
                onChange={(e) =>
                  updatedFilterValue(
                    "sanctionedShips",
                    !currentFilter.sanctionedShips
                  )
                }
                className={styles.checkboxMain}
                classes={{ checked: styles.checkboxChecked }}
              />
              <span className={styles.shipFiltersLabel}>
                Show only sanctioned ships
              </span>
            </Box>
          )}
        <Box
          display="grid"
          gridTemplateColumns="repeat(2,1fr)"
          gap={2}
          className={styles.shipFiltersDetail}
        >
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setCurrentEventTypeFilter(null);
            }}
            className={styles.shipFiltersButton}
          >
            Cancel
          </Button>
          <Button className={styles.shipFiltersButton}>Apply</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ShipFilters;

import { Box, CircularProgress } from "@mui/material";
import styles from "./SanctionDetails.module.scss";
import Table from "../../../../components/Table";
import { useEffect, useState } from "react";
import { getSanctionDetails } from "../../RenderShips/apiCalls";

interface Identification {
  Type: string;
  "ID#": string;
  Country: string | null;
  "Issue Date": string | null;
  "Expire Date": string | null;
}

interface SanctionsData {
  "Sanctions List": string;
  "Program(s)": string;
  "Vessel Owner": string | null;
  Remarks: string;
  Identifications: Identification[];
}

const SanctionDetails = ({ shipId }: { shipId: string }) => {
  const columns = ["Type", "ID#", "Country", "Issue Date", "Expire Date"];

  const [sanctionsData, setSanctionsData] = useState<SanctionsData | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const sanctionData = await getSanctionDetails(shipId);
      setSanctionsData(sanctionData);
      setLoading(false);
    };
    fetchData();
  }, [shipId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.sanctionedMain}
    >
      <Box display="grid" gridTemplateColumns="70% 30%" gap="5px">
        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Program(s)</span>
          <span className={styles.detailValue}>
            {sanctionsData?.["Program(s)"] ?? "No Info"}
          </span>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Sanctions List</span>
          <span className={styles.detailValue}>
            {sanctionsData?.["Sanctions List"] ?? "No Info"}
          </span>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Remarks</span>
          <span className={styles.detailValue}>
            {sanctionsData?.Remarks ?? "No Info"}
          </span>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Vessel Owner</span>
          <span className={styles.detailValue}>
            {sanctionsData?.["Vessel Owner"] ?? "No Info"}
          </span>
        </Box>
      </Box>
      <Table
        rows={
          sanctionsData?.Identifications?.map((id: Identification, index) => ({
            id: index.toString(),
            Type: id.Type,
            "ID#": id["ID#"],
            Country: id.Country ?? "No Info",
            "Issue Date": (
              <Box minWidth="72px">{id["Issue Date"] ?? "No Info"}</Box>
            ),
            "Expire Date": (
              <Box minWidth="78px">{id["Expire Date"] ?? "No Info"}</Box>
            ),
            tabIndex: index.toString(),
          })) ?? []
        }
        columns={columns}
        onRowClick={(id, type, tabIndex) => {
          console.log(
            `Row clicked: id=${id}, type=${type}, tabIndex=${tabIndex}`
          );
        }}
        type="document"
      />
    </Box>
  );
};

export default SanctionDetails;

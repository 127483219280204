import React, { useEffect, useState } from "react";
import SYNMAX_LOGO from "../../assets/synmax-logo.png";
import styles from "./Auth.module.scss";
import { Navigate, Outlet, useLocation } from "react-router";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../App";

const AuthWrapper = () => {
  let auth = getAuth();
  let isloggedIn = auth.currentUser as any;
  const isMFAVerified = localStorage.getItem("isMFAVerified");
  const [userRole, setUserRole] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isloggedIn) {
          const { uid } = isloggedIn;
          const userDoc = await getDoc(doc(db, "users", uid));
          const userData = userDoc.data();
          setUserRole(userData?.role ?? null);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  if (isloggedIn && isMFAVerified === "true") {
    return <Navigate to="theia" />;
  } else if (
    isloggedIn &&
    isMFAVerified === "false" &&
    userRole &&
    userRole !== "newUser"
  ) {
    return <Navigate to="mfa" />;
  }

  return (
    <div className={styles.loginMain}>
      <div className={styles.loginOverlay}></div>
      <div className={styles.loginWrap}>
        <img src={SYNMAX_LOGO} alt="synmax-logo" />
        <Outlet />
      </div>
    </div>
  );
};

export default AuthWrapper;

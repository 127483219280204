import { Tooltip } from "@mui/material";
import styles from "./ToolTipComponent.module.scss";

interface ToolTipComponentProps {
  open?: boolean;
  children: React.ReactElement;
  title: string;
  placement?: string;
  handleClose?: any;
  handleOpen?: any;
  props?: any;
}

const ToolTipComponent = ({
  open,
  title,
  children,
  placement = "top",
  handleClose,
  handleOpen,
  props,
}: ToolTipComponentProps) => (
  <Tooltip
    open={open}
    title={title}
    classes={{
      tooltip: styles.tooltipMain,
      arrow: styles.toolTipArrow,
    }}
    placement={placement}
    arrow
    onClose={handleClose}
    onOpen={handleOpen}
    {...props}
  >
    {children}
  </Tooltip>
);

export default ToolTipComponent;


import { api } from "../../../services/api"
import { changeLoaderState } from "../../../redux/globalState.store"
import { setAISSTSEvents, setOpticalSTSEvents, setSanctionedShips, setSpoofingEvents, setDetections } from "../Theia.store"
import moment from "moment"


export const getAllInitialShips = async (token: string,setDetections: any,dispatch:any, date: any, isFirstLoad: boolean) => {
    try {
        console.log('calling API')
        if(isFirstLoad) {   
            dispatch(changeLoaderState(true))
        }
        const res = await api.get(`/ships/detections?token=${token}&date=${date.format('YYYY-MM-DD')}`)
        console.log(res, ':res')  
        if(isFirstLoad) {
            dispatch(changeLoaderState(false))
        }
        dispatch(setDetections({...res.data.detections}))
        dispatch(setSpoofingEvents({...res.data.spoofingEvents}))
        dispatch(setAISSTSEvents({...res.data.AISSTS}))
        dispatch(setOpticalSTSEvents({...res.data.opticalSTSevents}))
        dispatch(setSanctionedShips({...res.data.sanctionedVessels}))
    } catch (err) {
        console.log(err)
        // Show snackbar on fail
    }
  
}   

export const getShipInfo = async (shipids: string[], dateString: any) => {
  try {
    const res = await api.post("/shipInfo", { shipids, dateString });
    return { ...res.data };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getShipAttributes = async (object_id: number) => {
  try {
    const res = await api.post("/get_attributes", { object_id });
    return { ...res.data };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getObjectsForShips = async (shipids: string[], startDate?: string,
  endDate?: string) => {
  try {
    const id = "frontend";
    const start = startDate || "2021-01-01 00:00:00";
    const end = endDate || moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const res = await api.post("/shipImages/shipObjects", {
      id,
      start,
      end,
      shipids,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const getSatelliteImage = async (objectId: number) => {
  try {
    const response = await api.get(
      `/shipImages/shipClip?object_id=${objectId}`,
      {
        responseType: "blob",
      }
    );
    const blob = response.data;
    const url = URL.createObjectURL(blob);
    return url;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getEvents = async (shipids: string[], startDate?: string,
  endDate?: string) => {
  try {
    const start = startDate || "2021-01-01 00:00:00";
    const end = endDate || moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const aisStart = moment
      .utc(new Date())
      .subtract(30, "days")
      .format("YYYY-MM-DD HH:mm:ss");
    const res = await api.post("/getEvents", {
      start,
      end,
      shipids,
      aisStart,
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return {};
  }
};

function randomInteger(min: any, max: any) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getSanctionVessels = async () => {
  try {
    const response = await api.get("/sanctions/sanctioned_vessels");
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getSanctionDetails = async (shipId: string) => {
  try {
    const response = await api.get(
      `/sanctions?synmax_ship_id=${shipId}`,
    );
    return {...response.data};
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const getShipPath = async (
  shipids: string[],
  start: string,
  end: string
) => {
  try {
    const res = await api.post("/ais_for_ships", { shipids, start, end });
    return res.data.sort(compare);
  } catch (err) {
    console.log(err);
    return [];
  }
};

function compare(a: { timestamp: number }, b: { timestamp: number }): number {
  if (a.timestamp < b.timestamp) {
    return -1;
  }
  if (a.timestamp > b.timestamp) {
    return 1;
  }
  return 0;
}

export const getShipObject = async (object : number[]) => {
  try {
    const res = await api.post("/object", { object });
    return res.data[0];
  } catch (err) {
    return [];
  }
};

export const getSimilarShips = async (
  shipId: string,
  start: string,
  end: string,
  area : any,
) => {
  try {
    const res = await api.post("/ship_search", { shipId , start, end, area });
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
import { api } from "../../../services/api";
import {
  AddNotificationType,
  UpdateNotificationType,
} from "./Notification.store";

export const addNotification = async (data: AddNotificationType) => {
  const response = await api.post("/notifications", data);
  return response.data;
};

export const updateNotification = async (data: UpdateNotificationType) => {
  const response = await api.put("/notifications", data);
  return response.data;
};

export const updateNotificationMarkAsRead = async (userId: string) => {
  const response = await api.put(`/notifications/${userId}`);
  return response.data;
};

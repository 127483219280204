import { Marker } from "react-map-gl";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CustomMarker.module.scss";
const CustomMarker = ({ lat, lng, title, onClose }: any) => {
  return (
    <Marker
      className={styles.markerSection}
      longitude={lng}
      latitude={lat}
      anchor="bottom"
    >
      <Box className={styles.markerMain}>
        <Box className={styles.markerIcon} onClick={onClose}>
          <CloseIcon fontSize="small" className={styles.markerClose} />
        </Box>
        <Box className={styles.markerBody}>
          <p className={styles.markerTetx}>Point: {title}</p>
          <p className={styles.markerTetx}>lat: {lat}</p>
          <p className={styles.markerTetx}>lng: {lng}</p>
        </Box>
      </Box>
    </Marker>
  );
};

export default CustomMarker;

import {
  FilterParameters,
  ICurrentEventTypeFilterOptions,
} from "../MapComponents/FilterArea/FilterArea";
import { TripsLayer } from "@deck.gl/geo-layers";
import { CollisionFilterExtension } from "@deck.gl/extensions/typed";

import * as turf from "@turf/turf";
import { bearingToAzimuth } from "@turf/helpers";
import { submarineCables } from "./GeoJSONLayers/submarine_cables";
import {  LineLayer, TextLayer } from "@deck.gl/layers/typed";
import { getShipInfo, getShipPath } from "./apiCalls";
import { Dispatch, SetStateAction } from "react";
import { GeoJsonLayer, IconLayer} from "@deck.gl/layers/typed";
import {PathLayer} from '@deck.gl/layers/typed';
import { allPorts } from "./GeoJSONLayers/portData";
import { seaOfAzov } from "./GeoJSONLayers/seaOfAzov";
import { gulfOfGuinea } from "./GeoJSONLayers/gulfOfGuinea";
import { indianOcean } from "./GeoJSONLayers/inidanOcean";
import { persianGulf } from "./GeoJSONLayers/persianGulf";
import { daboDelgado } from "./GeoJSONLayers/daboDelgado";
import { globalTerritories } from "./GeoJSONLayers/territories.js";
import { aisStations } from "./GeoJSONLayers/aisStationData";
import { elecLink } from "./GeoJSONLayers/elecLink";
import { nemoLink } from "./GeoJSONLayers/nemoLink";
import { NSL } from "./GeoJSONLayers/NSL";
import { IFA } from "./GeoJSONLayers/IFA";
import { IFA2 } from "./GeoJSONLayers/IFA2";
import { britNed } from "./GeoJSONLayers/britNed";
import {
  PathData,
  LayerToggleSwitches,
  ShipDetailsInterFace,
  updateActiveStatus,
  ShipDetail,
  ObjectData,
  BunkeringInterface
} from "../MainMap";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { SHIP_FILTER_PROVIDER } from "../../../utils/Constants";

interface ArrowData {
  position: [number, number];
  angle: number;
  size: number;
  timestamp: number;
}

const ICON_MAPPING = {
  marker: { width: 102, height: 191, mask: true },
};

const getIconColor = (type: any) => {
  if (type === "unattributed") {
    return [241, 86, 75];
  }

  if (type === "attributed") {
    return [255, 165, 0];
  }

  if (type === "light") {
    return [36, 160, 202];
  }
};

export const filterShips = (
  allShips: any,
  filters: FilterParameters,
  dataType: string,
  forCalculatingShipCount = false
) => {
  const {
    country,
    length,
    markerColor,
    noImoShips,
    noInfoShips,
    sanctionedShips,
    providerFilters,
    type,
    width,
    heading: header,
    isMoving,
    iconStringNormal,
    iconStringSanctioned,
    subType,
  } = filters || {};
  let filteredFeatures = allShips.features;
  if (dataType === "spoofing") {
    filteredFeatures = filteredFeatures.filter((ship: any) => {
      return subType[ship.properties.subType];
    });
  }

  if (!noImoShips) {
    filteredFeatures = filteredFeatures.filter(
      (ship: any) => ship.properties.imo !== null
    );
  }

  if (sanctionedShips) {
    filteredFeatures = filteredFeatures.filter(
      (ship: any) => ship.properties.isSanctionedShip
    );
  }

  filteredFeatures = filteredFeatures.filter((ship: any) => {
    const { length: shipLength, width: shipWidth } = ship.properties;

    return (
      shipLength >= length[0] &&
      shipLength <= length[1] &&
      shipWidth >= width[0] &&
      shipWidth <= width[1]
    );
  });

  if (header) {
    filteredFeatures = filteredFeatures.filter((ship: any) => {
      const { heading: shipHeading } = ship.properties;

      return shipHeading >= header[0] && shipHeading <= header[1];
    });
  }

  if (isMoving) {
    if (isMoving === "yes") {
      filteredFeatures = filteredFeatures.filter(
        (ship: any) => ship.properties.moving
      );
    }
    if (isMoving === "no") {
      filteredFeatures = filteredFeatures.filter(
        (ship: any) => !ship.properties.moving
      );
    }
  }
  if (type !== "None") {
    filteredFeatures = filteredFeatures.filter(
      (ship: any) => ship.properties.ship_type === type
    );
  }

  if (country?.code !== "") {
    filteredFeatures = filteredFeatures.filter(
      (ship: any) => ship.properties.flag === country?.code
    );
  }

  if (providerFilters && SHIP_FILTER_PROVIDER.includes(dataType)) {
    filteredFeatures = filteredFeatures.filter((ship: any) => {
      const shipData =
        ship?.properties?.detectionData?.provider || ship?.properties?.provider;
      return shipData && providerFilters[shipData.toLowerCase()];
    });
  }

  if (forCalculatingShipCount) return filteredFeatures;
  return {
    type: "FeatureCollection",
    features: filteredFeatures,
    markerColor,
    iconStringNormal,
    iconStringSanctioned,
  };
};
export const getDeckGLLayers = (
  detections: any,
  zoomLevel: any,
  spoofingEvents: any,
  opticalSTS: any,
  AISSTS: any,
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions,
  sanctionedShips: any,
  setSelectedShip: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  shipDetailTabValue: string | null,
  setShipDetailTabValue: Dispatch<SetStateAction<string | null>>,
  selectedShip: any,
  setShowCursor: any,
  currentCoveraData: any,
  layerToggleSwitches: LayerToggleSwitches,
  setSelectedEvent: Dispatch<
    SetStateAction<{ [key: string]: ShipDetailsInterFace }>
  >,
  selectedEvent: { [key: string]: ShipDetailsInterFace },
  onShipClick: (tabId?: string) => void,
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
      dateRange: {startDate : string, endDate : string };
      showPath?:boolean;
      showAllpoints?:boolean;
    };
  },
  setShipPaths: Dispatch<
    SetStateAction<{
      [key: string]: {
        path: PathData[];
        color: string;
        analysis : boolean;
        dateRange: {startDate : string, endDate : string };
        showPath?:boolean;
        showAllpoints?:boolean;
      };
    }>
  >,
  getUniqueColor: () => void,
  selectedPath : PathData[],
  playbackValue : number,
  pathRef: React.MutableRefObject<number | null>,
  setShipDetails: Dispatch<
    SetStateAction<{
      [key: string]: ShipDetail;
    }>
  >,
  shipObjects: {
    [key: string]: {
      objects: ObjectData[];
      isOpticalShip : boolean;
      isEvent : boolean;
    };
  },
  similarShips:{
    [key: string]: ShipDetailsInterFace[];
  },
  showSimilarShips: boolean,
  area: { type: string; coordinates: [[number, number][]] } | null,
  hoveredId : number | null,
  droppedPins: any,
  features: any,
  showTemporaryCircle: any,
  coordinatesForEstimatedLocationInfo: [number, number] | null,
  setFilteredDetection: any,
  layersForFuturePathPrediction?: any,
  drawRef?: any,
  showDeckglPolygons?: any,
  setShowDeckglPolygons?: any,
  showGlobeView?: any,
  polygonData?: any,
  showShips?: boolean,
  selectedShipEstimatedLoactionLayer?: { startDate: string; data: any }[] | null,
  selectedShipEstimatedaLocationPath?: any,
  liveAISShips?:any
) => {
  console.log(polygonData, ':in deckgl')
  let layers: any = [];
  const isPointInPolygon = (
    point: [number, number],
    polygon: { type: string; coordinates: [[number, number][]] }
  ): boolean => {
    const [lng, lat] = point;
    const [polyCoords] = polygon.coordinates;
  
    let inside = false;
    for (let i = 0, j = polyCoords.length - 1; i < polyCoords.length; j = i++) {
      const [xi, yi] = polyCoords[i];
      const [xj, yj] = polyCoords[j];
  
      const intersect =
        (yi > lat) !== (yj > lat) && lng < ((xj - xi) * (lat - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };
  
  
  aisStations.features = aisStations.features.map((feature: any) => ({
    ...feature,
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(feature.geometry.coordinates[0]),
        parseFloat(feature.geometry.coordinates[1]),
      ],
    },
  }));

  if (
    Object.keys(currentEventTypeFilterOptions).length > 0 &&
    detections &&
    spoofingEvents
  ) {
    const { spoofing, bunkering, AIS, light, dark, unattributed, sanctioned } =
      currentEventTypeFilterOptions;
    const filteredLightShips = filterShips(detections.lightGeoJson, light, 'light');
    const filteredDarkShips = filterShips(detections.attributedGeoJson, dark, 'dark');
    const filteredSpoofingShips = filterShips(spoofingEvents, spoofing, 'spoofing');
    const filteredUnattributedShips = filterShips(
      detections.unattributedGeoJson,
      unattributed,
      "unattributed"
    );

    const selectedShipsArr: any[] = [];    
    const observationShipsArr: any [] = [];

    Object.keys(selectedShip).forEach((key) =>
      selectedShipsArr.push({...selectedShip[key],selectedShipKey: key})
    );
    Object.keys(selectedEvent).forEach((key) =>
      selectedShipsArr.push({...selectedEvent[key],selectedShipKey: key})
    );
    Object.keys(selectedShip).forEach((key) => {
      if (selectedShip[key].isObservationShip) {
        observationShipsArr.push({ ...selectedShip[key], selectedShipKey: key });
      }
    });

    const selectedShipsGeojson = {
      type: "FeatureCollection",
      features: selectedShipsArr.map((ship: any, i: number) => {
        let coordinates = [
          ship?.detectionData?.longitude || null,
          ship?.detectionData?.latitude || null,
        ];
        if (ship.isPathShip) {
          coordinates = [ship.parentLongitude, ship.parentLatitude];
        } else {
          coordinates = [
            ship?.detectionData?.longitude || ship.longitude,
            ship?.detectionData?.latitude || ship.latitude,
          ];
        }
       return {
        type: "Feature",
        id: i,
        geometry: {
          type: "Point",
          coordinates
        },
        properties: {
          ...ship,
          isActiveShip: ship.active ? true : false,
        },
      }}),
    };
    
    const similarShipsGeojson = {
      type: "FeatureCollection",
      features: Object.entries(similarShips).flatMap(([key, ships]) =>
        ships.map((ship: ShipDetailsInterFace, i: number) => ({
          type: "Feature",
          id: `${key}-${i}`,
          geometry: {
            type: "Point",
            coordinates: [ship.longitude || null, ship.latitude || null],
          },
          properties: {
            ...ship,
            isHovered: Number(ship.object_id) === hoveredId
          },
        }))
      ),
    };
    const svgWithCircle = `<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29340)" fill-opacity="1"/>
    <path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="#FF6D99" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M23 25.5C23 24.5 23.5 23.5 25 23.5C26.5 23.5 27 24.5 27 25.5C27 26.5 26 27 25.5 27.5C25.0547 27.9453 25 28 25 29" stroke="#111326" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="25" cy="31.5" r="1" fill="#111326"/>
    <defs>
    <radialGradient id="paint0_radial_3218_29340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
    <stop offset="0.27" stop-color="#00A3E3" stop-opacity="0"/>
    <stop offset="1" stop-color="#00A3E3"/>
    </radialGradient>
    </defs>
    </svg>`;

    const defaultSvg = `<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="#FF6D99" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M4.99951 11.5C4.99951 10.5 5.49951 9.5 6.99951 9.5C8.49951 9.5 8.99951 10.5 8.99951 11.5C8.99951 12.5 7.99951 13 7.49951 13.5C7.0542 13.9453 6.99951 14 6.99951 15" stroke="#111326" stroke-width="1.5" stroke-linecap="round"/>
    <circle cx="6.99951" cy="17.5" r="1" fill="#111326"/>
    </svg>`;
   
    const observationGeoJson = {
      type: "FeatureCollection",
      features: observationShipsArr.map((ship: any, i: number) => {
        let coordinates = [ship?.longitude || null, ship?.latitude || null]

       return {
        type: "Feature",
        id: i,
        geometry: {
          type: "Point",
          coordinates
        },
        properties: {
          ...ship,
          isActiveShip: ship.active
        },
      }}),
    };

const savedPolygons : any = []

    if (features) {
      Object.keys(features).forEach((key: any) => {
        const feature = features[key];
        if (feature.properties.renderDrawMode === "draw_circle") {
          savedPolygons.push(feature.properties.circlePoints);
        } else {
          savedPolygons.push(feature);
        }
      });
    }

  const createDottedLineFromCoordinates = (start: number[], end: number[]): { sourcePosition: number[]; targetPosition: number[]; }[] => {
    const dotLength = 0.1;
    const gapLength = 0.1
    const distance = Math.sqrt(
      Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)
    );
    const numBerOfDotsForDistance = Math.floor(distance / (dotLength + gapLength));
    const lineSegments = [];

    for (let i = 0; i < numBerOfDotsForDistance; i++) {
      const startOfDot = (i * (dotLength + gapLength)) / distance;
      const endOfDot = ((i * (dotLength + gapLength)) + dotLength) / distance;

      lineSegments.push({
        sourcePosition: [
          start[0] + startOfDot * (end[0] - start[0]),
          start[1] + startOfDot * (end[1] - start[1]),
        ],
        targetPosition: [
          start[0] + endOfDot * (end[0] - start[0]),
          start[1] + endOfDot * (end[1] - start[1]),
        ],
      });
    }

    return lineSegments;
  };
    
    const droppedPoints = {
      type: "FeatureCollection",
      features: droppedPins.map((point: any, i: number) => ({
        type: "Feature",
        id: i,
        geometry: {
          type: "Point",
          coordinates: [point?.lng || 0.01, point?.lat || 0.01],
        },
        properties: {
          ...point,
        },
      })),
    };
    const liveAreaAIS = liveAISShips.map((point:any, i: any) => ({
      type: "Feature",
      id: i,
      geometry: {
        type: "Point",
        coordinates: [point?.longitude || null, point?.latitude || null],
      },
      properties: {
        ...point,
        type: 'AIS',
        timestamp: point.timestamp / 1000
      },
    }))

    const polygonDataGeojsonFeatures =  { features: (polygonData ? polygonData.map((point: any, i: number) => ({
      type: "Feature",
      id: i,
      geometry: {
        type: "Point",
        coordinates: [point?.longitude || null, point?.latitude || null],
      },
      properties: {
        ...point,
        type: 'AIS',
        length: point.ship_info?.length,
          width:  point.ship_info?.width,
          flag: point.ship_info?.flag,
          imo: point.ship_info?.imo,
          ship_type: point.ship_info?.ship_type

      },
    })) : []).concat(liveAreaAIS)}



    const filteredPolygonDataGeojsonFeatures = filterShips(polygonDataGeojsonFeatures, AIS, 'AIS')

    const shipInArea = {
      type: "FeatureCollection",
      features: filteredPolygonDataGeojsonFeatures.features
    };

    layers = [
      ...layersForFuturePathPrediction,
      new GeoJsonLayer({
        id: "visibcircle",
        filled: true,
        stroked: true,
        pickable: true,
        lineWidthMinPixels: 2,
        getLineColor: [59, 178, 208],
        getFillColor: [59, 178, 208, 35],
        getFillOpacity: 0.3,
        visible: showDeckglPolygons,
        data: {
          type: "FeatureCollection",
          features: savedPolygons,
        },
        onClick: (f: any) => {
          if (!showGlobeView) {
            drawRef.current.set({
              type: "FeatureCollection",
              features: Object.keys(features).map((key) => ({
                ...features[key],
              })),
            });
            setShowDeckglPolygons(false);
          }
        },
      }),
      new GeoJsonLayer({
        id: "droppedPins",
        data: droppedPoints,
        pickable: true,
        pointType: "icon",
        iconAtlas: "./locationPin.svg",
        iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 13,
        getIconColor: (f: any) => [255, 0, 0],
        getIcon: (d: any) => "marker",
        onClick: (f: any) => {},
        visible: true,
      }),
      new GeoJsonLayer({
        id: "polygon",
        data: shipInArea,
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        // getIconColor: hexToRgb(filteredLightShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: AIS.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.synmax_ship_id;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...prevShips,
                  [shipId]: {},
                }));
                const shipDetails = await getShipInfo(
                  [shipId],
                  moment(f.object.properties.timestamp * 1000).format(
                    "YYYY-MM-DD"
                  )
                );
                const startTimestamp =
                  f.object.properties.timestamp * 1000 - 24 * 60 * 60 * 1000;
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.timestamp * 1000)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const shipPath = await getShipPath(
                  [shipId],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev: any) => ({
                  ...prev,
                  [shipId]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));
                const updatedShipDetails = {
                  ...shipDetails,
                  [shipId]: {
                    ...f.object.properties,
                    ...shipDetails[shipId],
                    type: f.object.properties.type,
                    active: true,
                  },
                };
                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  ...updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
                console.log(f.object.properties);
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: showSimilarShips,
      }),
      new GeoJsonLayer({
        id: "unattributed",
        data:
          !showSimilarShips && area
            ? filteredUnattributedShips.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (filteredUnattributedShips as any),
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        // getIconColor: hexToRgb(filteredLightShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: filteredUnattributedShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = `unattributed-${f.object.properties.object_id}`;
              setShipDetailTabValue(shipId);
              setSelectedShip((prevShips) => ({
                ...(prevShips as { [key: string]: ShipDetailsInterFace }),
                [shipId]: {} as ShipDetailsInterFace,
              }));
              const updatedShipDetails = {
                [shipId]: {
                  ...f.object.properties,
                  synmax_ship_id: shipId,
                  active: true,
                },
              };
              setSelectedShip((prevShips) => ({
                ...updateActiveStatus(prevShips, shipId),
                ...updatedShipDetails,
              }));
              setSelectedEvent((prevEvents) => ({
                ...prevEvents,
                ...updateActiveStatus(prevEvents, shipId),
              }));
            } catch (err) {}
          })();
        },
        visible: unattributed.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "submarineCables",
        data: submarineCables as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#a8a8a8") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "elecLink-submarineCables",
        data: elecLink as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#168e9e") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "nemoLink-submarineCables",
        data: nemoLink as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#38e4ae") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "nslk-submarineCables",
        data: NSL as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#F85348") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "ifa-submarineCables",
        data: IFA as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#FFC0CB") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "ifa-two-submarineCables",
        data: IFA2 as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#800080") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "britNed-submarineCables",
        data: britNed as any,
        pickable: true,
        stroked: false,
        filled: true,
        extruded: true,
        lineWidthScale: 20,
        lineWidthMinPixels: 8,
        getLineColor: hexToRgb("#fa9f42") as any,
        getRadius: 100,
        getLineWidth: 1,
        getElevation: 30,
        visible: layerToggleSwitches.submarineCablesVisible,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "coverage-layer",
        data: currentCoveraData,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#4287f5") as any,
        getFillColor: [46, 96, 176],
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.coverage,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "high-risk-seaOfAzov-layer",
        data: seaOfAzov,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "high-risk-gulfOfGuinea-layer",
        data: gulfOfGuinea,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "high-risk-indianOcean-layer",
        data: indianOcean,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "high-risk-persian-gulf-layer",
        data: persianGulf,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "high-risk-daboDelgado-layer",
        data: daboDelgado,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#FF0000") as any,
        getFillColor: hexToRgb("#FF0000") as any,
        getLineWidth: 1,
        opacity: 0.3,
        visible: layerToggleSwitches.highRisk,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "territories-layer",
        data: globalTerritories,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getLineColor: hexToRgb("#285eb5") as any,
        getFillColor: hexToRgb("#285eb5") as any,
        getLineWidth: 1,
        opacity: 0.1,
        visible: layerToggleSwitches.territories,
        onHover: (e) => {
          setShowCursor(true);
        },
      }),
      new GeoJsonLayer({
        id: "ports",
        data: allPorts as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 10,
        getIconAngle: (f: any) => f.properties.heading,
        getIconColor: hexToRgb("#FF6B48") as any,
        onHover: (e) => {
          setShowCursor(true);
        },
        // TODO: fix icon so color is not hard coded
        getIcon: (f: any) => ({
          url: "./port_default.svg",
          height: 191,
          width: 102,
          mask: true,
        }),
        visible: layerToggleSwitches.ports,
      }),
      new GeoJsonLayer({
        id: "infastructure-layer",
        data: aisStations as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 10,
        getIconAngle: (f: any) => f.properties.heading,
        getIconColor: hexToRgb("#36a879") as any,
        onHover: (e) => {
          setShowCursor(true);
        },
        // TODO: fix icon so color is not hard coded
        getIcon: (f: any) => ({
          url: "./infrastructure.svg",
          height: 191,
          width: 102,
          mask: true,
        }),
        visible: layerToggleSwitches.infrastructure,
      }),
      new GeoJsonLayer({
        id: "AISSTS",
        data:
          !showSimilarShips && area
            ? AISSTS.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (AISSTS as any),
        pickable: true,
        pointType: "icon",
        iconAtlas: "./bunkering/greenGreenNew.svg",
        iconMapping: {
          marker: { height: 100, width: 100, mask: false },
        },
        getIconSize: zoomLevel * 6,
        getIconColor: (f: any) => [20, 128, 0] as any,
        getIcon: (d: any) => "marker",
        // getIconAngle: (f: any) => f.properties.frontend_rotation,
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = `${f.object.properties.synmax_ship_id_1}_${f.object.properties.synmax_ship_id_2}`;
              const shipId1 = f.object.properties.synmax_ship_id_1;
              const shipId2 = f.object.properties.synmax_ship_id_2;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...(prevShips as { [key: string]: ShipDetailsInterFace }),
                  [shipId]: {} as ShipDetailsInterFace,
                }));
                const dateString = moment(
                  f.object.properties.timestamp_t0
                ).format("YYYY-MM-DD");
                const shipDetails = await getShipInfo(
                  [shipId1, shipId2],
                  dateString
                );
                const updatedShipDetails = {
                  [shipId1]: {
                    ...f.object.properties,
                    ...shipDetails[shipId1],
                    type: f.object.properties.type,
                  },
                  [shipId2]: {
                    ...f.object.properties,
                    ...shipDetails[shipId2],
                    type: f.object.properties.type,
                  },
                  active: true,
                  synmax_ship_id: shipId,
                  ...f.object.properties,
                  ship1_status: "AIS",
                  ship2_status: "AIS",
                };

                const startTimestamp =
                  f.object.properties.timestamp_t0 - 24 * 60 * 60;
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.timestamp_t0)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const shipPath1 = await getShipPath([shipId1], startDate, endDate);
                const shipPathColor1 = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId1]: {
                    path: shipPath1,
                    color: shipPathColor1,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));

                const shipPath = await getShipPath(
                  [shipId2],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId2]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));

                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  [shipId]: updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: AIS.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "attributed",
        data:
          !showSimilarShips && area
            ? filteredDarkShips.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (filteredDarkShips as any),
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        getIconColor: hexToRgb(filteredDarkShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: f.properties.isSanctionedShip
            ? filteredDarkShips.iconStringSanctioned
            : filteredDarkShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.detectionData.attribution;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...prevShips,
                  [shipId]: {},
                }));
                const dateSting = moment
                  .utc(f.object.properties.detectionData.acquired)
                  .format("YYYY-MM-DD");
                const shipDetails = await getShipInfo([shipId], dateSting);
                setShipDetails((prev) => ({
                  ...prev,
                  ...shipDetails,
                }));
                const startTimestamp =
                  f.object.properties.detectionData.acquired -
                  24 * 60 * 60 * 1000;
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.detectionData.acquired)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const shipPath = await getShipPath(
                  [shipId],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));
                const updatedShipDetails = {
                  ...shipDetails,
                  [shipId]: {
                    ...f.object.properties,
                    ...shipDetails[shipId],
                    ...f.object.properties.detectionData,
                    type: f.object.properties.type,
                    active: true,
                  },
                };
                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  ...updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: dark.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "light",
        data:
          !showSimilarShips && area
            ? filteredLightShips.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (filteredLightShips as any),
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 5,
        getIconAngle: (f: any) => f.properties.heading,
        // getIconColor: hexToRgb(filteredLightShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: f.properties.isSanctionedShip
            ? filteredLightShips.iconStringSanctioned
            : filteredLightShips.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        // onHover: (e) => {
        //   setShowCursor(true)
        // },
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.detectionData.attribution;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...prevShips,
                  [shipId]: {},
                }));
                const startTimestamp =
                  f.object.properties.detectionData.acquired -
                  24 * 60 * 60 * 1000;
                const dateSting = moment
                  .utc(f.object.properties.detectionData.acquired)
                  .format("YYYY-MM-DD");
                const shipDetails = await getShipInfo([shipId], dateSting);
                setShipDetails((prev) => ({
                  ...prev,
                  ...shipDetails,
                }));
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.detectionData.acquired)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const shipPath = await getShipPath(
                  [shipId],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));
                const updatedShipDetails = {
                  ...shipDetails,
                  [shipId]: {
                    ...f.object.properties,
                    ...shipDetails[shipId],
                    ...f.object.properties.detectionData,
                    type: f.object.properties.type,
                    active: true,
                  },
                };
                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  ...updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: light.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "spoofingEvents",
        data:
          !showSimilarShips && area
            ? filteredSpoofingShips.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (filteredSpoofingShips as any),
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        // getIconColor: hexToRgb(filteredLightShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: filteredSpoofingShips.iconStringNormal,
          height: 200,
          width: 200,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.synmax_ship_id;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  [shipId]: {},
                }));
                const dateSting = moment
                  .utc(f.object.properties.timestamp)
                  .format("YYYY-MM-DD");
                const shipDetails = await getShipInfo([shipId], dateSting);
                setShipDetails((prev) => ({
                  ...prev,
                  ...shipDetails,
                }));
                const startTimestamp =
                  f.object.properties.timestamp - 24 * 60 * 60 * 1000;
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.timestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;

                const shipPath = await getShipPath(
                  [shipId],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));
                const updatedShipDetails = {
                  ...shipDetails,
                  [shipId]: {
                    ...f.object.properties,
                    ...shipDetails[shipId],
                    type: f.object.properties.type,
                    active: true,
                  },
                };
                setSelectedEvent((prevEvents) => ({
                  ...updateActiveStatus(prevEvents, shipId),
                  ...updatedShipDetails,
                }));
                setSelectedShip((prevShips) => ({
                  ...prevShips,
                  ...updateActiveStatus(prevShips, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: spoofing.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "opticalSTS",
        data:
          !showSimilarShips && area
            ? opticalSTS.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (opticalSTS as any),
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 5,
        getIconColor: (f: any) => [] as any,
        getIconAngle: (f: any) => f.properties.frontend_rotation,
        getIcon: (f: any) => ({
          url: `./bunkering/${getBunkeringImageUrl(f.properties)}`,
          height: 100,
          width: 100,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.bunkering_id;
              const shipId1 =
                f.object.properties.ship1_attribution !== "None" &&
                f.object.properties.ship1_attribution
                  ? f.object.properties.ship1_attribution
                  : `unattributed-${f.object.properties.ship1_object_id}`;
              const shipId2 =
                f.object.properties.ship2_attribution !== "None" &&
                f.object.properties.ship1_attribution
                  ? f.object.properties.ship2_attribution
                  : `unattributed-${f.object.properties.ship2_object_id}`;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...(prevShips as { [key: string]: ShipDetailsInterFace }),
                  [shipId]: {} as ShipDetailsInterFace,
                }));
                const dateSting = moment(f.object.properties.acquired).format(
                  "YYYY-MM-DD"
                );
                const ship1Details =
                  f.object.properties.ship1_attribution !== "None" &&
                  (await getShipInfo([shipId1], dateSting));
                setShipDetails((prev) => ({
                  ...prev,
                  ...ship1Details,
                }));
                if (f.object.properties.ship1_attribution !== "None") {
                  const startTimestamp =
                    f.object.properties.acquired - 24 * 60 * 60 * 1000;
                  const startDate = `${moment
                    .utc(startTimestamp)
                    .format("YYYY-MM-DD HH:mm:ss")}`;
                  const endDate = `${moment
                    .utc(f.object.properties.acquired)
                    .format("YYYY-MM-DD HH:mm:ss")}`;
                  const shipPath = await getShipPath(
                    [shipId1],
                    startDate,
                    endDate
                  );
                  const shipPathColor = getUniqueColor();
                  setShipPaths((prev) => ({
                    ...prev,
                    [shipId1]: {
                      path: shipPath,
                      color: shipPathColor,
                      analysis: false,
                      dateRange: { startDate, endDate },
                    },
                  }));
                }
                if (f.object.properties.ship2_attribution !== "None") {
                  const startTimestamp =
                    f.object.properties.acquired - 24 * 60 * 60 * 1000;
                  const startDate = `${moment
                    .utc(startTimestamp)
                    .format("YYYY-MM-DD HH:mm:ss")}`;
                  const endDate = `${moment
                    .utc(f.object.properties.acquired)
                    .format("YYYY-MM-DD HH:mm:ss")}`;
                  const shipPath = await getShipPath(
                    [shipId2],
                    startDate,
                    endDate
                  );
                  const shipPathColor = getUniqueColor();
                  setShipPaths((prev) => ({
                    ...prev,
                    [shipId2]: {
                      path: shipPath,
                      color: shipPathColor,
                      analysis: false,
                      dateRange: { startDate, endDate },
                    },
                  }));
                }

                const ship2Details =
                  f.object.properties.ship2_attribution !== "None" &&
                  (await getShipInfo([shipId2], dateSting));
                setShipDetails((prev) => ({
                  ...prev,
                  ...ship2Details,
                }));
                const updatedShipDetails = {
                  [shipId1]: {
                    ...ship1Details[shipId1],
                    ...f.object.properties,
                    attribution: f.object.properties.ship1_attribution,
                    heading: f.object.properties.ship1_heading,
                    latitude: f.object.properties.ship1_lat,
                    longitude: f.object.properties.ship1_lon,
                    length: f.object.properties.ship1_length,
                    moving: f.object.properties.ship1_moving,
                    object_id: f.object.properties.ship1_object_id,
                    ship_type: f.object.properties.ship1_ship_type,
                    width: f.object.properties.ship1_width,
                    status: f.object.properties.ship1Status,
                    type:
                      f.object.properties.ship1Status === "blue"
                        ? "light"
                        : f.object.properties.ship1Status === "orange"
                        ? "attributed"
                        : "unattributed",
                    synmax_ship_id_1: shipId1,
                    isShip1: true,
                    isOpticalBunkering: true,
                  },
                  [shipId2]: {
                    ...ship2Details[shipId2],
                    ...f.object.properties,
                    attribution: f.object.properties.ship2_attribution,
                    heading: f.object.properties.ship2_heading,
                    latitude: f.object.properties.ship2_lat,
                    longitude: f.object.properties.ship2_lon,
                    length: f.object.properties.ship2_length,
                    moving: f.object.properties.ship2_moving,
                    object_id: f.object.properties.ship2_object_id,
                    ship_type: f.object.properties.ship2_ship_type,
                    width: f.object.properties.ship2_width,
                    status: f.object.properties.ship2Status,
                    type:
                      f.object.properties.ship2Status === "blue"
                        ? "light"
                        : f.object.properties.ship2Status === "orange"
                        ? "attributed"
                        : "unattributed",
                    synmax_ship_id_2: shipId2,
                    isShip1: false,
                    isOpticalBunkering: true,
                  },
                  ...f.object.properties,
                  active: true,
                  synmax_ship_id: shipId,
                  latitude: f.object.properties.ship1_lat,
                  longitude: f.object.properties.ship1_lon,
                  synmax_ship_id_1: shipId1,
                  synmax_ship_id_2: shipId2,
                  object_id_1: f.object.properties.ship1_object_id,
                  object_id_2: f.object.properties.ship2_object_id,
                  ship1_status: getBunkeringEvent(
                    f.object.properties.ship1Status
                  ),
                  ship2_status: getBunkeringEvent(
                    f.object.properties.ship2Status
                  ),
                };
                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  [shipId]: updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: bunkering.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "sanctionedShips",
        data:
          !showSimilarShips && area
            ? sanctionedShips.features.filter(
                (ship: any) =>
                  !isPointInPolygon(ship.geometry.coordinates, area)
              )
            : (sanctionedShips as any),
        pickable: true,
        pointType: "icon",
        // iconAtlas: "./blueEye.svg",
        // iconMapping: ICON_MAPPING,
        getIconSize: zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        // getIconColor: hexToRgb(filteredLightShips.markerColor) as any,
        getIcon: (f: any) => ({
          url: sanctioned.iconStringNormal,
          height: 191,
          width: 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = f.object.properties.synmax_ship_id;
              if (shipId) {
                setShipDetailTabValue(shipId);
                setSelectedShip((prevShips) => ({
                  ...prevShips,
                  [shipId]: {},
                }));
                const dateString = moment(
                  f.object.properties.timestamp * 1000
                ).format("YYYY-MM-DD");
                const shipDetails = await getShipInfo([shipId], dateString);
                setShipDetails((prev) => ({
                  ...prev,
                  ...shipDetails,
                }));
                const startTimestamp =
                  f.object.properties.timestamp * 1000 - 24 * 60 * 60 * 1000;
                const startDate = `${moment
                  .utc(startTimestamp)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const endDate = `${moment
                  .utc(f.object.properties.timestamp * 1000)
                  .format("YYYY-MM-DD HH:mm:ss")}`;
                const shipPath = await getShipPath(
                  [shipId],
                  startDate,
                  endDate
                );
                const shipPathColor = getUniqueColor();
                setShipPaths((prev) => ({
                  ...prev,
                  [shipId]: {
                    path: shipPath,
                    color: shipPathColor,
                    analysis: false,
                    dateRange: { startDate, endDate },
                  },
                }));
                const updatedShipDetails = {
                  ...shipDetails,
                  [shipId]: {
                    ...f.object.properties,
                    ...shipDetails[shipId],
                    type: f.object.properties.type,
                    active: true,
                  },
                };
                setSelectedShip((prevShips) => ({
                  ...updateActiveStatus(prevShips, shipId),
                  ...updatedShipDetails,
                }));
                setSelectedEvent((prevEvents) => ({
                  ...prevEvents,
                  ...updateActiveStatus(prevEvents, shipId),
                }));
              }
            } catch (err) {
              console.log("Error fetching ship details: ", err);
            }
          })();
        },
        visible: sanctioned.checked && showShips,
      }),
      new GeoJsonLayer({
        id: "selectedShips",
        data: selectedShipsGeojson,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 15,
        getIconAngle: (f: any) => getSelectedShipHeading(f.properties),
        getIcon: (f: any) => getSelectedIcon(shipPaths, f.properties),
        onClick: (f: any) => {
          onShipClick(f?.object?.properties?.synmax_ship_id);
          const shipId = f?.object?.properties?.synmax_ship_id;
          shipId && setShipDetailTabValue(shipId);
          const getPrevShips = (prevShips: any) => {
            const updatedActiveStateShips: any = {};
            Object.keys(prevShips).forEach((key: any) => {
              if (key === shipId) {
                updatedActiveStateShips[key] = {
                  ...prevShips[key],
                  active: true,
                };
              } else {
                updatedActiveStateShips[key] = {
                  ...prevShips[key],
                  active: false,
                };
              }
            });
            return updatedActiveStateShips;
          };
          if (Object.keys(selectedShip).includes(shipId)) {
            setSelectedShip((prevShips) => ({
              ...prevShips,
              ...getPrevShips(prevShips),
            }));
          } else if (Object.keys(selectedEvent).includes(shipId)) {
            setSelectedEvent((prevShips) => ({
              ...prevShips,
              ...getPrevShips(prevShips),
            }));
          }
        },
        visible: true,
      }),
      new GeoJsonLayer({
        id: "observationShips",
        data: observationGeoJson as any,
        pickable: true,
        pointType: "icon",
        getIconSize: zoomLevel * 15,
        getIconAngle: (f: any) => f.properties.heading,
        getIcon: (f: any) => getSelectedIcon(shipPaths, f.properties),
        onClick: (f: any) => {
          console.log(f);
        },
      }),
      new GeoJsonLayer({
        id: "similarShips",
        data: similarShipsGeojson,
        pickable: true,
        pointType: "icon",
        getIconSize: (f: any) =>
          f.properties.isHovered ? zoomLevel * 12 : zoomLevel * 6,
        getIconAngle: (f: any) => f.properties.heading,
        getIcon: (f: any) => ({
          url:
            "data:image/svg+xml;base64," +
            btoa(f.properties.isHovered ? svgWithCircle : defaultSvg),
          height: f.properties.isHovered ? 200 : 191,
          width: f.properties.isHovered ? 205 : 102,
          mask: false,
        }),
        onClick: (f: any) => {
          (async () => {
            try {
              onShipClick();
              const shipId = `unattributed-${f.object.properties.object_id}`;
              setShipDetailTabValue(shipId);
              setSelectedShip((prevShips) => ({
                ...(prevShips as { [key: string]: ShipDetailsInterFace }),
                [shipId]: {} as ShipDetailsInterFace,
              }));
              const updatedShipDetails = {
                [shipId]: {
                  ...f.object.properties,
                  synmax_ship_id: shipId,
                  active: true,
                  type: "unattributed",
                },
              };
              setSelectedShip((prevShips) => ({
                ...updateActiveStatus(prevShips, shipId),
                ...updatedShipDetails,
              }));
              setSelectedEvent((prevEvents) => ({
                ...prevEvents,
                ...updateActiveStatus(prevEvents, shipId),
              }));
            } catch (err) {
              console.log(err);
            }
          })();
        },
      }),
    ];

    const calculateAngle = (
      start: [number, number],
      end: [number, number],
      heading: number
    ): number => {
      if (heading < 0 || heading > 360) {
        heading = calculateBearing(start, end);
      }
      return (360 - heading) % 360;
    };

    const calculateBearing = (
      start: [number, number],
      end: [number, number]
    ): number => {
      const [startLon, startLat] = start?.map(degToRad);
      const [endLon, endLat] = end?.map(degToRad);

      const dLon = endLon - startLon;
      const y = Math.sin(dLon) * Math.cos(endLat);
      const x =
        Math.cos(startLat) * Math.sin(endLat) -
        Math.sin(startLat) * Math.cos(endLat) * Math.cos(dLon);
      let bearing = Math.atan2(y, x);

      bearing = radToDeg(bearing);
      return (bearing + 360) % 360;
    };

    const degToRad = (deg: number): number => (deg * Math.PI) / 180;
    const radToDeg = (rad: number): number => (rad * 180) / Math.PI;

    const createLayersData = (
      data: PathData[],
      showAllPoints: any
    ): ArrowData[] => {
      const headingData = data?.filter((d) => d.heading !== null);
      const path: [number, number][] = headingData?.map((d) => [
        d.longitude,
        d.latitude,
      ]) || [];
      const step = showAllPoints
        ? 1
        : Math.max(1, Math.floor(path.length / (zoomLevel * 2)));
      const arrows: ArrowData[] = [];
      if (path.length > 1) {
        const firstPoint = path[0];
        const secondPoint = path[1];
        const firstHeading = headingData[0]?.heading ?? 0;
        arrows.push({
          position: firstPoint,
          angle: calculateAngle(firstPoint, secondPoint, firstHeading),
          size: 10,
          timestamp: headingData[0]?.timestamp,
        });
      }

      for (let i = 0; i < path.length - 1; i += step) {
        const start = path[i];
        const end = path[i + 1];

        if (end) {
          const midPoint: [number, number] = [
            (start[0] + end[0]) / 2,
            (start[1] + end[1]) / 2,
          ];

          const heading = headingData[i]?.heading ?? 0;
          arrows.push({
            position: midPoint,
            angle: calculateAngle(start, end, heading),
            size: 10,
            timestamp: headingData[i]?.timestamp,
          });
        }
      }
      if (path.length > 1) {
        const lastPoint = path[path.length - 1];
        const secondLastPoint = path[path.length - 2];
        const lastHeading = headingData[path.length - 1]?.heading ?? 0;
        arrows.push({
          position: lastPoint,
          angle: calculateAngle(secondLastPoint, lastPoint, lastHeading),
          size: 10,
          timestamp: headingData[path.length - 1]?.timestamp,
        });
      }
      return arrows;
    };

    if (selectedShipEstimatedLoactionLayer !== null) {
      layers.push(
        new GeoJsonLayer({
          id: "estimated-ship-loaction",
          data: selectedShipEstimatedLoactionLayer,
          pickable: true,
          stroked: true,
          filled: true,
          lineWidthMinPixels: 2,
          getLineColor: hexToRgb("#D03527"),
          getFillColor: hexToRgb("#D03527"),
          getLineWidth: 1,
          opacity: 0.3,
          visible: true,
          onHover: (e) => {
            setShowCursor(true);
          },
        })
      );
    }

    const calcuateMilesBetweenTwoCoordinates = (
      lat1: number,
      lon1: number,
      lat2?: number,
      lon2?: number
    ): number => {
      // haversine formula for calculating distnance between 2 points
      // https://en.wikipedia.org/wiki/Haversine_formula

      const toRadians = (degrees: number): number => {
        return degrees * (Math.PI / 180);
      };

      if (!lat2 || !lon2) {
        return 0;
      }
      const R = 3958.8; // Radius of the Earth in miles

      const dLat = toRadians(lat2 - lat1);
      const dLon = toRadians(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // Distance in miles
    };

    if (
      selectedShipEstimatedaLocationPath.length > 0 &&
      selectedShipEstimatedLoactionLayer !== null
    ) {
      if (coordinatesForEstimatedLocationInfo !== null) {
        const distanceBewteenPoints = calcuateMilesBetweenTwoCoordinates(
          selectedShipEstimatedaLocationPath[0][0],
          selectedShipEstimatedaLocationPath[0][1],
          selectedShipEstimatedaLocationPath[1][0],
          selectedShipEstimatedaLocationPath[1][1]
        );
        const textData = [
          {
            position: coordinatesForEstimatedLocationInfo,
            text: `Distance:${distanceBewteenPoints}`,
          },
        ];

        const estimatedLoacationTextLayer = new TextLayer({
          id: "estimated-loacation-text",
          data: textData,
          getPosition: (d) => d.position,
          getText: (d) => d.text,
          getSize: 12,
          getColor: [255, 255, 255],
          sizeUnits: "pixels",
          billboard: true,
          backgroundPadding: [12, 12],
          fontFamily: "Inter",
          outlineWidth: 2,
          outlineColor: [0, 0, 0],
          backgroundColor: hexToRgb("#24263C"),
        });
        layers.push(estimatedLoacationTextLayer);
      }

      const lineData = createDottedLineFromCoordinates(
        selectedShipEstimatedaLocationPath[0],
        selectedShipEstimatedaLocationPath[1]
      );
      const estimatedLoactionLayer = new LineLayer({
        id: "estimated-location-line",
        data: lineData,
        getSourcePosition: (d) => d.sourcePosition,
        getTargetPosition: (d) => d.targetPosition,
        getColor: () => hexToRgb("#FFFFFF"),
        getWidth: () => 3,
      });

      layers.push(estimatedLoactionLayer);
    }

    Object.keys(shipPaths).forEach((synmax_ship_id) => {
      const pathData = shipPaths[synmax_ship_id].path;
      const showPath = shipPaths[synmax_ship_id]?.showPath ? true : false;
      const arrowData = createLayersData(
        pathData,
        shipPaths[synmax_ship_id].showAllpoints
      );
      const pathColor = shipPaths[synmax_ship_id].color;
      const pathAnalysis = shipPaths[synmax_ship_id].analysis;
      showPath &&
        (pathAnalysis
          ? layers.push(
              new IconLayer({
                id: `path-analysis-${synmax_ship_id}`,
                data: pathData,
                getPosition: (d: any) => [d.longitude, d.latitude],
                getIcon: () => "arrow-icon",
                getSize: zoomLevel > 10 ? zoomLevel : 10,
                getAngle: (d: any) => d.angle,
                getColor: () => hexToRgb(pathColor) as any,
                iconAtlas: "./circleIcon.svg",
                iconMapping: {
                  "arrow-icon": {
                    x: 0,
                    y: 0,
                    width: 72,
                    height: 72,
                    mask: true,
                  },
                },
                pickable: true,
                onHover: (info: any) => {
                  if (info.object) {
                    setShowCursor(true);
                  } else {
                    setShowCursor(false);
                  }
                },
              })
            )
          : layers.push(
              new PathLayer({
                id: `path-layer-${synmax_ship_id}`,
                data: [pathData],
                getPath: (d: any) =>
                  d.map((item: any) => [item.longitude, item.latitude]),
                getColor: () => hexToRgb(pathColor),
                getWidth: 3,
                widthMinPixels: 2,
                rounded: true,
                parameters: {
                  depthTest: false,
                },
              }),
              new IconLayer({
                id: `icon-layer-${synmax_ship_id}`,
                data: arrowData,
                getPosition: (d: any) => d.position,
                getIcon: () => "arrow-icon",
                getSize: zoomLevel > 6 ? zoomLevel : 6,
                getAngle: (d: any) => d.angle,
                getColor: () => [255, 255, 255, 255],
                iconAtlas: "./pathArray.svg",
                iconMapping: {
                  "arrow-icon": {
                    x: 0,
                    y: 0,
                    width: 10,
                    height: 6,
                    mask: true,
                  },
                },
              })
            ));
    });
    
    Object.keys(shipObjects).forEach((synmax_ship_id) => {
      const shipObjectsData = shipObjects[synmax_ship_id].objects;
      const isOpticalShip = shipObjects[synmax_ship_id].isOpticalShip;
      const isEvent = shipObjects[synmax_ship_id].isEvent;
      const showPath = shipPaths[synmax_ship_id]?.showPath ? true : false;

      const iconData = shipObjectsData.map((ship: any) => {
        let iconPath: string | undefined = filteredLightShips.iconStringNormal;
        if (ship.attribution === "None") {
          iconPath = filteredUnattributedShips.iconStringNormal;
        } else if (ship.attribution && !ship.dark) {
          iconPath = filteredLightShips.iconStringNormal;
        } else if (ship.attribution && ship.dark) {
          iconPath = filteredDarkShips.iconStringNormal;
        } 
        return {
          position: [ship.longitude, ship.latitude],
          angle: (360 - ship?.heading) % 360,
          properties: {
            ...ship,
            iconPath : iconPath || filteredLightShips.iconStringNormal,
          },
        };
      });
      showPath && layers.push(
        new IconLayer({
          id: `ship-objects-${synmax_ship_id}`,
          data: iconData,
          getPosition: (d: any) => d.position,
          getSize: zoomLevel * 5,
          getAngle: (d: any) => d.angle,
          getIcon: (f: any) => ({
            url: f.properties.iconPath,
            x: 0,
            y: 0,
            width: 102,
            height: 191,
            mask: false,
          }),
          pickable: true,
          onClick: (f: any) => {
            const shipId = synmax_ship_id;
            const shipData = f?.object?.properties;
            isOpticalShip && shipDetailTabValue
              ? setSelectedShip((prev) => {
                  const currentShipDetails = prev[shipDetailTabValue];
                  if (currentShipDetails) {
                    const updatedDetails = {
                      ...((currentShipDetails as BunkeringInterface)[shipId] ||
                        {}),
                      ...shipData,
                    };
                    return {
                      ...prev,
                      [shipDetailTabValue]: {
                        ...(currentShipDetails as BunkeringInterface),
                        [shipId]: updatedDetails,
                      },
                    };
                  }
                  return prev;
                })
              : isEvent
              ? setSelectedEvent((prev) => {
                const updatedState = {
                  ...prev,
                  [shipId]: {
                    ...prev[shipId],
                    ...shipData,
                    isPathShip: true,
                    parentLongitude:
                      prev[shipId]?.parentLongitude ??
                      prev[shipId]?.longitude,
                    parentLatitude:
                      prev[shipId]?.parentLatitude ?? prev[shipId]?.latitude,
                  },
                };
                return updatedState;
              })
              : setSelectedShip((prev) => {
                  const updatedState = {
                    ...prev,
                    [shipId]: {
                      ...prev[shipId],
                      ...shipData,
                      isPathShip: true,
                      parentLongitude:
                        prev[shipId]?.parentLongitude ??
                        prev[shipId]?.longitude,
                      parentLatitude:
                        prev[shipId]?.parentLatitude ?? prev[shipId]?.latitude,
                    },
                  };
                  return updatedState;
                });
          },
        })
      );
    });
    
    if (selectedPath.length > 1 && pathRef.current !== null) {
      console.log(selectedPath.length, ':selectedPath.length')
      const currentPosition = selectedPath[playbackValue] || selectedPath[0];
      const nextPosition = selectedPath[playbackValue + 1] || selectedPath[1];
      const heading = currentPosition?.heading ? currentPosition?.heading : 0;
      layers.push(
        new IconLayer({
          id: "moving-ship",
          data: [
            {
              position: [currentPosition.longitude, currentPosition.latitude],
              angle: calculateAngle(
                [currentPosition.longitude, currentPosition.latitude],
                [nextPosition.longitude, nextPosition.latitude],
                heading
              ),
            },
          ],
          getPosition: (d: any) => d.position,
          getIcon: () => "arrow-icon",
          getSize: zoomLevel * 8,
          getAngle: (d: any) => d.angle,
          getColor: hexToRgb(filteredLightShips.markerColor) as any,
          iconAtlas: "./blueEye.svg",
          iconMapping: {
            "arrow-icon": {
              x: 0,
              y: 0,
              height: 191,
              width: 102,
              mask: true,
            },
          },
        })
      );
    }
  }
  return layers;
};

function hexToRgb(hex: string): [number, number, number] {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const { r, g, b } = (
    result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  ) as any;

  return [r, g, b];
}

const getBunkeringImageUrl = (properties: any) => {
  const { ship1Status, ship2Status } = properties;
  switch (ship1Status + ship2Status) {
    case "redblue":
      return "redBlueNew.svg";
    case "bluered":
      return "blueRedNew.svg";
    case "redred":
      return "redRedNew.svg";
    case "orangered":
      return "orangeRedNew.svg";
    case "redorange":
      return "redOrangeNew.svg";
    case "orangeblue":
      return "orangeBlueNew.svg";
    case "blueorange":
      return "blueOrangeNew.svg";
    case "orangeorange":
      return "orangeOrangeNew.svg";
    case "blueblue":
      return "blueBlueNew.svg";
    case "aisais":
      return "greenGreenNew.svg";
  }
};

const getBunkeringEvent = (status: string) => {
  switch (status) {
    case "red":
      return "Unattributed";
    case "blue":
      return "Light";
    case "orange":
      return "Dark";
    case "ais":
      return "AIS";
  }
};

const getIndividualShipColor = (ship1Status: any) => {

  if(ship1Status === 'red') {
    return '#fa5849'
  }
  if(ship1Status === 'orange') {
    return '#ffa500'
  }
  if(ship1Status === 'blue') {
    return '#00a3e3'
  }
  if(ship1Status === 'ais') {
    return '#00eb6c'
  }
}

const getSelectedIcon = (paths: any, properties: any) => {
  if (properties.type === "opticalSTS") {
    const { ship1Status, ship2Status, isActiveShip } = properties;

    const ship1color = getIndividualShipColor(ship1Status);
    const ship2color = getIndividualShipColor(ship2Status);

    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_4053_23602)" fill-opacity="${
          isActiveShip ? "1" : "0.4"
        }"/>
<rect x="15.5" y="16" width="9.5" height="19" fill="${ship1color}"/>
<rect x="25" y="16" width="9.5" height="19" fill="${ship2color}"/>
<rect x="15.5" y="16" width="19" height="19" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M25 16V35" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
<defs>
<radialGradient id="paint0_radial_4053_23602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

`),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "AISSTS") {
    const { isActiveShip } = properties;
    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_4053_23602)" fill-opacity="${
          isActiveShip ? "1" : "0.4"
        }"/>
<rect x="15.5" y="16" width="9.5" height="19" fill="#00eb6c"/>
<rect x="25" y="16" width="9.5" height="19" fill="#00eb6c"/>
<rect x="15.5" y="16" width="19" height="19" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M25 16V35" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
<defs>
<radialGradient id="paint0_radial_4053_23602" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

`),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "AIS") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29320)" fill-opacity="${
          isActiveShip ? "1" : "0.4"
        }"/>
<path d="M24.8236 35.5H30.6473L30.6473 30.7413C30.6472 20.0159 24.8236 15.5 24.8236 15.5C24.8236 15.5 19 20.0159 19 30.7413L19 35.5H24.8236Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<defs>
<radialGradient id="paint0_radial_3218_29320" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

`),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "sanctioned") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29364)" fill-opacity="${
          isActiveShip ? "1" : "0.4"
        }"/>
<path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M30.6768 35.5L21.1768 21.5" stroke="white" stroke-width="1.5"/>
<defs>
<radialGradient id="paint0_radial_3218_29364" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>
`),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "light") {
    const { isActiveShip, isSanctionedShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    if (isSanctionedShip) {
      return {
        url:
          "data:image/svg+xml;base64," +
          btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29525)" fill-opacity="${
            isActiveShip ? "1" : "0.4"
          }"/>
<path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z"  fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M26.995 30.1988L26.7009 29.8976L27.0548 29.5128C27.4811 29.0416 27.6723 28.6509 27.7626 28.0685C27.8836 27.2365 27.6077 26.4076 27.0116 25.7972L26.7019 25.4799L27.003 25.1858L27.3042 24.8917L27.5905 25.1849C28.2414 25.8515 28.5997 26.7647 28.5844 27.7211C28.569 28.6775 28.2867 29.3846 27.6391 30.1112L27.2891 30.5L26.995 30.1988Z" fill="white"/>
<path d="M23.0079 30.1988L23.302 29.8976L22.9482 29.5128C22.5218 29.0416 22.3306 28.6509 22.2403 28.0685C22.1193 27.2365 22.3952 26.4076 22.9913 25.7972L23.3011 25.4799L22.9999 25.1858L22.6987 24.8917L22.4124 25.1849C21.7615 25.8515 21.4032 26.7647 21.4186 27.7211C21.4339 28.6775 21.7162 29.3846 22.3639 30.1112L22.7138 30.5L23.0079 30.1988Z" fill="white"/>
<circle cx="25" cy="27.75" r="2" fill="white"/>
<path d="M30.6768 35.5L21.1768 21.5" stroke="white" stroke-width="1.5"/>
<defs>
<radialGradient id="paint0_radial_3218_29525" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

    `),
        height: 200,
        width: 200,
        mask: false,
      };
    } else {
      return {
        url:
          "data:image/svg+xml;base64," +
          btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29324)" fill-opacity="${
      isActiveShip ? "1" : "0.4"
    }"/>
    <path d="M24.8236 35.5H30.6473L30.6473 30.7413C30.6472 20.0159 24.8236 15.5 24.8236 15.5C24.8236 15.5 19 20.0159 19 30.7413L19 35.5H24.8236Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M26.8183 30.1988L26.5242 29.8976L26.878 29.5128C27.3044 29.0416 27.4955 28.6509 27.5858 28.0685C27.7069 27.2365 27.4309 26.4076 26.8349 25.7972L26.5251 25.4799L26.8263 25.1858L27.1275 24.8917L27.4137 25.1849C28.0647 25.8515 28.4229 26.7647 28.4076 27.7211C28.3923 28.6775 28.11 29.3846 27.4623 30.1112L27.1124 30.5L26.8183 30.1988Z" fill="white"/>
    <path d="M22.8312 30.1988L23.1253 29.8976L22.7714 29.5128C22.3451 29.0416 22.1539 28.6509 22.0636 28.0685C21.9426 27.2365 22.2185 26.4076 22.8145 25.7972L23.1243 25.4799L22.8231 25.1858L22.522 24.8917L22.2357 25.1849C21.5847 25.8515 21.2265 26.7647 21.2418 27.7211C21.2572 28.6775 21.5394 29.3846 22.1871 30.1112L22.5371 30.5L22.8312 30.1988Z" fill="white"/>
    <circle cx="24.8232" cy="27.75" r="2" fill="white"/>
    <defs>
    <radialGradient id="paint0_radial_3218_29324" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
    <stop offset="0.27" stop-color="white" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </radialGradient>
    </defs>
    </svg>
    
    `),
        height: 200,
        width: 200,
        mask: false,
      };
    }
  }

  if (properties.type === "attributed") {
    const { isActiveShip, isSanctionedShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    if (isSanctionedShip) {
      return {
        url:
          "data:image/svg+xml;base64," +
          btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29534)" fill-opacity="${
            isActiveShip ? "1" : "0.4"
          }"/>
<path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M26.995 30.1988L26.7009 29.8976L27.0548 29.5128C27.4811 29.0416 27.6723 28.6509 27.7626 28.0685C27.8836 27.2365 27.6077 26.4076 27.0116 25.7972L26.7019 25.4799L27.003 25.1858L27.3042 24.8917L27.5905 25.1849C28.2414 25.8515 28.5997 26.7647 28.5844 27.7211C28.569 28.6775 28.2867 29.3846 27.6391 30.1112L27.2891 30.5L26.995 30.1988Z" fill="#111326"/>
<path d="M23.0079 30.1988L23.302 29.8976L22.9482 29.5128C22.5218 29.0416 22.3306 28.6509 22.2403 28.0685C22.1193 27.2365 22.3952 26.4076 22.9913 25.7972L23.3011 25.4799L22.9999 25.1858L22.6987 24.8917L22.4124 25.1849C21.7615 25.8515 21.4032 26.7647 21.4186 27.7211C21.4339 28.6775 21.7162 29.3846 22.3639 30.1112L22.7138 30.5L23.0079 30.1988Z" fill="#111326"/>
<circle cx="25" cy="27.75" r="2" fill="#111326"/>
<path d="M30.6768 35.5L21.1768 21.5" stroke="white" stroke-width="1.5"/>
<defs>
<radialGradient id="paint0_radial_3218_29534" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

  
      `),
        height: 200,
        width: 200,
        mask: false,
      };
    } else {
      return {
        url:
          "data:image/svg+xml;base64," +
          btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29332)" fill-opacity="${
            isActiveShip ? "1" : "0.4"
          }"/>
<path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M26.995 30.1988L26.7009 29.8976L27.0548 29.5128C27.4811 29.0416 27.6723 28.6509 27.7626 28.0685C27.8836 27.2365 27.6077 26.4076 27.0116 25.7972L26.7019 25.4799L27.003 25.1858L27.3042 24.8917L27.5905 25.1849C28.2414 25.8515 28.5997 26.7647 28.5844 27.7211C28.569 28.6775 28.2867 29.3846 27.6391 30.1112L27.2891 30.5L26.995 30.1988Z" fill="#111326"/>
<path d="M23.0079 30.1988L23.302 29.8976L22.9482 29.5128C22.5218 29.0416 22.3306 28.6509 22.2403 28.0685C22.1193 27.2365 22.3952 26.4076 22.9913 25.7972L23.3011 25.4799L22.9999 25.1858L22.6987 24.8917L22.4124 25.1849C21.7615 25.8515 21.4032 26.7647 21.4186 27.7211C21.4339 28.6775 21.7162 29.3846 22.3639 30.1112L22.7138 30.5L23.0079 30.1988Z" fill="#111326"/>
<circle cx="25" cy="27.75" r="2" fill="#111326"/>
<defs>
<radialGradient id="paint0_radial_3218_29332" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>

      
      `),
        height: 200,
        width: 200,
        mask: false,
      };
    }
  }

  if (properties.type === "unattributed") {
    const { isActiveShip } = properties;
    let color = "#FA5849";
    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="200" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29340)" fill-opacity="${
      isActiveShip ? "1" : "0.4"
    }"/>
    <path d="M25.0004 35.5H30.824L30.824 30.7413C30.824 20.0159 25.0004 15.5 25.0004 15.5C25.0004 15.5 19.1768 20.0159 19.1768 30.7413L19.1768 35.5H25.0004Z" fill="${color}" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
    <circle cx="24.9766" cy="27" r="2.5" stroke="white" stroke-width="1.5"/>
    <path d="M27.3174 31.9748C27.5446 32.3212 28.0095 32.4179 28.3559 32.1908C28.7023 31.9637 28.799 31.4987 28.5719 31.1523L27.3174 31.9748ZM25.6726 29.4659L27.3174 31.9748L28.5719 31.1523L26.927 28.6435L25.6726 29.4659Z" fill="white"/>
    <defs>
    <radialGradient id="paint0_radial_3218_29340" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
    <stop offset="0.27" stop-color="white" stop-opacity="0"/>
    <stop offset="1" stop-color="white"/>
    </radialGradient>
    </defs>
    </svg>

  `),
      height: 200,
      width: 200,
      mask: false,
    };
  }

  if (properties.type === "spoofing") {
    const { isActiveShip, pathColor } = properties;
    const color =
      paths &&
      properties?.selectedShipKey &&
      paths[properties.selectedShipKey]?.color
        ? paths[properties.selectedShipKey].color
        : pathColor || "white";

    return {
      url:
        "data:image/svg+xml;base64," +
        btoa(`<svg width="205" height="200" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.5" r="25" fill="url(#paint0_radial_3218_29348)" fill-opacity="${
          isActiveShip ? "1" : "0.4"
        }" />
<rect x="25.0002" y="15.5" width="12.7282" height="12.7282" transform="rotate(45 25.0002 15.5)" fill="${color}" stroke="white" stroke-width="1.5"/>
<path d="M25.0002 25.25V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="25.0002" cy="28" r="1" fill="white"/>
<defs>
<radialGradient id="paint0_radial_3218_29348" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25 25.5) rotate(90) scale(25)">
<stop offset="0.27" stop-color="white" stop-opacity="0"/>
<stop offset="1" stop-color="white"/>
</radialGradient>
</defs>
</svg>
 `),
      height: 200,
      width: 205,
      mask: false,
    };
  }
};

const getSelectedShipHeading = (properties: any) => {
  if (properties.type === "light" || properties.type === "attributed") {
    return properties?.detectionData?.heading
      ? properties?.detectionData?.heading
      : properties.heading;
  }

  if (
    properties.type === "unattributed" ||
    properties.type === "sanctioned" ||
    properties.type === "AIS"
  ) {
    return properties.heading;
  }

  if (properties.type === "spoofing" || properties.type === "AISSTS") {
    return 0;
  }

  if (properties.type === "opticalSTS") {
    return properties.frontend_rotation;
  }
};
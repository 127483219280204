import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  onSnapshot,
  DocumentData,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { Snackbar, SnackbarContent } from "@mui/material";
import { RootState } from "./redux/store";
import { setUser } from "./pages/Theia/Theia.store";
import Spinner from "./components/Spinner/Spinner";
import { changeLoaderState } from "./redux/globalState.store";
import Routes from "./routes";
import { SnackbarProvider } from "./context/SnackbarContext";
import SnackBar from "./components/SnackBar";
import { setIsUserLoggedIn } from "./common/Common.store";

// export const REACT_APP_ENV = process.env.REACT_APP_ENV;
// console.log(` process.env: ${ process.env}`)
//
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

export const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const frontendAPIURL =
  REACT_APP_ENV === "dev"
    ? "https://frontend-api-j75nm34yfa-uc.a.run.app/"
    : "https://frontend-api-y5rlib2gfa-uc.a.run.app/";

console.log(`REACT_APP_ENV: ${REACT_APP_ENV}`);

// REACT_APP_BASE_URL=https://theia-nodejs-j75nm34yfa-uc.a.run.app/ REACT_APP_MAPBOX_TOKEN=notoken
// REACT_APP_APPID=1:861000466587:web:c20ea509df245c3caa063e REACT_APP_MESSAGINGSENDERID=861000466587
// REACT_APP_STORAGEBUCKET=theia-dev-376415.appspot.com REACT_APP_APIKEY=AIzaSyA895Qfqtls2Ckww7JE35QtQG5bcj9O5S8
// REACT_APP_AUTHDOMAIN=theia-dev-376415.firebaseapp.com REACT_APP_PROJECTID=theia-dev-376415
// export const firebaseConfig = REACT_APP_ENV === 'dev' ? {
//   apiKey: "AIzaSyA895Qfqtls2Ckww7JE35QtQG5bcj9O5S8",
//   authDomain: "theia-dev-376415.firebaseapp.com",
//   projectId: "theia-dev-376415",
//   storageBucket: "theia-dev-376415.appspot.com",
//   messagingSenderId: "861000466587",
//   appId: "1:861000466587:web:c20ea509df245c3caa063e",
//   measurementId: "G-20E2YQG6N5"
// } : {
//   apiKey: "AIzaSyBQ04Z_dL7AvznjFInivS5WEVbYOD00lPk",
//   authDomain: "theia-prod-401716.firebaseapp.com",
//   projectId: "theia-prod-401716",
//   storageBucket: "theia-prod-401716.appspot.com",
//   messagingSenderId: "842455938625",
//   appId: "1:842455938625:web:119144c578d563fae338c0",
//   measurementId: "G-VY946CN9DB"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyA4nBRwiJsNmfAER0MVw73TjKN0glTAObc",
//   authDomain: "frontend-test-firebase.firebaseapp.com",
//   projectId: "frontend-test-firebase",
//   storageBucket: "frontend-test-firebase.appspot.com",
//   messagingSenderId: "114343960579",
//   appId: "1:114343960579:web:9c09d0f1c6d169e6fd6e36",
// };

function isEmpty(obj: any) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const FirebaseFunctions = getFunctions(app);

export default function App() {
  const { email } = useSelector((state: RootState) => state.auth);
  const { showLoader } = useSelector(
    (state: RootState) => state.globalTheiaState
  );
  const [userData, setUserData] = useState<DocumentData | null>(null);

  const dispatch = useDispatch();
  let auth = getAuth();
  let isloggedIn = auth.currentUser as any;
  const isMFAVerified = localStorage.getItem("isMFAVerified");

  useEffect(() => {
    setInterval(() => {
      if (isloggedIn) {
        refreshToken();
      }
    }, 5000);

    const refreshToken = async () => {
      const token = await isloggedIn.getIdToken();
      localStorage.setItem("token", token);
    };
  }, [isloggedIn]);

  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  // userData ~ user profile (has email and role)
  const [snackBarMsg, setSnackBarMsg] = useState("");

  useEffect(() => {
    dispatch(changeLoaderState(isTokenLoading));
  }, [isTokenLoading]);

  useEffect(() => {
    //extra code to check build
    // This function from  firebase is called when our authentication state changes(eg: logged in/ logged out)
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid, email } = user;

        setIsTokenLoading(true);
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data() || {};
        setUserData({ ...userData, uid });
        const token = await user.getIdToken();
        if (uid && (!userData || isEmpty(userData))) {
          // It means this is a new user, so it doesn't have any profile data yet
          // So we create a profile for this user on firebase
          let firstName = "";
          let lastName = "";

          await setDoc(doc(db, "users", uid), {
            email,
            role: "new_user",
            firstName,
            lastName,
            isNewUser: true,
          });
        }

        // We are checking the user's role
        // As you need to be a customer, co-worker, admin, or super-user to view the app
        const role = userData ? userData.role : "";
        if (
          role === "super_user" ||
          role === "admin" ||
          role === "co-worker" ||
          role === "theia_customer" ||
          (role === "temporary_theia_user" &&
            userData.expiry_date.seconds > Date.now() / 1000)
        ) {
          localStorage.setItem("token", token);
          dispatch(setUser({ ...userData, uid }));
          dispatch(setIsUserLoggedIn(true));
          setIsTokenLoading(false);
        } else {
          if (token) {
            signOut(auth).then(() => {
              setSnackBarMsg("Email info@synmax.com For Access");
              setIsSnackBarOpen(true);
            });
          } else {
            setSnackBarMsg("Email info@synmax.com For Access");
            setIsSnackBarOpen(true);
          }
        }
        setIsTokenLoading(false);
      } else {
        setIsTokenLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (
      !userData ||
      !userData.uid ||
      !userData?.role ||
      userData?.role === "new_user" ||
      isMFAVerified === "false"
    )
      return;
    const userCollectionRef = collection(db, "users");
    const userDocRef = doc(userCollectionRef, userData.uid);
    const userUnsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const userDocData = snapshot.data();
      if (!userDocData) return;
      if (!userDocData?.secret) {
        signOut(auth)
          .then(() => {
            dispatch(setIsUserLoggedIn(false));
            window.location.href = "/login";
          })
          .catch((error) => {
            console.log(error, "err");
          });
      }
    });
    return () => {
      userUnsubscribe();
    };
  }, [userData]);

  return (
    <div className="App">
      {showLoader && <Spinner />}
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message={snackBarMsg}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        className="askKevin"
      >
        <SnackbarContent
          message={<span id="client-snackbar">{snackBarMsg}</span>}
        />
      </Snackbar>
      <SnackbarProvider>
        <SnackBar />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const homePanelSlice = createSlice({
  name: "homePanel",
  initialState: {
    isRecent: false,
    searchText: "",
  },
  reducers: {
    setIsRecent: (state, action) => {
      state.isRecent = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

export const { setIsRecent, setSearchText } = homePanelSlice.actions;

export const homePanelReducer = homePanelSlice.reducer;

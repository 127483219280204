import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import CustomButton from "../CustomButton/CustomButton";
import { Box } from "@mui/material";
import styles from "./SelectDateRangePicker.module.scss";

const SelectDateRangePicker = ({
  startDateTitle,
  endDateTitle,
  dateRange,
  setDateRange,
  buttonName,
  handleClick,
  activeVariant,
  loading,
}: any) => {
  return (
    <Box className={styles.selectDatePicker}>
      <CustomDatePicker
        title={startDateTitle}
        value={dateRange.startDate}
        setValue={(date: any) =>
          setDateRange({ ...dateRange, startDate: date })
        }
        maxDate={dateRange.endDate}
      />
      <CustomDatePicker
        title={endDateTitle}
        value={dateRange.endDate}
        setValue={(date: any) => setDateRange({ ...dateRange, endDate: date })}
        minDate={dateRange.startDate}
      />
      <Box width="100%">
        <CustomButton
          buttonName={buttonName}
          onClick={handleClick}
          variant={activeVariant ? "outlined" : "contained"}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default SelectDateRangePicker;

import React from "react";

const WorldNewsIcon = ({size = 20}) => (
  <svg
    width={size}
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5359 3.46444C15.4885 5.41707 15.4885 8.58289 13.5359 10.5355M6.46479 10.5355C4.51217 8.58286 4.51217 5.41704 6.46479 3.46442M4.10777 12.8925C0.8534 9.63817 0.8534 4.36179 4.10777 1.10742M15.8929 1.10746C19.1473 4.36183 19.1473 9.6382 15.8929 12.8926M11.667 6.99998C11.667 7.92045 10.9208 8.66665 10.0003 8.66665C9.07985 8.66665 8.33366 7.92045 8.33366 6.99998C8.33366 6.0795 9.07985 5.33331 10.0003 5.33331C10.9208 5.33331 11.667 6.0795 11.667 6.99998Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default WorldNewsIcon;

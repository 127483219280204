import { Box, styled } from "@mui/material";

const HoveredCoordinates = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  background: "#24263c",
  backdropFilter: "blur(25px)",
  zIndex: 90,
  left: 140,
}));

const Coordinate = styled(Box)(() => ({
  minWidth: "74px",
}));

const roundToSixthDecimal = (num: number): number => {
  return Math.round(num * 1_000_000) / 1_000_000;
};

const UserHoveredCoordinates: React.FC<{
  currentUserHoverCoordinates: [number, number];
}> = ({ currentUserHoverCoordinates }) => (
  <HoveredCoordinates>
    <Coordinate>
      {currentUserHoverCoordinates[1] > 0 ? "+" : ""}
      {roundToSixthDecimal(currentUserHoverCoordinates[1])}
    </Coordinate>
    <Coordinate>
      {currentUserHoverCoordinates[0] > 0 ? "+" : ""}
      {roundToSixthDecimal(currentUserHoverCoordinates[0])}
    </Coordinate>
  </HoveredCoordinates>
);

export default UserHoveredCoordinates;

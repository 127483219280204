import { Box } from "@mui/material";
import Loader from "../../../../../components/ShipTools/Loader/Loader";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { DateRange, EstimatedLocationOpenType } from "../EstimatedLocation";
import { MapContext } from "../../../MainMap";
import axios from "axios";
import dayjs from "dayjs";
import { frontendAPIURL } from "../../../../../App";

const EstimatedLocationLoader: React.FC<{
  setOpen: Dispatch<SetStateAction<EstimatedLocationOpenType>>;
  open: EstimatedLocationOpenType;
  dateRange: DateRange;
  setDatesWithNoLoactionData: Dispatch<SetStateAction<string[]>>;
  setAllDatesAreMissingLoactions: Dispatch<SetStateAction<boolean>>;
}> = ({
  setOpen,
  open,
  dateRange,
  setDatesWithNoLoactionData,
  setAllDatesAreMissingLoactions,
}) => {
  const {
    shipDetailTabValue,
    selectedShip,
    selectedEvent,
    setSelectedShipEstimatedLocationGEOJSON,
    setSelectedShipEstimatedLocationLayer,
    bunkeringValue
  } = useContext(MapContext);
  const [loadingPercentage, setLoadingPercantege] = useState(33);
  const [estimatedLoactionController, setEstimatedShipController] =
    useState<AbortController | null>(null);

    useEffect(() => {
      console.log(selectedEvent, ':selectedEvent')
    },[selectedEvent])

  const getDateRanges = (): { startDate: string; endDate: string }[] => {
    const dateRanges: any[] = [];
    let currentDate = dayjs(dateRange.startDate).startOf("day");

    while (
      currentDate.isBefore(dayjs(dateRange.endDate).endOf("day")) ||
      currentDate.isSame(dayjs(dateRange.endDate).endOf("day"))
    ) {
      const currentDateRange = {
        startDate: currentDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: currentDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      };

      dateRanges.push(currentDateRange);

      // Move to the next day
      currentDate = currentDate.add(1, "day");
    }

    return dateRanges;
  };

  useEffect(() => {
    const abortController = new AbortController();
    setEstimatedShipController(abortController);
    setLoadingPercantege(0);

    // Mock loading percentage increase
    const loadinPregressInterval = setInterval(() => {
      if (loadingPercentage < 95) {
        setLoadingPercantege(
          (previousPercantage) =>
            previousPercantage + Math.floor(Math.random() * 5)
        );
      }
    }, 2500);

    const validateLoactionRespones = (locationData: any): void => {
      const missingLoactions = locationData.filter(
        (locationData: any) => locationData.data === null
      );
      setDatesWithNoLoactionData(missingLoactions);
      if (locationData.length === missingLoactions.length) {
        setAllDatesAreMissingLoactions(true);
      }
    };

    const getEstimatedLoaction = async () => {
      try {
        const token = localStorage.getItem("token");
        console.log(selectedEvent, 'event :selectedShip', selectedShip)
        console.log(shipDetailTabValue, ':shipDetailTabValue')
        console.log(bunkeringValue, ':bunkeringValue')

        let shipToLoadLocations = selectedShip;

        if(selectedShip[shipDetailTabValue] === undefined) {
          shipToLoadLocations = selectedEvent[shipDetailTabValue]
        } else {
          if(selectedShip[shipDetailTabValue].mmsi === undefined) {
            // Bunkering
            // const bunkeringDataObjKeys = Object.keys(selectedShip[shipDetailTabValue])

            if(bunkeringValue === '1') {
              if(selectedShip[shipDetailTabValue].type === "AISSTS") {
                shipToLoadLocations = selectedShip[shipDetailTabValue][selectedShip[shipDetailTabValue].synmax_ship_id_1]
              } else {
                shipToLoadLocations = selectedShip[shipDetailTabValue][selectedShip[shipDetailTabValue].ship1_attribution]
              }
            }

            if(bunkeringValue === '2') {
              if(selectedShip[shipDetailTabValue].type === "AISSTS") {
                shipToLoadLocations = selectedShip[shipDetailTabValue][selectedShip[shipDetailTabValue].synmax_ship_id_2]
              } else {
                shipToLoadLocations = selectedShip[shipDetailTabValue][selectedShip[shipDetailTabValue].ship2_attribution]
              }
            }
          } else {
            shipToLoadLocations = selectedShip[shipDetailTabValue]
          }
        }
        console.log(shipToLoadLocations, ':shipToLoadLocations')
       
        const loactionRequests = getDateRanges().map((range) => {
          return axios.post(
            `${frontendAPIURL}/ship_location`,
            {
              mmsi: shipToLoadLocations.mmsi,
              start: range.startDate,
              end: range.endDate,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
              signal: abortController.signal,
            }
          );
        });
        const res = await Promise.all(loactionRequests);

        const formatedFeatures = res.map((feature: any) => {
          return {
            startDate: feature.data.start_datetime,
            data: feature.data.response.geojson,
          };
        });

        validateLoactionRespones(formatedFeatures);
        setSelectedShipEstimatedLocationGEOJSON(formatedFeatures);
        setSelectedShipEstimatedLocationLayer(formatedFeatures[0].data);
        clearInterval(loadinPregressInterval);
        setLoadingPercantege(100);
        setOpen("openSlider");
      } catch (error) {
        console.log("getEstimatedLoactio error:", error);
      }
    };
    if (open === "openLoder" && selectedShip) {
      getEstimatedLoaction();
    }
    return () => {
      clearInterval(loadinPregressInterval);
      if (estimatedLoactionController !== null) {
        estimatedLoactionController.abort();
      }
    };
  }, [open, selectedShip, selectedEvent]);

  return (
    <Box>
      <Loader
        handleCancelButtonClick={() => {
          setOpen("selectDate");
          if (estimatedLoactionController !== null) {
            estimatedLoactionController.abort();
          }
        }}
        loadingMessage="Loading estimated location..."
        loaderProgress={loadingPercentage}
      />
    </Box>
  );
};

export default EstimatedLocationLoader;

import { useEffect, useState } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import styles from "./Loader.module.scss";

const Loader = ({
  handleCancelButtonClick,
  loadingMessage,
  loaderProgress,
}: any) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(loaderProgress);
  }, [loaderProgress]);

  return (
    <Box className={styles.futurePathPredictionLoader}>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{
          root: styles.linearProgressBar,
          bar: styles.linearProgressBarFill,
        }}
      />
      <Box className={styles.loadingContent}>
        <p className={styles.content}>
          {loadingMessage && `${progress}% ${loadingMessage}`}
        </p>
        <Button
          type="button"
          variant="text"
          onClick={handleCancelButtonClick}
          className={styles.cancelBtn}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default Loader;

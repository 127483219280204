import React, { useState } from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import styles from "./TextInput.module.scss";

interface TextInputProps {
  id: string;
  type?: any;
  name: string;
  value?: any;
  placeholder?: string;
  className?: string;
  onChange?: (e: any) => void;
  props?: any;
  passwordField?: boolean;
  required?: boolean;
}

const TextInput = ({
  id,
  type = "text",
  name,
  value,
  placeholder,
  onChange,
  className,
  passwordField,
  required = false,
  ...props
}: TextInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={passwordField ? styles.passwordInput : ""}>
      <input
        className={`${styles.customInput} ${className}`}
        id={id}
        type={passwordField ? (showPassword ? "text" : "password") : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        {...props}
      />
      {passwordField && (
        <div className={styles.eyeIcon} onClick={togglePasswordVisibility}>
          {showPassword ? (
            <VisibilityOffOutlinedIcon />
          ) : (
            <VisibilityOutlinedIcon />
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;

import { createSlice } from "@reduxjs/toolkit";

export const globalStateSlice = createSlice({
  name: "globalState",
  initialState: {
    showLoader: false,
    mapIntervalId: null
  },
  reducers: {
    changeLoaderState: (state, action) => {
      state.showLoader = action.payload;
    },
    setMapIntervalID: (state, action) => {
      state.mapIntervalId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLoaderState, setMapIntervalID } = globalStateSlice.actions;

export const globalStateReducer = globalStateSlice.reducer;

import { useContext, useEffect, useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import DateSlider from "../../../../../components/DateSlider/DateSlider";
import { valueLabelFormat } from "../../../../../common/Common";
import { Box, styled } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import styles from "./EstimatedLocationSlider.module.scss";
import { MapContext } from "../../../MainMap";
import ErrorIcon from "../../../../../assets/icons/ErrorIcon";

const ErroMessageContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "6px",
  border: "1px #FA5849",
  background: "rgba(250, 88, 73, 0.50)",
  padding: "12px",
}));

const ErroMessageText = styled(Box)(() => ({
  marginLeft: "6px",
}));

export const EstimatedLoactionErrorMessage: React.FC<{ message: string }> = ({
  message,
}) => {
  return (
    <ErroMessageContainer>
      <ErrorIcon size={40} />
      <ErroMessageText>{message}</ErroMessageText>
    </ErroMessageContainer>
  );
};

const ErrorMessage: React.FC<{
  datesWithNoLocationData: any[];
  allDatesAreMissingLoactions: boolean;
}> = ({ datesWithNoLocationData, allDatesAreMissingLoactions }) => {
  if (allDatesAreMissingLoactions) {
    return (
      <EstimatedLoactionErrorMessage message="No data is available during the selected timeframe" />
    );
  }
  let datesWithNoLactionList = "";
  datesWithNoLactionList += moment(datesWithNoLocationData[0].startDate).format(
    "MM/DD/YYYY"
  );
  if (datesWithNoLocationData.length > 1) {
    {
      datesWithNoLocationData.forEach((date: any, index) => {
        if (index === datesWithNoLocationData.length - 1) {
          datesWithNoLactionList += `, and ${moment(date.startDate).format(
            "MM/DD/YYYY"
          )}`;
        } else if (index > 0) {
          datesWithNoLactionList += `, ${moment(date.startDate).format(
            "MM/DD/YYYY"
          )}`;
        }
      });
    }
  }
  return (
    <EstimatedLoactionErrorMessage
      message={`No data is available on: ${datesWithNoLactionList}. No data will be displayed for these dates`}
    />
  );
};

const EstimatedLocationSlider: React.FC<{
  dateRange: any;
  datesWithNoLocationData: string[];
  allDatesAreMissingLoactions: boolean;
}> = ({ dateRange, datesWithNoLocationData, allDatesAreMissingLoactions }) => {
  const [value, setValue] = useState<number>(0);
  const [estimatedDateRange, setEstimatedDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  useEffect(() => {
    setEstimatedDateRange(dateRange);
  }, []);

  const { handleEstmatedShipLayerSelect } = useContext(MapContext);

  const singleSecond = 1000;
  const oneHundredFiftyMiuntes = 9000;

  const handleChange = (event: any, newValue: number | number[]): void => {
    if (typeof newValue === "number") {
      const formated = moment(newValue * singleSecond).format("MM/DD/YYYY");
      handleEstmatedShipLayerSelect(formated);
      setValue(newValue);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {estimatedDateRange !== null &&
        !dayjs(estimatedDateRange.startDate).isSame(
          estimatedDateRange.endDate
        ) && (
          <Box
            display="flex"
            flexDirection="column"
            className={styles.estimatedLocations}
          >
            {datesWithNoLocationData.length > 0 ? (
              <ErrorMessage
                datesWithNoLocationData={datesWithNoLocationData}
                allDatesAreMissingLoactions={allDatesAreMissingLoactions}
              />
            ) : null}
            <span className={styles.estimatedLocationsHead}>
              Drag slider to view ship's estimated locations
            </span>
            <Box display="flex" alignItems="center" gap={1}>
              <span
                className={styles.estimatedIcon}
                onClick={(e) => {
                  handleChange(e, value - oneHundredFiftyMiuntes);
                }}
              >
                <Remove />
              </span>
              <DateSlider
                value={value}
                minDate={estimatedDateRange.startDate.valueOf() / singleSecond}
                maxDate={estimatedDateRange.endDate.valueOf() / singleSecond}
                step={900}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={handleChange}
                className={styles.sliderMain}
                classes={{
                  rail: styles.sliderRail,
                  track: styles.sliderTrack,
                  thumb: styles.sliderThumb,
                }}
              />
              <span
                className={styles.estimatedIcon}
                onClick={(e) => {
                  handleChange(e, value + oneHundredFiftyMiuntes);
                }}
              >
                <Add />
              </span>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <span className={styles.estimatedDate}>
                {moment(estimatedDateRange.startDate.valueOf()).format(
                  "MM/DD/YYYY"
                )}
              </span>
              <span className={styles.estimatedDate}>
                {moment(estimatedDateRange.endDate.valueOf()).format(
                  "MM/DD/YYYY"
                )}
              </span>
            </Box>
          </Box>
        )}
    </Box>
  );
};

export default EstimatedLocationSlider;

import React, { Dispatch, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import TextInput from "../../../components/TextInput/TextInput";
import Label from "../../../components/Label/Label";
import styles from "../Auth.module.scss";
import { InfoOutlined } from "@mui/icons-material";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../App";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string | null>(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const passwordRequirements = [
    { label: "Must be at least 8 characters" },
    { label: "Must contain one uppercase letter" },
    { label: "Must have at least one number" },
    { label: "Must have at least one special character" },
  ];

  const createNewUser = () => {
    setIsLoading(true);
    setErrMessage(null);

    if (password !== confirmPassword) {
      setIsLoading(false);
      setErrMessage("The passwords you provided do not match.");
      //setSnackbarMessage("Password and Confirm Password are not same");
      //setSnackbarSeverity("error");
      //setIsSnackBarOpen(true);
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const { uid, email } = user;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists() && uid && docRef) {
            try {
              await setDoc(docRef, {
                email,
                role: "new_user",
                firstName,
                lastName,
                isNewUser: true,
              });
            } catch (error: any) {
              console.log(error);
            }
          }
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("lastName", lastName);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          const errorMessage = error.message;
          setErrMessage(errorMessage);
        });
    }
  };

  const clearState = () => {
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    setEmail("");
  };

  return (
    <Box className={styles.loginDetail}>
      <h5 className={styles.loginDetailHead}>Create An Account</h5>
      <form
        className={styles.detailInputWrapper}
        onSubmit={(e) => {
          e.preventDefault();
          createNewUser();
        }}
      >
        <Box className={styles.inputWrapperMain}>
          <Box className={styles.inputWrapper}>
            <Label label="First Name" />
            <TextInput
              id="firstName"
              type="text"
              name="name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Enter first name"
            />
          </Box>

          <Box className={styles.inputWrapper}>
            <Label label="Last Name" />
            <TextInput
              id="lastName"
              type="text"
              name="name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Enter last name"
            />
          </Box>
        </Box>

        <Box className={styles.inputWrapper}>
          <Label label="Email" />
          <TextInput
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            required={true}
          />
        </Box>

        <Box className={styles.inputWrapper}>
          <Label label="Password" />
          <TextInput
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Create password"
            passwordField={true}
            required={true}
          />
          {/* {passwordRequirements.map((requirement) => (
            <Typography
              sx={{
                fontSize: "10px",
                color: "white",
                fontWeight:"normal",
                margin: "-2px"
              }}
            >
              {requirement.label}
            </Typography>
          ))} */}
        </Box>
        <Box className={styles.inputWrapper}>
          <Label label="Confirm Password" />
          <TextInput
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
            passwordField={true}
            required={true}
          />
        </Box>
        <LoadingButton
          variant="outlined"
          className={styles.buttonWrap}
          type="submit"
          loading={isLoading}
        >
          Sign Up
        </LoadingButton>
        {errMessage && (
          <p className={styles.errorMessage}>
            <InfoOutlined />
            {errMessage}
          </p>
        )}
        <p className={styles.accountWrapper}>
          Already have an account?
          <Link
            className={styles.inputLink}
            underline="none"
            onClick={(e) => {
              navigate("/login");
              clearState();
              setErrMessage(null);
            }}
          >
            Log In
          </Link>
        </p>
      </form>
    </Box>
  );
};

export default SignUp;

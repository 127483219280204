import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    email: "paras",
  },
  reducers: {
    signIn: (state, action) => {
      state.email = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn } = authSlice.actions;

export const authReducer = authSlice.reducer;

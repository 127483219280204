import React, { FC } from "react";
import { AttributionInterface } from "../Attribution";
import { Box, Button, Modal, TextField } from "@mui/material";
import styles from "./ModalAttribution.module.scss";

interface ModalAttribution {
  openManualAttributionModal: boolean;
  handleSave: (attribution: AttributionInterface) => void;
  handleClose: () => void;
  setManualAttribution: React.Dispatch<React.SetStateAction<AttributionInterface>>;
  ManualAttribution: AttributionInterface;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalAttribution: FC<ModalAttribution> = ({
  openManualAttributionModal,
  handleClose,
  handleSave,
  ManualAttribution,
  setManualAttribution,
}) => {
  const handleChange = (name: string, value: string) => {
    setManualAttribution((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <Modal
      keepMounted
      open={openManualAttributionModal}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className={styles.attrubutionModalWrap}
    >
      <Box sx={style} className={styles.attrubutionModal}>
        <h3 className={styles.title}>Manual Attribution</h3>
        <Box className={styles.attributionForm}>
          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <TextField
              autoFocus
              required
              margin="dense"
              id="length"
              name="length"
              label="Length"
              type="number"
              fullWidth
              variant="standard"
              value={
                ManualAttribution.length ? ManualAttribution.length : undefined
              }
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              InputLabelProps={{
                classes: {
                  root: styles.modalLabel,
                  focused: styles.modalLabelFocused,
                },
              }}
              InputProps={{
                inputProps: { min: 0 },
                classes: {
                  root: styles.modalInput,
                  input: styles.test,
                  focused: styles.modalInputFocused,
                },
              }}
              className={styles.modalTextFild}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <TextField
              autoFocus
              required
              margin="dense"
              id="heading"
              name="heading"
              label="Heading"
              type="number"
              fullWidth
              value={
                ManualAttribution.heading
                  ? ManualAttribution.heading
                  : undefined
              }
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="standard"
              InputLabelProps={{
                classes: {
                  root: styles.modalLabel,
                  focused: styles.modalLabelFocused,
                },
              }}
              InputProps={{
                inputProps: { min: 0 },
                classes: {
                  root: styles.modalInput,
                  input: styles.test,
                  focused: styles.modalInputFocused,
                },
              }}
              className={styles.modalTextFild}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <TextField
              autoFocus
              required
              margin="dense"
              id="type"
              name="type"
              label="Type"
              type="text"
              fullWidth
              variant="standard"
              value={ManualAttribution.type ? ManualAttribution.type : ""}
              InputLabelProps={{
                classes: {
                  root: styles.modalLabel,
                  focused: styles.modalLabelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: styles.modalInput,
                  input: styles.test,
                  focused: styles.modalInputFocused,
                },
              }}
              className={styles.modalTextFild}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <TextField
              autoFocus
              required
              margin="dense"
              id="moving"
              name="moving"
              label="Moving"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{
                classes: {
                  root: styles.modalLabel,
                  focused: styles.modalLabelFocused,
                },
              }}
              value={ManualAttribution.moving ? ManualAttribution.moving : ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              InputProps={{
                classes: {
                  root: styles.modalInput,
                  input: styles.test,
                  focused: styles.modalInputFocused,
                },
              }}
              className={styles.modalTextFild}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={styles.attributionInputField}
          >
            <TextField
              autoFocus
              required
              margin="dense"
              id="id"
              name="id"
              label="Id"
              type="text"
              fullWidth
              variant="standard"
              InputLabelProps={{
                classes: {
                  root: styles.modalLabel,
                  focused: styles.modalLabelFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: styles.modalInput,
                  input: styles.test,
                  focused: styles.modalInputFocused,
                },
              }}
              value={ManualAttribution.id ? ManualAttribution.id : ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              className={styles.modalTextFild}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          className={styles.btnWrapper}
        >
          <Button
            variant="outlined"
            onClick={() => {
              setManualAttribution({});
              handleClose();
            }}
            className={styles.linkBtn}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSave(ManualAttribution)}
            className={styles.saveBtn}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalAttribution;

import { useState } from "react";
import { Box, Button } from "@mui/material";
import Header from "../../../../components/ShipTools/Header/Header";
import styles from "./DropPin.module.scss";
import TextInput from "../../../../components/TextInput/TextInput";
import Label from "../../../../components/Label/Label";
import { useSnackbar } from "../../../../context/SnackbarContext";

interface DropPinComponentProps {
  setshowDropPinDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMapLatLng: React.Dispatch<React.SetStateAction<any>>;
  setDroppedPins: React.Dispatch<React.SetStateAction<any>>;
  mapRef: any;
  setMapViewState: any;
}
const DropPin = ({
  setshowDropPinDialog,
  setMapLatLng,
  setDroppedPins,
  mapRef,
  setMapViewState,
}: DropPinComponentProps) => {
  const [dropPinMapPoint, setDropPinMapPoint] = useState<{
    lat?: number;
    lng?: number;
    title?: string;
  }>();
  const { setSnackbarMessage, setSnackbarSeverity, setIsSnackBarOpen } =
    useSnackbar();
  const handleChange = (name: string, value: string | number) => {
    setDropPinMapPoint({
      ...dropPinMapPoint,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    const lat = Number(dropPinMapPoint?.lat) || 0;
    const lng = Number(dropPinMapPoint?.lng) || 0;
    const title = dropPinMapPoint?.title || "";

    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      setSnackbarMessage(
        "Invalid latitude or longitude values. Latitude must be between -90 and 90, and longitude must be between -180 and 180."
      );
      setSnackbarSeverity("error");
      setIsSnackBarOpen(true);
      return;
    }
    mapRef?.current?.flyTo && mapRef?.current?.flyTo({ center: [lng, lat] });
    setshowDropPinDialog(false);
    setMapLatLng({ lat, lng });
    setMapViewState((prev: any) => ({
      ...prev,
      longitude: lng,
      latitude: lat,
    }));
    setDroppedPins((prev: any) => [...prev, { lat, lng, title }]);
  };
  return (
    <Box className={styles.dropPinWrapper}>
      <Header
        headerTitle="Drop Pin At:"
        showMinimize={false}
        handleClose={() => {
          setshowDropPinDialog(false);
        }}
      />
      <Box
        display="grid"
        gridTemplateColumns="repeat(1,1fr)"
        className={styles.EditPathMain}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={styles.editMainWrapper}
        >
          <Label label="Name" className={styles.editMainLabel} />
          <TextInput
            id="title"
            name="title"
            placeholder="Enter name"
            value={dropPinMapPoint?.title}
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            className={styles.editMainInput}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={styles.editMainWrapper}
        >
          <Label label="Latitude" className={styles.editMainLabel} />
          <TextInput
            id="latitude"
            name="lat"
            type="number"
            placeholder="0"
            value={dropPinMapPoint?.lat}
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            className={styles.editMainInput}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={styles.editMainWrapper}
        >
          <Label label="Longtitude" className={styles.editMainLabel} />
          <TextInput
            id="longtitude"
            name="lng"
            type="number"
            placeholder="0"
            value={dropPinMapPoint?.lng}
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            className={styles.editMainInput}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        className={styles.dropPinButton}
      >
        <Button variant="contained" onClick={() => handleSubmit()}>
          DROP PIN
        </Button>
      </Box>
    </Box>
  );
};

export default DropPin;

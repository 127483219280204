import React from "react";
import styles from "./Label.module.scss";

interface LabelProps {
  label: string;
  className?: string;
}

const Label = ({ label, className }: LabelProps) => {
  return (
    <label className={`${styles.customLabel} ${className}`}>{label}</label>
  );
};

export default Label;

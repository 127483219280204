import { api } from "../../services/api";

export const addFileToStorage = async (data: any) => {
  const response = await api.post("/save-file", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

import { useState } from "react";
import SelectDateRangePicker from "../../../../components/SelectDateRangePicker/SelectDateRangePicker";
import { Box } from "@mui/material";
import styles from "./ExtendedPath.module.scss";
import dayjs from "dayjs";

const ExtendedPath = ({
  handleViewExtendedPath,
  loading,
}: {
  handleViewExtendedPath: (dateRange: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => void;
  loading: boolean;
}) => {
  const [buttonName, setButtonName] = useState("View Extended Path");
  const [activeVariant, setActiveVariant] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }>({
    startDate: dayjs().subtract(2, "day"),
    endDate: dayjs(),
  });

  const handleClick = () => {
    handleViewExtendedPath(dateRange);
    setButtonName("Update");
    setActiveVariant(true);
  };
  return (
    <Box className={styles.extendedPathModal}>
      <Box className={styles.extendedPathBody}>
        <SelectDateRangePicker
          startDateTitle="Start Date"
          endDateTitle="End Date"
          buttonName={buttonName}
          dateRange={dateRange}
          setDateRange={setDateRange}
          handleClick={handleClick}
          activeVariant={activeVariant}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default ExtendedPath;

import { Box, Button, Divider, Skeleton } from "@mui/material";
import styles from "./Overview.module.scss";
import OveriewShipIcon from "../../../../assets/icons/OveriewShipIcon";
import { AllDetailsTabs } from "../../MainMap";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import BunkeringShip from "../../../../assets/BunkeringShip.png";
import styled from "styled-components";
import moment from "moment";
import axios from "axios";
import { getSatelliteImage } from "../../RenderShips/apiCalls";
import Modal from "../../../../components/Modal/Modal";

interface CustomButtonProps {
  isActive: boolean;
}

const CustomButton = styled(Button)<CustomButtonProps>(({ isActive }) => ({
  position: "relative",
  backgroundColor: isActive ? "rgba(0, 148, 255, 1)" : "rgba(0, 0, 0, 0.5)",
  color: "white",
  fontSize: "12px",
  textTransform: "none",
  borderRadius: "8px",
  padding: "2px 6px",
  minWidth: "unset",
  "&:hover": {
    backgroundColor: isActive ? "rgba(0, 148, 255, 1)" : "rgba(0, 0, 0, 0.5)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    borderStyle: "solid",
    borderWidth: "5px",
    borderColor: `transparent ${
      isActive ? "rgba(0, 148, 255, 1)" : "rgba(0, 0, 0, 0.5)"
    } transparent transparent`,
  },
  "&.rightArrow::before": {
    right: "-5px",
    borderWidth: "5px 0 5px 5px",
    borderColor: `transparent transparent transparent  ${
      isActive ? "rgba(0, 148, 255, 1)" : "rgba(0, 0, 0, 0.5)"
    }`,
  },
  "&.leftArrow::before": {
    left: "-5px",
    borderWidth: "5px 5px 5px 0",
    borderColor: `transparent  ${
      isActive ? "rgba(0, 148, 255, 1)" : "rgba(0, 0, 0, 0.5)"
    } transparent transparent`,
  },
}));

const Overview = ({
  tabDetails,
  bunkeringValue,
  setBunkeringValue,
  finalPathPoint,
  satelliteImageModal,
  setSatelliteImageModal
}: {
  tabDetails: AllDetailsTabs;
  bunkeringValue: string;
  setBunkeringValue: Dispatch<SetStateAction<string>>;
  finalPathPoint: any;
  satelliteImageModal: boolean;
  setSatelliteImageModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const {object_id, detectionData, speeds, dynamic} = tabDetails;
  const [satelliteImageUrl, setSatelliteImageUrl] = useState('')
  const [satelliteImageLoading, setSatelliteImageLoading] = useState<boolean>(true);
  let detectionValues = detectionData;

  const isLightOrAttributed = tabDetails.type === "light" || tabDetails.type === "attributed";
  const hasValidDetectionData = detectionData && Object.keys(detectionData).length > 0;
  
  if (!isLightOrAttributed || tabDetails.isObservationShip || !hasValidDetectionData) {
    detectionValues = {
      length: tabDetails.length,
      acquired: tabDetails.acquired,
      heading: tabDetails.heading,
      width: tabDetails.width,
      ship_type: tabDetails.ship_type,
    };
  } 
  
  useEffect(() => {
    object_id && getSatelliteImageRequest();
  },[object_id])

  const getSatelliteImageRequest = async () => {
    // const token = localStorage.getItem('token')
    if (!object_id) return;
    setSatelliteImageLoading(true);

    try {
      const imageData = await getSatelliteImage(object_id);
      if (imageData) {
        setSatelliteImageUrl(imageData);
      }
      // const res = await axios.get(`https://frontend-api-j75nm34yfa-uc.a.run.app/ship_clip?object_id=${object_id}`,{
      //   headers: {
      //     Authorization: 'Bearer ' + token,
      //   },
      //   responseType: 'blob'
      // });
      // setSatelliteImageUrl(URL.createObjectURL(res.data));
    } catch (error) {
      return "";
    } finally {
      setSatelliteImageLoading(false);
    }
  };

  const handleSatelliteImageClick = () => {
    setSatelliteImageModal(true)
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      className={styles.overviewMain}
    >
      {((tabDetails.type !== "spoofing" && tabDetails.type !== "sanctioned" && tabDetails.type !== "AISSTS" && tabDetails.type !== "AIS") || tabDetails?.isPathShip) && (
        <>       
          <Box display="flex" gap={2} className={styles.overviewWrap}>
            <Box className={styles.overViewImage}>
              {satelliteImageLoading ? (
                <Skeleton
                  variant="rounded"
                  width={225}
                  height={225}
                  sx={{ bgcolor: "#393c56" }}
                />
              ) : tabDetails.bunkering_id ? (
                <Box sx={{ position: "relative" }}>
                  <Box
                    style={{
                      position: "absolute",
                      top: "110px",
                      left: "30px",
                      zIndex: 10,
                    }}
                  >
                    <CustomButton
                      className="rightArrow"
                      isActive={bunkeringValue === "1"}
                      onClick={() => setBunkeringValue("1")}
                    >
                      Ship 1
                    </CustomButton>
                  </Box>
                  <img src={BunkeringShip} alt="ShipImg" />
                  <Box
                    style={{
                      position: "absolute",
                      top: "85px",
                      right: "55px",
                      zIndex: 10,
                    }}
                  >
                    <CustomButton
                      className="leftArrow"
                      isActive={bunkeringValue === "2"}
                      onClick={() => setBunkeringValue("2")}
                    >
                      Ship 2
                    </CustomButton>
                  </Box>
                </Box>
              ) : (
                <img
                  src={satelliteImageUrl}
                  alt="ShipImg"
                  onClick={handleSatelliteImageClick}
                />
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.overviewDetail}
            >
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>OID</span>
                <span className={styles.detailValue}>
                  {object_id ? object_id : "No Info"}
                </span>
              </Box>
              <Box display="flex" gap={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>Longitude</span>
                  <span className={styles.detailValue}>
                    {tabDetails.longitude ?? "No Info"}
                  </span>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={styles.detailWrap}
                >
                  <span className={styles.detailHead}>Latitude</span>
                  <span className={styles.detailValue}>
                    {tabDetails.latitude ?? "No Info"}
                  </span>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Image Captured Time</span>
                <span className={styles.detailValue}>
                  {moment
                    .utc(detectionValues.acquired)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Length</span>
                <span className={styles.detailValue}>
                  {detectionValues.length ?? "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Width</span>
                <span className={styles.detailValue}>
                  {detectionValues.width ?? "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Ship Type</span>
                <span className={styles.detailValue}>
                  {detectionValues.ship_type ?? "No Info"}
                </span>
              </Box>
            </Box>
          </Box>
          <Divider className={styles.overViewDivider} />
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="column"
            gap={1}
            className={styles.satelliteImageModal}
          >
            <Modal
              headerTitle={`Satellite Imagery - ${
                tabDetails?.name ? tabDetails?.name : tabDetails?.object_id
              }`}
              handleClose={() => setSatelliteImageModal(false)}
              onClickMinimize={() => {}}
              onMinimizeClick={!satelliteImageModal}
              showMinimize={false}
            >
              <Box className={styles.modalImage}>
                {satelliteImageLoading ? (
                  <Skeleton
                    variant="rounded"
                    width={450}
                    height={450}
                    sx={{ bgcolor: "#393c56" }}
                  />
                ) : (
                  <img
                    src={satelliteImageUrl}
                    alt="ShipImg"
                    className={styles.modalImage}
                  />
                )}
              </Box>
            </Modal>
          </Box>
        </>
      )}

      <Box
        display="grid"
        gridTemplateColumns="repeat(4,1fr)"
        gap={2}
        className={styles.overviewDetail}
      >
        {tabDetails.type !== "unattributed" && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              gridColumn="span 2"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Latest Speed</span>
              {finalPathPoint ? (
                <span
                  className={styles.detailValue}
                  style={{ fontSize: "10px" }}
                >
                  {finalPathPoint.speed} (Acquired on{" "}
                  {moment
                    .utc(finalPathPoint.timestamp * 1000)
                    .format("YYYY-MM-DD HH:mm:ss")}{" "}
                  UTC)
                </span>
              ) : (
                <span
                  className={styles.detailValue}
                  style={{ fontSize: "10px" }}
                >
                  No Info
                </span>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Destination</span>
              <span className={styles.detailValue}>
                {dynamic &&
                  dynamic.length > 0 &&
                  dynamic[dynamic.length - 1].destination}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>ETA</span>
              <span className={styles.detailValue}>
                {dynamic &&
                dynamic.length > 0 &&
                dynamic[dynamic.length - 1].eta !== null
                  ? moment
                      .utc(dynamic[dynamic.length - 1].eta)
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "No Info"}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Longitude</span>
              <span className={styles.detailValue}>
                {tabDetails.longitude ?? "No Info"}
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={styles.detailWrap}
            >
              <span className={styles.detailHead}>Latitude</span>
              <span className={styles.detailValue}>
                {tabDetails.latitude ?? "No Info"}
              </span>
            </Box>
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          className={styles.detailWrap}
        >
          <span className={styles.detailHead}>Heading</span>
          <span className={styles.detailValue}>
            {tabDetails.heading ?? "No Info"}
          </span>
        </Box>
      </Box>

      {tabDetails.type !== "unattributed" && (
        <>
          <Divider className={styles.overViewDivider} />
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              className={styles.detailWrap}
              width="225px"
            >
              <OveriewShipIcon />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <span className={styles.detailValue}>
                  {dynamic &&
                    dynamic.length > 0 &&
                    dynamic[dynamic.length - 1].draught}
                  m draft
                </span>
                <span className={styles.detailValue}>
                  {tabDetails.length && tabDetails.width
                    ? `${tabDetails.length}m x ${tabDetails.width}m`
                    : "No Info"}
                </span>
              </Box>
            </Box>
            <Box display="grid" gridTemplateColumns="repeat(2,1fr)" gap={2}>
              <Box
                display="flex"
                flexDirection="column"
                gridColumn="span 2"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Type</span>
                <span className={styles.detailValue}>
                  {tabDetails.ship_type ?? "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Avg. Speed</span>
                <span className={styles.detailValue}>
                  {speeds && speeds.length > 0 ? speeds[5] : "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Max Speed</span>
                <span className={styles.detailValue}>
                  {speeds && speeds.length > 0 ? speeds[9] : "No Info"}
                </span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Build Year</span>
                <span className={styles.detailValue}>No Info</span>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={styles.detailWrap}
              >
                <span className={styles.detailHead}>Status</span>
                <span className={styles.detailValue}>
                  {tabDetails.status ?? "No Info"}
                </span>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Overview;

import { useState } from "react";
import { PersonOutlineOutlined } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./UserProfile.module.scss";

interface UserProfileProps {
  logout: () => void;
}

const UserProfile = ({ logout }: UserProfileProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box onClick={handleClick}>
        <PersonOutlineOutlined className={styles.icon} />
      </Box>
      <Menu
        id="basic-menu"
        onClose={handleClose}
        anchorEl={anchorEl}
        open={anchorEl ? true : false}
        classes={{ paper : styles.menuWrap }}
      >
        <MenuItem className={styles.menuItem}>
          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            className={styles.logoutButtonWrap}
            onClick={logout}
          >
            <LogoutIcon
              style={{
                fontSize: "20px",
              }}
            />
            Logout
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserProfile;

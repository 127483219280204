import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import styles from "./CheckboxComponent.module.scss";

interface CheckboxComponentProps {
  className?: any;
  label: string;
  checked?: boolean;
  onChange?: (e: any) => void;
}

const CheckboxComponent = ({
  className,
  label,
  checked,
  onChange,
}: CheckboxComponentProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        className={styles.Checkbox || className}
        control={
          <Checkbox
            className={styles.Check}
            classes={{ checked: styles.CheckTick }}
            defaultChecked
            checked={checked}
            onChange={onChange}
            size="medium"
          />
        }
        label={label}
        classes={{ label: styles.CheckboxLabel }}
      />
    </FormGroup>
  );
};

export default CheckboxComponent;

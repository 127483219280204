import React from "react";

const TrendingPortsIcon = ({size = 20}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2219_8084)">
      <path
        d="M10.0003 6.66699C11.381 6.66699 12.5003 5.5477 12.5003 4.16699C12.5003 2.78628 11.381 1.66699 10.0003 1.66699C8.61961 1.66699 7.50033 2.78628 7.50033 4.16699C7.50033 5.5477 8.61961 6.66699 10.0003 6.66699ZM10.0003 6.66699V18.3337M10.0003 18.3337C7.79019 18.3337 5.67057 17.4557 4.10777 15.8929C2.54497 14.3301 1.66699 12.2105 1.66699 10.0003H4.16699M10.0003 18.3337C12.2105 18.3337 14.3301 17.4557 15.8929 15.8929C17.4557 14.3301 18.3337 12.2105 18.3337 10.0003H15.8337"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2219_8084">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TrendingPortsIcon;

import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Header from "../Header/Header";
import HomePanel from "../HomePanel/HomePanel";
import Spinner from "../../components/Spinner/Spinner";
import styles from "./LayoutWrapper.module.scss";
import { Outlet } from "react-router";

const LayoutWrapper: FC = () => {
  const { isRecent } = useSelector((state: RootState) => state.homePanel);
  const { isShared } = useSelector((state: RootState) => state.shared);
  const { isDashboard } = useSelector((state: RootState) => state.header);
  const { loading, isWorkLoading } = useSelector(
    (state: RootState) => state.common
  );
  const { loading: notificationLoading } = useSelector(
    (state: RootState) => state.notification
  );
  const { loading: sharedLoading } = useSelector(
    (state: RootState) => state.shared
  );

  return (
    <div className={styles.layoutWrapper}>
      {(loading || notificationLoading || sharedLoading || isWorkLoading) && (
        <Spinner />
      )}
      <Header />
      <div className={styles.contentWrapper}>
        {(isDashboard || isRecent || isShared) && <HomePanel />}
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LayoutWrapper;

import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import Table from "../Table";
import styles from "./TabsWithTable.module.scss";

interface TabsWithTableProps {
  columns: string[];
  tabLabels: string[];
  tabData: {
    [key: string]: { [key: string]: string | number | React.ReactNode }[];
  };
  handleRowClick: (id: string, type: string, tabIndex?: string) => void;
  type: string;
}

const TabsWithTable: React.FC<TabsWithTableProps> = ({
  columns,
  tabLabels,
  tabData,
  handleRowClick,
  type,
}) => {
  const [activeTab, setActiveTab] = useState<string>("1");

  return (
    <Box>
      <Box className={styles.shipTabMain}>
        <TabContext value={activeTab}>
          <TabList
            className={styles.shipDetailsTab}
            classes={{ indicator: styles.shipTabIndicator }}
            onChange={(e, value) => setActiveTab(value)}
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={index}
                className={styles.shipTab}
                classes={{ selected: styles.shipTabSelected }}
                label={label}
                value={(index + 1).toString()}
              />
            ))}
          </TabList>
        </TabContext>
      </Box>
      <Box sx={{ padding: "14px" }}>
        <Table
          columns={columns}
          rows={tabData[activeTab] || []}
          onRowClick={handleRowClick}
          type={type}
        />
      </Box>
    </Box>
  );
};

export default TabsWithTable;

const MovementShiptools = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="map-01" clip-path="url(#clip0_3745_27017)">
        <path
          id="Icon"
          d="M13.9997 6.5V1.33398L9.99967 3.00065M9.99967 3.00065L5.66634 1.33398M9.99967 3.00065V6.5M5.66634 1.33398L1.33301 4.00065V14.6673L5.66634 12.0007V1.33398Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Icon_2"
          d="M14.75 14.75L13.25 13.25M14 11.375C14 12.8247 12.8247 14 11.375 14C9.92525 14 8.75 12.8247 8.75 11.375C8.75 9.92525 9.92525 8.75 11.375 8.75C12.8247 8.75 14 9.92525 14 11.375Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3745_27017">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MovementShiptools;

import React, { useEffect, useState } from "react";
import DeckGL from "@deck.gl/react/typed";
import {
  COORDINATE_SYSTEM,
  _GlobeView as GlobeView,
} from "@deck.gl/core/typed";
import { useDispatch } from "react-redux";
import { SimpleMeshLayer } from "@deck.gl/mesh-layers/typed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import { SphereGeometry } from "@luma.gl/engine";
import MapLayerPopover from "./MapLayerPopover";
import moment from "moment";

const DeckglRender = ({
  setZoomLevel,
  zoomLevel,
  setMapLatLng,
  mapViewState,
  selectedTabId,
  setChangeSaved,
  deckGlLayers,
  mapRef,
  setMapSnapshot,
  showCursor,
  setShowCursor,
  mapLatLng,
  shipPaths,
  mapStyle,
}: any) => {
  const dispatch = useDispatch();
  const EARTH_RADIUS_METERS = 6.3e6;
  const backgroundLayers = [
    new SimpleMeshLayer({
      id: "earth-sphere",
      data: [0],
      mesh: new SphereGeometry({
        radius: EARTH_RADIUS_METERS,
        nlat: 18,
        nlong: 36,
      }),
      coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      getPosition: [0, 0, 0],
      getColor: mapStyle.includes("dark") ? [38, 38, 38] : [255, 255, 255],
    }),
    new GeoJsonLayer({
      id: "earth-land",
      data: "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_land.geojson",
      stroked: false,
      filled: true,
      opacity: 0.1,
      getFillColor: mapStyle.includes("dark") ? [100, 100, 100] : [30, 80, 120],
    }),
  ];

  const [clickedCoordinates, setClickedCoordinates] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0,
  });
  const [selectedMapObject, setSelectedMapObject] = useState<any | null>(null);
  const [hoverInfo, setHoverInfo] = useState<{
    x: number;
    y: number;
    longitude: number;
    latitude: number;
    timestamp: string;
  } | null>(null);

  const handleMapClick = (event: any): void => {
    const pathAnalysis = shipPaths?.[event?.object?.synmax_ship_id]?.analysis;
    if (event?.object && pathAnalysis) {
      const { timestamp, longitude, latitude } = event.object;
      setHoverInfo(() => ({
        x: event.x,
        y: event.y,
        longitude,
        latitude,
        timestamp: `${moment
          .utc(timestamp * 1000)
          .format("DD/MM/YYYY HH:mm:ss")} UTC`,
      }));
    } else {
      setHoverInfo(null);
    }

    if (event.object !== undefined) {
      setSelectedMapObject(event.object);
    } else {
      setSelectedMapObject(null);
    }
    setClickedCoordinates({ x: event.x, y: event.y });
  };

  useEffect(() => {
    setSelectedMapObject(null);
    setClickedCoordinates({
      x: 0,
      y: 0,
    });
  }, [zoomLevel, mapLatLng]);

  return (
    <>
      <DeckGL
        ref={mapRef}
        onAfterRender={() => {
          const base64Image =
            //@ts-ignore
            mapRef?.current?.deck?.canvas?.toDataURL("image/png");
          setMapSnapshot(base64Image);
        }}
        views={new GlobeView({})}
        initialViewState={mapViewState}
        controller={true}
        layers={[backgroundLayers, deckGlLayers]}
        onViewStateChange={(e: any) => {
          setMapLatLng({
            lat: e.viewState.latitude,
            lng: e.viewState.longitude,
          });
          setZoomLevel(e.viewState.zoom);
          dispatch(setChangeSaved(false));
        }}
        onClick={handleMapClick}
        key={selectedTabId}
        onHover={(object) => {
          if (object.layer) {
            const layerId = object.layer.id;
            if (
              layerId === "selectedShips" ||
              layerId === "light" ||
              layerId === "unattributed" ||
              layerId === "attributed" ||
              layerId === "spoofingEvents" ||
              layerId === "opticalSTS" ||
              layerId === "AISSTS" ||
              layerId === "sanctionedShips"
            ) {
              setShowCursor(true);
            }
          } else {
            setShowCursor(false);
          }
        }}
        getCursor={() => (showCursor ? "pointer" : "grab")}
      >
        {hoverInfo && (
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              pointerEvents: "none",
              background: "white",
              padding: "5px",
              borderRadius: "5px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
              left: hoverInfo.x + 10,
              top: hoverInfo.y,
              color: "#000",
              fontSize: "10px",
              fontFamily: "Arial, sans-serif",
              whiteSpace: "nowrap",
              transform: "translateY(-50%)",
            }}
          >
            <div style={{ lineHeight: "15px" }}>
              <strong>Longitude:</strong> {hoverInfo.longitude}
            </div>
            <div style={{ lineHeight: "15px" }}>
              <strong>Latitude:</strong> {hoverInfo.latitude}
            </div>
            <div style={{ lineHeight: "15px" }}>
              <strong>Timestamp:</strong> {hoverInfo.timestamp}
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "-5px",
                transform: "translateY(-50%)",
                width: 0,
                height: 0,
                borderTop: "5px solid transparent",
                borderBottom: "5px solid transparent",
                borderRight: "5px solid white",
              }}
            />
          </div>
        )}
      </DeckGL>

      <MapLayerPopover
        selectedMapObject={selectedMapObject}
        clickedCoordinates={clickedCoordinates}
      />
    </>
  );
};

export default DeckglRender;

import { Slider, SliderValueLabelProps, Tooltip } from "@mui/material";
import styles from "./DateSlider.module.scss";

const ToolTipComponent = (props: SliderValueLabelProps) => {
  const { children, open, value } = props;
  return (
    <Tooltip
      open={open}
      placement="bottom"
      title={value}
      arrow
      classes={{
        tooltip: styles.tooltipMain,
        arrow: styles.toolTipArrow,
      }}
    >
      {children}
    </Tooltip>
  );
};

const DateSlider = ({
  value,
  minDate,
  maxDate,
  step,
  getAriaValueText,
  valueLabelFormat,
  onChange,
  className,
}: any) => {
  return (
    <Slider
      value={value}
      min={minDate || 0}
      max={maxDate}
      step={step}
      getAriaValueText={getAriaValueText}
      valueLabelFormat={valueLabelFormat}
      onChange={onChange}
      valueLabelDisplay="on"
      className={`${styles.sliderMain} ${className}`}
      classes={{
        rail: styles.sliderRail,
        track: styles.sliderTrack,
        thumb: styles.sliderThumb,
      }}
      slots={{
        valueLabel: ToolTipComponent,
      }}
    />
  );
};

export default DateSlider;

import * as turf from '@turf/turf';

// export const getLineStringMeasurement = (allFeatures: any) => {
//     const allPoints = allFeatures.features[allFeatures.features.length - 1].geometry.coordinates;
//     const lastPoint = allPoints[allPoints.length - 1]
//     const secondLastPoint = allPoints[allPoints.length - 2]
   
//     if(lastPoint && secondLastPoint) {
//       // var from = turf.point([secondLastPoint[1], secondLastPoint[0]]);
//       // var to = turf.point([lastPoint[1], lastPoint[0]]);

//       var from = turf.point(secondLastPoint);
//       var to = turf.point(lastPoint);
//       var options = { units: "kilometers" } as any;      
//       var distance = turf.distance(from, to, options);
//       const angle = turf.bearing(secondLastPoint,lastPoint)

//       if(allPoints.length > 3) {
//         let polygon = turf.polygon([[...allPoints, allPoints[0]]]);
//         let area =return3DecimalPoints(turf.area(polygon) / 1000000)
//         return {distance, angle, latLng: lastPoint, area}
//       } else {
//         return {distance, angle, latLng: lastPoint}
//       }
//     }
// }

export const getLineStringMeasurement = (points: any) => {
  const lastPoint = points[points.length - 1]
  const secondLastPoint = points[points.length - 2]

  if(lastPoint && secondLastPoint) {
    // var from = turf.point([secondLastPoint[1], secondLastPoint[0]]);
    // var to = turf.point([lastPoint[1], lastPoint[0]]);

    var from = turf.point(secondLastPoint);
    var to = turf.point(lastPoint);
    var options = { units: "kilometers" } as any;      
    var distance = turf.distance(from, to, options);
    const angle = turf.bearing(secondLastPoint,lastPoint)

    if(points.length > 3) {
      let polygon = turf.polygon([[...points, points[0]]]);
      let area =return3DecimalPoints(turf.area(polygon) / 1000000)
      return {distance, angle, latLng: lastPoint, area} 
    } else {
      return {distance, angle, latLng: lastPoint}
    }
  }
}

// export const getCircleMeasurement = (allFeatures: any) => {

//   if(allFeatures.features.length > 0) {
//     if(allFeatures.features[allFeatures.features.length - 1].properties.circleRadius ) {
//       const center = allFeatures.features[allFeatures.features.length - 1].geometry.coordinates[0][0];
//       const radius = allFeatures.features[allFeatures.features.length - 1].properties.circleRadius
//       return {latLng: center, radius}
//     }
//   }
// }

export const getCircleMeasurement = (feature: any) => {
  if (feature.properties.circleRadius) {
    const center = feature.geometry.coordinates[0][0];
    const radius = feature.properties.circleRadius;

    var options = {
      steps: 500,
      units: "kilometers"
    } as any;
    var circlePoints = turf.circle(center, radius, options);

    // var featuresall = turf.points(circlePoints.geometry.coordinates[0]);
    
    // var center2 = turf.center(featuresall);

    // console.log(center, '1, 2', center2.geometry.coordinates)


//     var pt = turf.point(circlePoints.geometry.coordinates[0]);
// var converted = turf.toMercator(pt);



    return { latLng: center, radius, circlePoints };
  }
};

const  numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const return3DecimalPoints = (numberString: any) => {
  const arrSplit = numberString.toString().split('.')
  if (arrSplit.length > 1) {
    return numberWithCommas(arrSplit[0]) + '.' +  arrSplit[1].substr(0,2) 
  }
}

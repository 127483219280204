import React from "react";

interface CopyIconProps {
  onClick?: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  stroke?: string;
}

const CopyIcon: React.FC<CopyIconProps> = ({
  onClick,
  disabled,
  style,
  stroke = "white",
}) => {
  return (
    <svg
      onClick={!disabled ? onClick : undefined}
      style={{ cursor: disabled ? "not-allowed" : "pointer", ...style }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2041_21786)">
        <path
          d="M6.6665 6.66602V4.33268C6.6665 3.39926 6.6665 2.93255 6.84816 2.57603C7.00795 2.26243 7.26292 2.00746 7.57652 1.84767C7.93304 1.66602 8.39975 1.66602 9.33317 1.66602H15.6665C16.5999 1.66602 17.0666 1.66602 17.4232 1.84767C17.7368 2.00746 17.9917 2.26243 18.1515 2.57603C18.3332 2.93255 18.3332 3.39926 18.3332 4.33268V10.666C18.3332 11.5994 18.3332 12.0661 18.1515 12.4227C17.9917 12.7363 17.7368 12.9912 17.4232 13.151C17.0666 13.3327 16.5999 13.3327 15.6665 13.3327H13.3332M4.33317 18.3327H10.6665C11.5999 18.3327 12.0666 18.3327 12.4232 18.151C12.7368 17.9912 12.9917 17.7363 13.1515 17.4227C13.3332 17.0661 13.3332 16.5994 13.3332 15.666V9.33268C13.3332 8.39926 13.3332 7.93255 13.1515 7.57603C12.9917 7.26243 12.7368 7.00746 12.4232 6.84767C12.0666 6.66602 11.5999 6.66602 10.6665 6.66602H4.33317C3.39975 6.66602 2.93304 6.66602 2.57652 6.84767C2.26292 7.00746 2.00795 7.26243 1.84816 7.57603C1.6665 7.93255 1.6665 8.39926 1.6665 9.33268V15.666C1.6665 16.5994 1.6665 17.0661 1.84816 17.4227C2.00795 17.7363 2.26292 17.9912 2.57652 18.151C2.93304 18.3327 3.39975 18.3327 4.33317 18.3327Z"
          stroke={stroke}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2041_21786">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNotification,
  updateNotification,
  updateNotificationMarkAsRead,
} from "./Notification.services";
import { AlertColor } from "@mui/material";

export type NotificationType = {
  [key: string]: {
    id: string;
    workStationId: string;
    workStationName: string;
    sharedUserId: string;
    notificationType: string;
    userId: string;
    folderId: string;
    status: string;
    isMarkAsRead: boolean;
    createdAt: Date | any;
    data: any;
  };
};

export type AddNotificationType = {
  userId: string;
  workStationName: string;
  type: string;
};

export type UpdateNotificationType = {
  userId: string;
  notificationId: string;
  isApprove: boolean;
};

export const addNotificationData = createAsyncThunk(
  "notification/addNotificationData",
  async (notificationData: AddNotificationType) => {
    try {
      const data = await addNotification(notificationData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNotificationData = createAsyncThunk(
  "notification/updateNotificationData",
  async (notificationData: UpdateNotificationType) => {
    try {
      const data = await updateNotification(notificationData);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateNotificationMarkAsReadData = createAsyncThunk(
  "notification/updateNotificationMarkAsReadData",
  async (userId: string) => {
    try {
      const data = await updateNotificationMarkAsRead(userId);
      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: {} as NotificationType,
    loading: false,
    error: "",
    isNotificationFetch: false,
    snackbarMessage: "",
    snackbarSeverity: "success" as AlertColor | undefined,
    isSnackBarOpen: false,
  },
  reducers: {
    setIsSnackBarOpen: (state, action) => {
      state.isSnackBarOpen = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //ADD NOTIFICATION
      .addCase(addNotificationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addNotificationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.snackbarMessage = action.payload.msg;
          state.snackbarSeverity = "success";
          state.isSnackBarOpen = true;
        }
      )
      .addCase(
        addNotificationData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //UPDATE NOTIFICATION
      .addCase(updateNotificationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateNotificationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          const notification = state.notifications;
          const updateNotification = action.payload.updateNotificationData;
          state.loading = false;
          if (notification[updateNotification.notificationId]) {
            if (updateNotification.status) {
              notification[updateNotification.notificationId].status =
                "approved";
            } else {
              notification[updateNotification.notificationId].status =
                "rejected";
            }
          }
        }
      )
      .addCase(
        updateNotificationData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      //UPDATE NOTIFICATION MARK AS READ
      .addCase(updateNotificationMarkAsReadData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateNotificationMarkAsReadData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
        }
      )
      .addCase(
        updateNotificationMarkAsReadData.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export const { setIsSnackBarOpen, setNotifications } =
  notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;

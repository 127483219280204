import { useState } from "react";
import AttributionList from "./AttributionList/AttributionList";
import ModalAttribution from "./ModalAttribution/ModalAttribution";
import { Box, Button } from "@mui/material";
import styles from "./Attribution.module.scss";
import { AllDetailsTabs } from "../../MainMap";

export interface AttributionInterface {
  name?: string;
  length?: number;
  type?: string;
  heading?: number;
  moving?: boolean;
  id?: string;
}

const Attribution = ({ tabDetails }: { tabDetails: AllDetailsTabs }) => {
  const [manualAttribution, setManualAttribution] =
    useState<AttributionInterface | null>(null);
  const [manualAttributionModal, setManualAttributionModal] =
    useState<AttributionInterface>({});
  const [openManualAttributionModal, setOpenManualAttributionModal] =
    useState(false);

  const handleOpen = () => {
    setManualAttributionModal(manualAttribution ? manualAttribution : {});
    setOpenManualAttributionModal(true);
  };

  const handleClose = () => setOpenManualAttributionModal(false);

  const handleSave = (attribution: AttributionInterface) => {
    setManualAttribution(attribution);
    setOpenManualAttributionModal(false);
  };

  const AUTOMATIC_ATTRIBUTION = {
    length: tabDetails.length,
    name: "Automatic Attribution",
    heading: tabDetails.heading,
    type: tabDetails.ship_type,
    moving: tabDetails.moving,
    id: tabDetails.attribution,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={styles.attributionMain}
    >
      <span className={styles.attributionHead}>Automatic Attribution</span>
      <AttributionList attribution={AUTOMATIC_ATTRIBUTION} />
      <span className={styles.attributionHead}>Manual Attribution</span>

      {!manualAttribution ? (
        <Box>
          <Button
            className={styles.attributionButton}
            variant="outlined"
            onClick={handleOpen}
          >
            Add Manual Attribution
          </Button>
        </Box>
      ) : (
        <Box>
          <AttributionList attribution={manualAttribution} />
          <Box
            display="grid"
            gridTemplateColumns="repeat(2,1fr)"
            gap={2}
            marginTop={2}
          >
            <Button
              className={styles.attributionButton}
              variant="outlined"
              onClick={handleOpen}
            >
              Update
            </Button>
            <Button
              className={styles.attributionButton}
              variant="outlined"
              onClick={() => setManualAttribution(null)}
            >
              Remove
            </Button>
          </Box>
        </Box>
      )}

      <ModalAttribution
        openManualAttributionModal={openManualAttributionModal}
        handleSave={handleSave}
        handleClose={handleClose}
        ManualAttribution={manualAttributionModal}
        setManualAttribution={setManualAttributionModal}
      />
    </Box>
  );
};

export default Attribution;

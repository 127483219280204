import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnalyzeShape,
  setIsOpenPolygonShipAnalysisModal,
  setPolygonDataForSlider,
} from "./PolygonShipAnalysis.store";
import { setMinimizePolygonModal } from "../MainMap.store";
import { RootState } from "../../../redux/store";
import Modal from "../../../components/Modal/Modal";
import PolygonShipAnalysisSelectDate from "./PolygonShipAnalysisSelectDate/PolygonShipAnalysisSelectDate";
import PolygonShipAnalysisSlider from "./PolygonShipAnalysisSlider/PolygonShipAnalysisSlider";
import { ShipSvgType } from "../../../utils/ShipSvgs";
import { DateRange } from "../MainMap";
import styles from "./PolygonShipAnalysis.module.scss";
import { useSnackbar } from "../../../context/SnackbarContext";
import moment from "moment";

interface PolygonShipAnalysisProps {
  setSelectedShape: (val: ShipSvgType | null) => void;
  dateRange: DateRange;
  setDateRange: (val: DateRange) => void;
  handleSimilarShips: () => Promise<void>;
  showSimilarShips: boolean;
  lastSelectedOption: string;
  setLastSelectedOption:(lastSelectedOption: string) => void;
}

const PolygonShipAnalysis = ({
  setSelectedShape,
  dateRange,
  setDateRange,
  handleSimilarShips,
  showSimilarShips,
  lastSelectedOption,
  setLastSelectedOption
}: PolygonShipAnalysisProps) => {
  const dispatch = useDispatch();
  const { minimizePolygonModal } = useSelector(
    (state: RootState) => state.mainMap
  );
  const { polygonData, error } = useSelector(
    (state: RootState) => state.polygonShipAnalysis
  );

  const [showPolygonShipAnalysisSlider, setShowPolygonShipAnalysisSlider] =
    useState<boolean>(false);

  const handleClose = () => {
    dispatch(setIsOpenPolygonShipAnalysisModal(false));
    dispatch(setAnalyzeShape([]));
    setDateRange({
      startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
      endDate: new Date(),
    });
    setSelectedShape(null);
  };
  const { setIsSnackBarOpen, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbar();

  const settingPolygonDataForSlider = () => {
    let index = 1,
      startDate = moment
      .utc(
        `${moment((dateRange.startDate as any).$d).format("YYYY-MM-DD")} 00:00:00`,
        "YYYY-MM-DD HH:mm:ss"
      )
      .unix(),
      endDate = moment
      .utc(
        `${moment((dateRange.endDate as any).$d).format("YYYY-MM-DD")} 23:59:59`,
        "YYYY-MM-DD HH:mm:ss"
      )
      .unix();
      console.log(dateRange, ':dateRange')
    let size = Math.ceil((endDate - startDate) / 900);
    let dataForSlider: any = Array.from({ length: size }, () => []);
    let sortedPolygonData = polygonData
      .slice()
      .sort((a: any, b: any) => (a.timestamp/1000) - (b.timestamp/1000));
      console.log(sortedPolygonData, ':sortedPolygonData')
    let set = new Set();
    sortedPolygonData.forEach((data: any) => {
      (data.timestamp/1000) > startDate + 15 * 60 * index && ++index && set.clear();
      !set.has(data.synmax_ship_id) &&
        dataForSlider[index - 1].push(data) &&
        set.add(data.synmax_ship_id);
    });
    console.log(dataForSlider,':dataForSlider')
    dispatch(setPolygonDataForSlider(dataForSlider));
  };
  useEffect(() => {
    settingPolygonDataForSlider();
  }, [polygonData]);
  useEffect(() => {
    if (error) {
      setSnackbarMessage(error);
      setIsSnackBarOpen(true);
      setSnackbarSeverity("error");
    }
  }, [error]);
  return (
    <Modal
      headerTitle="Polygon Ship Analysis"
      handleClose={handleClose}
      onClickMinimize={() => {
        dispatch(setMinimizePolygonModal(true));
      }}
      onMinimizeClick={minimizePolygonModal}
      className={styles.polygonShip}
    >
      <PolygonShipAnalysisSelectDate
        setShowPolygonShipAnalysisSlider={setShowPolygonShipAnalysisSlider}
        dateRange={dateRange}
        setDateRange={setDateRange}
        showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        lastSelectedOption={lastSelectedOption}
        setLastSelectedOption={setLastSelectedOption}
      />
        <PolygonShipAnalysisSlider
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          handleSimilarShips={handleSimilarShips}
          showSimilarShipsButton={showSimilarShips}
          showPolygonShipAnalysisSlider={showPolygonShipAnalysisSlider}
        />
    </Modal>
  );
};

export default PolygonShipAnalysis;

import { Divider } from "@mui/material";
import styles from "./SearchOptionRow.module.scss";


const SearchOptionRow: React.FC<{
  columns: { title: string; data: string | number }[];
  rowHeader: React.ReactElement;
  props: any;
  searchValue: any;
  isAISSearch: boolean;
  loadShipsOnMap:any;
}> = ({ columns, rowHeader, props, searchValue, isAISSearch,loadShipsOnMap }) => {


  

  return (
    <li {...props} className={styles.searchListItem} onClick={() => loadShipsOnMap(isAISSearch, searchValue)}>
      <div className={styles.searchItemRow}>
        <div className={styles.searchItemTitle}>{rowHeader}</div>
        <div className={styles.searchItemRowData}>
          {columns.map((column: any) => (
            <div className={styles.searchColumn}>
              <div className={styles.searchRowTitle}>{column.title}</div>
              <div className={styles.searchRowData}>
                {column.data === null ? "No Info" : column.data}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Divider className={styles.searchItemDivider} orientation="horizontal" />
    </li>
  );
};

export default SearchOptionRow;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateAllProjects } from "../../../../common/Common.store";
import { setChangeSaved } from "../../Theia.store";
import ShipFilters from "../ShipFilters/ShipFilters";
import FilterEventType from "../FilterEventType/FilterEventType";
import { SHIP_MARKERS_EVENTS } from "../../../../utils/Constants";
import ListenForOutsideClicks from "../../../../common/Listen";
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Radio,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import styles from "./FilterArea.module.scss";
import { FilterLayer, RightSideNavigationSelectedFilter } from "../../MainMap";
import LightView from "../../../../assets/LightView.png";
import DarkMap from "../../../../assets/icons/DarkMap";
import SatelliteMap from "../../../../assets/icons/SatelliteMap";

export interface ICurrentEventTypeFilterOptions {
  [key: string]: FilterParameters;
}
export interface FilterParameters {
  checked: boolean;
  noInfoShips: boolean;
  noImoShips: boolean;
  sanctionedShips: boolean;
  markerColor: string;
  iconStringNormal?: string;
  iconStringSanctioned?: string;
  length: [number, number];
  width: [number, number];
  heading: [number, number];
  country: { code: string; label: string };
  subType: { [key: string]: boolean };
  type: string;
  providerFilters: {
    [key: string]: boolean;
  };
  isMoving?: string;
}
interface IFilterAreaProps {
  currentEventTypeFilterOptions: ICurrentEventTypeFilterOptions;
  setCurrentEventTypeFilterOptions: any;
  currentSelectedFilter: RightSideNavigationSelectedFilter | null;
  filterLayers: FilterLayer[];
  setCurrentSelectedFilter: (
    slectedFilter: RightSideNavigationSelectedFilter | null
  ) => void;
  showFilter: boolean;
  intialStateForFilterProvider: any;
  setIntialStateForFilterProvider: any;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setMapStyle: React.Dispatch<React.SetStateAction<string>>;
  showGlobeView: boolean;
}

const FilterArea = (props: IFilterAreaProps) => {
  const {
    currentEventTypeFilterOptions,
    setCurrentEventTypeFilterOptions,
    currentSelectedFilter,
    setCurrentSelectedFilter,
    filterLayers,
    showFilter,
    intialStateForFilterProvider,
    setShowFilter,
    setIntialStateForFilterProvider,
    setMapStyle,
    showGlobeView,
  } = props;
  const dispatch = useDispatch();
  const { allProjects } = useSelector((state: RootState) => state.common);
  const [currentEventTypeFilter, setCurrentEventTypeFilter] = useState<
    string | null
  >(null);
  const { tabs, selectedTabId } = useSelector(
    (state: RootState) => state.header
  );
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [listening, setListening] = useState(false);
  const menuRef: any = useRef(null);
  const mapStyles = {
    dark: "mapbox://styles/mapbox/dark-v11",
    satellite: "mapbox://styles/mapbox/satellite-streets-v12",
    light: "mapbox://styles/mapbox/light-v11",
  };

  const [selectedStyle, setSelectedStyle] = useState(mapStyles.light);

  useEffect(() => {
    const handleOutsideClick = ListenForOutsideClicks({
      listening,
      setListening,
      menuRef,
      handleOutsideClick: () => {
        setCurrentEventTypeFilter(null);
        setCurrentSelectedFilter(null);
      },
    });

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [
    listening,
    setListening,
    menuRef,
    currentSelectedFilter,
    setCurrentSelectedFilter,
    setCurrentEventTypeFilter,
  ]);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.stopPropagation();
      setExpanded(isExpanded ? panel : false);
    };

  const currentFilter =
    currentEventTypeFilterOptions[
      currentEventTypeFilter ? currentEventTypeFilter : ""
    ];

  const updatedFilterValue = (
    shipIndex: keyof typeof currentFilter,
    value: any
  ) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    if (currentEventTypeFilter) {
      let updatedFilterState;
      if (shipIndex === "markerColor") {
        updatedFilterState = {
          ...currentEventTypeFilterOptions,
          [currentEventTypeFilter]: {
            ...currentEventTypeFilterOptions[currentEventTypeFilter],
            [shipIndex]: value,
            iconStringNormal: getColorUpdatedShipIcon(
              currentEventTypeFilter,
              value
            ),
            iconStringSanctioned: getColorUpdatedSanctionedShipIcon(
              currentEventTypeFilter,
              value
            ),
          },
        };
      } else {
        updatedFilterState = {
          ...currentEventTypeFilterOptions,
          [currentEventTypeFilter]: {
            ...currentEventTypeFilterOptions[currentEventTypeFilter],
            [shipIndex]: value,
          },
        };
      }

      currentEventTypeFilter &&
        setCurrentEventTypeFilterOptions(updatedFilterState);

      const updatedProjects = {
        ...allProjects,
        [folderId]: {
          ...allProjects[folderId],
          workStations: {
            ...allProjects[folderId].workStations,
            [workStationId]: {
              ...allProjects[folderId].workStations[workStationId],
              mapState: {
                ...allProjects[folderId].workStations[workStationId].mapState,
                mapFilters: updatedFilterState,
              },
            },
          },
        },
      };
      dispatch(setChangeSaved(false));
      dispatch(updateAllProjects(updatedProjects));
    }
  };

  const updateCheckedFilterValue = (eventType: any, oldValue: any) => {
    const { folderId, workStationId } = tabs[selectedTabId];

    const updatedFilterState = {
      ...currentEventTypeFilterOptions,
      [eventType]: {
        ...currentEventTypeFilterOptions[eventType],
        checked: !oldValue,
      },
    };
    setCurrentEventTypeFilterOptions(updatedFilterState);
    // dispatch for state update
    const updatedProjects = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              mapFilters: updatedFilterState,
            },
          },
        },
      },
    };
    dispatch(setChangeSaved(false));
    dispatch(updateAllProjects(updatedProjects));
  };

  const handleMapClick = (style: string) => {
    setMapStyle(style);
    setSelectedStyle(style);
  };

  return (
    <Box display="flex" flexDirection="column" className={styles.filtersMain}>
      {currentSelectedFilter === RightSideNavigationSelectedFilter.Ships &&
        Object.keys(currentEventTypeFilterOptions).length > 0 && (
          <Box className={styles.filterSelector}>
            {currentEventTypeFilter ? (
              <ShipFilters
                currentEventTypeFilter={currentEventTypeFilter}
                setCurrentEventTypeFilter={setCurrentEventTypeFilter}
                updatedFilterValue={updatedFilterValue}
                currentFilter={currentFilter}
                currentEventTypeFilterOptions={currentEventTypeFilterOptions}
                setCurrentEventTypeFilterOptions={
                  setCurrentEventTypeFilterOptions
                }
                intialStateForFilterProvider={intialStateForFilterProvider}
                setIntialStateForFilterProvider={
                  setIntialStateForFilterProvider
                }
              />
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.shipFiltersHead}
                >
                  <span>Ship Marker</span>
                  <Close
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFilter(!showFilter);
                      setCurrentEventTypeFilter(null);
                      setCurrentSelectedFilter(null);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  className={styles.mapCheckbox}
                >
                  <Checkbox
                    size="medium"
                    className={styles.checkboxMain}
                    classes={{ checked: styles.checkboxChecked }}
                  />
                  <span className={styles.mapCheckboxLabel}>
                    Show Legend on Map
                  </span>
                </Box>

              {SHIP_MARKERS_EVENTS.map((items, key) => {
                return (
                  <Accordion
                    expanded={expanded === key}
                    onChange={handleChange(key)}
                    key={key}
                    className={styles.accordionMain}
                    classes={{ expanded: styles.accordionExpanded }}
                  >
                    <AccordionSummary
                      aria-controls={"panel1-content"}
                      id={"panel1-content"}
                      className={styles.accordionWrap}
                      classes={{
                        content: styles.accordionContent,
                      }}
                      expandIcon={
                        <ExpandMore className={styles.expandMoreIcon} />
                      }
                    >
                      <Radio
                        className={styles.radioMain}
                        classes={{ checked: styles.radioChecked }}
                        checked={expanded === key}
                      />
                      <span className={styles.accordionLabel}>
                        {items.EventName}
                      </span>
                    </AccordionSummary>

                    <AccordionDetails className={styles.accordionDetail}>
                      {key === 0 && (
                        <FilterEventType
                          updateCheckedFilterValue={updateCheckedFilterValue}
                          currentEventTypeFilterOptions={
                            currentEventTypeFilterOptions
                          }
                          setCurrentEventTypeFilter={setCurrentEventTypeFilter}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </>
          )}
        </Box>
      )}
      {currentSelectedFilter === RightSideNavigationSelectedFilter.Layers && (
        <Box className={styles.filterSelector}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={styles.shipFiltersHead}
          >
            <span>Map Layers</span>
            <Close
              onClick={(e) => {
                e.stopPropagation();
                setCurrentSelectedFilter(null);
                setCurrentEventTypeFilter(null);
              }}
            />
          </Box>

          <Box className={styles.mapLayersWrap}>
            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(mapStyles.dark)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.dark ? styles.selectedBox : ""
                }`}
              >
                <DarkMap />
              </Box>
              <Typography className={styles.mapTitle}>Dark</Typography>
            </Box>

            <Box
              className={`${styles.LayerContentWrap} ${
                showGlobeView ? styles.disabledWrap : ""
              }`}
              onClick={() =>
                showGlobeView ? {} : handleMapClick(mapStyles.satellite)
              }
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.satellite && !showGlobeView
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <SatelliteMap />
              </Box>
              <Typography className={styles.mapTitle}>Satellite</Typography>
            </Box>

            <Box
              className={styles.LayerContentWrap}
              onClick={() => handleMapClick(mapStyles.light)}
            >
              <Box
                className={`${styles.mapLayer} ${
                  selectedStyle === mapStyles.light ||
                  (selectedStyle === mapStyles.satellite && showGlobeView)
                    ? styles.selectedBox
                    : ""
                }`}
              >
                <img
                  className={styles.LightImg}
                  src={LightView}
                  alt="LightView"
                />
              </Box>
              <Typography className={styles.mapTitle}>Light</Typography>
            </Box>
          </Box>
          <Box className={styles.layerSelector}>
            {filterLayers.map((item) => (
              <FormControlLabel
                key={item.id}
                className={styles.layerRow}
                control={
                  <Checkbox
                    checked={item.value}
                    onChange={item.onChange}
                    size="medium"
                    sx={{ color: "white" }}
                  />
                }
                label={item.label}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export function useDebounce(cb: any, delay: any) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

const getColorUpdatedSanctionedShipIcon = (type: any, color: any) => {
  if (type === "light") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}"  stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
<circle cx="6.99951" cy="13.75" r="2" fill="white"/>
<path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
</svg>
`)
    );
  }

  if (type === "dark") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
        <path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
        <circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
        <path d="M12.6763 21.5L3.17627 7.5" stroke="#111326" stroke-width="1.5"/>
        </svg>
        
          `)
    );
  }
};

const getColorUpdatedShipIcon = (type: any, color: any) => {
  if(type === 'light') {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="white"/>
<path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="white"/>
<circle cx="6.99951" cy="13.75" r="2" fill="white"/>
</svg>
`)
    );
  }

  if (type === "AIS") {
    console.log('creating nere color')
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>

`)
    );
  }

  if (type === "unattributed") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
<circle cx="6.97607" cy="13" r="2.5" stroke="#111326" stroke-width="1.5"/>
<path d="M9.31696 17.9748C9.54407 18.3212 10.009 18.4179 10.3554 18.1908C10.7018 17.9637 10.7985 17.4987 10.5714 17.1523L9.31696 17.9748ZM7.6721 15.4659L9.31696 17.9748L10.5714 17.1523L8.92653 14.6435L7.6721 15.4659Z" fill="#111326"/>
</svg>

              `)
    );
  }

  if (type === "dark") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="102" height="191" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.9999 21.5L12.8235 21.5L12.8235 16.7413C12.8235 6.01595 6.99989 1.5 6.99989 1.5C6.99989 1.5 1.17627 6.01595 1.17628 16.7413L1.17628 21.5H6.9999Z" fill="${color}" stroke="#111326" stroke-width="1.5" stroke-miterlimit="10"/>
        <path d="M8.99453 16.1988L8.70042 15.8976L9.05429 15.5128C9.48062 15.0416 9.67182 14.6509 9.7621 14.0685C9.88313 13.2365 9.60721 12.4076 9.01115 11.7972L8.70136 11.4799L9.00255 11.1858L9.30373 10.8917L9.58999 11.1849C10.2409 11.8515 10.5992 12.7647 10.5839 13.7211C10.5685 14.6775 10.2862 15.3846 9.63857 16.1112L9.28863 16.5L8.99453 16.1988Z" fill="#111326"/>
        <path d="M5.00742 16.1988L5.30153 15.8976L4.94767 15.5128C4.52133 15.0416 4.33014 14.6509 4.23986 14.0685C4.11883 13.2365 4.39475 12.4076 4.9908 11.7972L5.30059 11.4799L4.99941 11.1858L4.69822 10.8917L4.41196 11.1849C3.76101 11.8515 3.40274 12.7647 3.41808 13.7211C3.43342 14.6775 3.7157 15.3846 4.36338 16.1112L4.71332 16.5L5.00742 16.1988Z" fill="#111326"/>
        <circle cx="6.99951" cy="13.75" r="2" fill="#111326"/>
        </svg>
        `)
    );
  }
  

  if (type === "spoofing") {
    return (
      "data:image/svg+xml;base64," +
      btoa(`<svg width="105" height="100" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="11" y="1.5" width="12.7282" height="12.7282" transform="rotate(45 11 1.5)" fill="${color}" stroke="#111326" stroke-width="1.5"/>
<path d="M11 11.25V7" stroke="#111326" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="11" cy="14" r="1" fill="#111326"/>
</svg>`)
    );
  }
};

export default FilterArea;

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  GET_SHIP_TOOLS_COMPONENT,
  MAP_PATHTOOLS_OPTIONS_TO_TITLE,
} from "../../../utils/Constants";
import {
  setMinimizePathToolModal,
  setOpenPathToolModal,
} from "../MainMap.store";
import Modal from "../../../components/Modal/Modal";
import { setFuturePathIntialValue } from "./FuturePathPrediction/FuturePathPrediction.store";
import ExtendedPath from "./ExtendedPath/ExtendedPath";
import dayjs from "dayjs";
import PathPlayback from "./PathPlayback/PathPlayback";
import { PathData } from "../MainMap";
import { Dispatch, SetStateAction } from "react";

const ShipTools = ({
  shipDetailTabValue,
  shipDetailsTabs,
  bunkeringValue,
  handleViewExtendedPath,
  setSelectedShipEstimatedLocationLayer,
  selectedShipEstimatedLoactionLayer,
  selectedPath,
  sliderValue,
  setSliderValue,
  pathRef,
  loading,
}: {
  shipDetailTabValue: any;
  shipDetailsTabs: any;
  bunkeringValue: any;
  handleViewExtendedPath: (dateRange: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => void;
  setSelectedShipEstimatedLocationLayer: Dispatch<SetStateAction<any>>;
  selectedShipEstimatedLoactionLayer: { startDate: string; data: any }[] | null;
  selectedPath: PathData[];
  sliderValue: number;
  setSliderValue: Dispatch<SetStateAction<number>>;
  pathRef: React.MutableRefObject<number | null>;
  loading: boolean;
}) => {
  const dispatch = useDispatch();
  const { openPathToolModal, minimizePathToolModal } = useSelector(
    (state: RootState) => state.mainMap
  );
  const { intervalId } = useSelector(
    (state: RootState) => state.futurePathPrediction
  );

  const handleClose = (key: any) => {
    if (selectedShipEstimatedLoactionLayer !== null) {
      setSelectedShipEstimatedLocationLayer(null);
    }
    dispatch(
      setMinimizePathToolModal({
        ...minimizePathToolModal,
        [key]: false,
      })
    );
    dispatch(setOpenPathToolModal({ ...openPathToolModal, [key]: false }));
  };

  const handleCloseFuturePathPrediction = () => {
    clearInterval(intervalId)
    dispatch(setFuturePathIntialValue());
  }

  return (
    <>
      {Object.keys(openPathToolModal).map((key: any) =>
        openPathToolModal[key] && key === "extendedPath" ? (
          <Modal
            headerTitle={MAP_PATHTOOLS_OPTIONS_TO_TITLE[key]}
            handleClose={() => handleClose(key)}
            onClickMinimize={() => {
              dispatch(
                setMinimizePathToolModal({
                  ...minimizePathToolModal,
                  [key]: true,
                })
              );
            }}
            onMinimizeClick={minimizePathToolModal[key]}
            isMainModalClass={false}
          >
            <ExtendedPath handleViewExtendedPath={handleViewExtendedPath} loading={loading}/>
          </Modal>
        ) : openPathToolModal[key] && key === "pathPlayback" ? (
          <Modal
            headerTitle={MAP_PATHTOOLS_OPTIONS_TO_TITLE[key]}
            handleClose={() => {
              pathRef.current = null;
              setSliderValue(() => 0);
              handleClose(key);
            }}
            onClickMinimize={() => {
              dispatch(
                setMinimizePathToolModal({
                  ...minimizePathToolModal,
                  [key]: true,
                })
              );
            }}
            onMinimizeClick={minimizePathToolModal[key]}
            isMainModalClass={false}
          >
            <PathPlayback
              selectedPath={selectedPath}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              pathRef={pathRef}
            />
          </Modal>
        ) : (
          openPathToolModal[key] &&
          key !== "path" &&
          key !== "pathAnalysis" &&
          key !== "downloadXLS" && (
            <Modal
              headerTitle={MAP_PATHTOOLS_OPTIONS_TO_TITLE[key]}
              handleClose={() => {
                key === "futurePrediction" && handleCloseFuturePathPrediction();
                handleClose(key);
              }}
              onClickMinimize={() => {
                dispatch(
                  setMinimizePathToolModal({
                    ...minimizePathToolModal,
                    [key]: true,
                  })
                );
              }}
              onMinimizeClick={minimizePathToolModal[key]}
              isMainModalClass={false}
            >
              {GET_SHIP_TOOLS_COMPONENT[key]({
                shipDetailTabValue,
                shipDetailsTabs,
                bunkeringValue,
              })}
            </Modal>
          )
        )
      )}
    </>
  );
};
export default ShipTools;

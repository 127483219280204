// SnackbarComponent.tsx
import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSnackbar } from '../../context/SnackbarContext';

const SnackBar = () => {
  const { message, severity, isOpen, setIsSnackBarOpen } = useSnackbar();

  const handleClose = () => {
    setIsSnackBarOpen(false);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;

import { DeckProps } from "@deck.gl/core/typed";
import { MapboxOverlay } from "@deck.gl/mapbox/typed";
import { useControl } from "react-map-gl";
import { useSelector } from "react-redux";
import {
  COORDINATE_SYSTEM,
  _GlobeView as GlobeView,
  MapView,
} from "@deck.gl/core/typed";
import { RootState } from "../../../redux/store";
import { Dispatch, SetStateAction, useState } from "react";
import moment from "moment";
import { PathData } from "../MainMap";

function DeckGLOverlay(props: any) {
  const deck = useControl<any>(() => new MapboxOverlay(props));
  //@ts-ignore
  if (deck?._deck !== undefined && deck?._deck?.canvas !== null) {
    props.setDeckCanvas(deck);
  }
  deck.setProps(props);

  return null;
}

interface IRenderShips {
  deckGlLayers: any;
  setDeckCanvas: any;
  setShowCursor: any;
  setClickedCoordinates: Dispatch<SetStateAction<{ x: number; y: number }>>;
  setSelectedMapObject: Dispatch<any>;
  setCurrentUserHoverCoordinates: Dispatch<SetStateAction<[number, number]>>;
  shipPaths: {
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
    };
  };
}

const RenderShips = (props: IRenderShips) => {
  const {
    deckGlLayers,
    setDeckCanvas,
    setShowCursor,
    setCurrentUserHoverCoordinates,
  } = props;
  const { detections } = useSelector((state: RootState) => state.theia);

  const handleMapClick = (event: { object: any; x: any; y: any }) => {
    if (event.object !== undefined) {
      props.setSelectedMapObject(event.object);
    } else {
      props.setSelectedMapObject(null);
    }
    props.setClickedCoordinates({ x: event.x, y: event.y });
  };

  const [hoverInfo, setHoverInfo] = useState<{
    x: number;
    y: number;
    longitude: number;
    latitude: number;
    timestamp: string;
  } | null>(null);

  return (
    <div style={{ position: "relative" }}>
      {detections && (
        <DeckGLOverlay
          setDeckCanvas={setDeckCanvas}
          layers={deckGlLayers}
          onHover={(object: any) => {
            if (object.coordinate) {
              setCurrentUserHoverCoordinates(object.coordinate);
            }
            // add all the geojson layers, maybe some code orginatization as well?
            if (object.layer) {
              const layerId = object.layer.id;
              if (
                layerId === "selectedShips" ||
                layerId === "light" ||
                layerId === "unattributed" ||
                layerId === "attributed" ||
                layerId === "spoofingEvents" ||
                layerId === "opticalSTS" ||
                layerId === "AISSTS" ||
                layerId === "sanctionedShips"
              ) {
                setShowCursor(true);
              }
            } else {
              setShowCursor(false);
            }
          }}
          onClick={(object: { object: any; x: number; y: number }) => {
            handleMapClick(object);
            const pathAnalysis =
              props.shipPaths?.[object?.object?.synmax_ship_id]?.analysis;
            if (object.object && pathAnalysis) {
              const { timestamp, longitude, latitude } = object.object;
              setHoverInfo({
                x: object.x,
                y: object.y,
                longitude,
                latitude,
                timestamp: `${moment
                  .utc(timestamp * 1000)
                  .format("DD/MM/YYYY HH:mm:ss")} UTC`,
              });
            } else {
              setHoverInfo(null);
            }
          }}
        />
      )}
      {hoverInfo && (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            pointerEvents: "none",
            background: "white",
            padding: "5px",
            borderRadius: "5px",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
            left: hoverInfo.x + 10,
            top: hoverInfo.y,
            color: "#000",
            fontSize: "10px",
            fontFamily: "Arial, sans-serif",
            whiteSpace: "nowrap",
            transform: "translateY(-50%)",
          }}
        >
          <div style={{ lineHeight: "15px" }}>
            <strong>Longitude:</strong> {hoverInfo.longitude}
          </div>
          <div style={{ lineHeight: "15px" }}>
            <strong>Latitude:</strong> {hoverInfo.latitude}
          </div>
          <div style={{ lineHeight: "15px" }}>
            <strong>Timestamp:</strong> {hoverInfo.timestamp}
          </div>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "-5px",
              transform: "translateY(-50%)",
              width: 0,
              height: 0,
              borderTop: "5px solid transparent",
              borderBottom: "5px solid transparent",
              borderRight: "5px solid white",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RenderShips;

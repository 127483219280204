import { AttributionInterface } from "../Attribution";
import { Box } from "@mui/material";
import styles from "../Attribution.module.scss";
import CopyToClipboard from "../../../../../components/CopyToClipboard";

const AttributionList = ({
  attribution,
}: {
  attribution: AttributionInterface;
}) => {
  return (
    <Box display="grid" gridTemplateColumns="repeat(4,1fr)" rowGap="10px">
      <Box display="flex" flexDirection="column" className={styles.detailWrap}>
        <span className={styles.detailHead}>Length</span>
        <span className={styles.detailValue}>
          {attribution.length ?? "No Info"}
        </span>
      </Box>

      <Box display="flex" flexDirection="column" className={styles.detailWrap}>
        <span className={styles.detailHead}>Heading </span>
        <span className={styles.detailValue}>
          {attribution.heading ?? "No Info"}
        </span>
      </Box>

      <Box display="flex" flexDirection="column" className={styles.detailWrap}>
        <span className={styles.detailHead}>Ship Type</span>
        <span className={styles.detailValue}>
          {attribution.type ?? "No Info"}
        </span>
      </Box>

      <Box display="flex" flexDirection="column" className={styles.detailWrap}>
        <span className={styles.detailHead}>Moving </span>
        <span className={styles.detailValue}>
          {attribution.moving?.toString() ?? "No Info"}
        </span>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridColumn="span 4"
        className={styles.detailWrap}
      >
        <span className={styles.detailHead}>Attribution </span>
        <span className={styles.detailValue}>
          {attribution.id ? (
            <>
              {attribution.id}
              <CopyToClipboard data={attribution.id} />
            </>
          ) : (
            "No Info"
          )}
        </span>
      </Box>
    </Box>
  );
};

export default AttributionList;

import moment from "moment";
import { TabType } from "../layout/Header/Header.store";
import { AllProjectsType, UserType } from "./Common.store";
import { api } from "../services/api";

export const findWorkStationInTabs = (targetId: string, tabs: TabType) => {
  for (const key in tabs) {
    const item = tabs[key];
    if (item.workStationId === targetId) {
      return {
        id: item.workStationId,
        name: item.name,
        value: item.value,
      };
    }
  }
  return null;
};

export const findFolderId = (
  allProjects: AllProjectsType,
  workstationId: string
) => {
  for (const folderId in allProjects) {
    const workStations = allProjects[folderId].workStations;
    if (workStations && workstationId in workStations) {
      return folderId;
    }
  }
  return null;
};

export const getUserByEmail = async (users: UserType, userEmail: string) => {
  let targetUserId = null;
  try {
    const data = await api.get(`/users/getuserId?useremail=${userEmail}`);
    return data.data;
  } catch (error) {
    targetUserId = null;
  }
  return targetUserId;
};

export const getUserName = (users: UserType, userId: string) => {
  const user = users[userId];
  const firstName = user.firstName;
  const lastName = user.lastName;
  const email = user.email;

  return { firstName, lastName, email };
};

export const onDragStart = (e: any, workName: string, folderId?: string) => {
  e.stopPropagation();
  e.dataTransfer.setData("workId", workName);
  e.dataTransfer.setData("folderId", folderId);
};

export const findTabIdFromWorkId = (tabs: any, workId: string) => {
  for (const key in tabs) {
    const item = tabs[key];
    if (workId === item.workStationId) {
      return key;
    }
  }
  return null;
};

export const valueLabelFormat = (date: number) => {
  const p = moment.utc(date * 1000).format("MM/DD/YYYY h:mm:ss");
  return `${p} UTC`;
};

import React from "react";

const TabDetailsIcon = ({ size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7.9987L6 7.9987M14 3.9987L6 3.9987M14 11.9987L6 11.9987M3.33333 7.9987C3.33333 8.36689 3.03486 8.66536 2.66667 8.66536C2.29848 8.66536 2 8.36689 2 7.9987C2 7.63051 2.29848 7.33203 2.66667 7.33203C3.03486 7.33203 3.33333 7.63051 3.33333 7.9987ZM3.33333 3.9987C3.33333 4.36689 3.03486 4.66536 2.66667 4.66536C2.29848 4.66536 2 4.36689 2 3.9987C2 3.63051 2.29848 3.33203 2.66667 3.33203C3.03486 3.33203 3.33333 3.63051 3.33333 3.9987ZM3.33333 11.9987C3.33333 12.3669 3.03486 12.6654 2.66667 12.6654C2.29848 12.6654 2 12.3669 2 11.9987C2 11.6305 2.29848 11.332 2.66667 11.332C3.03486 11.332 3.33333 11.6305 3.33333 11.9987Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TabDetailsIcon;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { RootState } from "../../redux/store";
import {
  getAllInitialShips,
  getObjectsForShips,
  getSanctionVessels,
  getSatelliteImage,
  getShipAttributes,
  getShipInfo,
  getShipObject,
  getShipPath,
  getSimilarShips,
} from "./RenderShips/apiCalls";
import {
  changeIntervalStatus,
  setChangeSaved,
  setDetections,
} from "./Theia.store";
import MapboxRender from "./RenderShips/MapboxRender";
import DeckglRender from "./RenderShips/DeckglRender";
import { api } from "../../services/api";
import { changeLoaderState, setMapIntervalID } from "../../redux/globalState.store";
import { updateAllProjects } from "../../common/Common.store";
import {
  setIsDashboard,
  setIsDashboardClick,
  setIsSaveMap,
  setPrevTabId,
} from "../../layout/Header/Header.store";
import {
  addFileToStorageData,
  setIsDeleteTabUpdated,
  setIsFileAdded,
  setIsSwitchTabUpdated,
} from "./MainMap.store";
import { IMapState } from "./WorkStations";
import { filterShips, getDeckGLLayers } from "./RenderShips/deckglLayers";
import FilterArea, {
  ICurrentEventTypeFilterOptions,
} from "./MapComponents/FilterArea/FilterArea";
import RightNavigationBar from "./RightNavigationBar/RightNavigationBar";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
} from "@mui/material";
import {
  CalendarTodayOutlined,
  ContentCopyOutlined,
  EditOutlined,
  LanguageOutlined,
  MoreHorizOutlined,
  ReplayOutlined,
  SaveOutlined,
  ShareOutlined,
  VisibilityOutlined,
  SignalCellularAltOutlined,
  Publish,
  FileDownload,
} from "@mui/icons-material";
import Sidebar from "../../components/Sidebar";
import Drawer from "../../components/Drawer";
import WorldNews from "../../assets/icons/WorldNews";
import Shapes from "../../assets/icons/Shapes";
import TrendingPorts from "../../assets/icons/TrendingPorts";
import TrendingShip from "../../assets/icons/TrendingShip";
import Satellite from "../../assets/icons/Satellite";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'

import MyShips from "./SidebarComponents/MyShips";
import ShipDetails from "./ShipDetails/ShipDetails";
import MainSearch from "./Search/MainSearch";
import axios from "axios";
import ShipTools from "./ShipTools/ShipTools";
import PolygonShipAnalysis from "./PolygonShipAnalysis/PolygonShipAnalysis";
import { ShipSvgType } from "../../utils/ShipSvgs";
import PolygonShipAnalysisMinimize from "./PolygonShipAnalysis/PolygonShipAnalysisMinimize/PolygonShipAnalysisMinimize";
import EventsIcon from "../../assets/icons/Events";
import MyEvents from "./SidebarComponents/MyEvents";
import {
  INITIAL_STATE_FOR_FILTER_PROVIDER,
  SHIP_FILTER_PROVIDER,
  SHIP_SERVICE_PROVIDER_LIST,
} from "../../utils/Constants";
import styles from "./MainMap.module.scss";
import MainMapHeaderShipCounts from "./MainMapShipCounts";
import moment from "moment";
import DropPin from "./MapComponents/DropPIn/DropPin";
import * as XLSX from "xlsx";

const REACT_APP_BASE_URL = process.env.REACT_APP_ENV === 'dev'
    ? 'https://theia-web-api-j75nm34yfa-uc.a.run.app/'
    : 'https://theia-web-api-y5rlib2gfa-uc.a.run.app';
console.log(`REACT_APP_BASE_URL: ${REACT_APP_BASE_URL}`)


export const MapContext = React.createContext({} as any);

export interface ShipDetailsInterFace {
  synmax_ship_id: string;
  name?: string;
  synmax_ship_id_1?: string;
  synmax_ship_id_2?: string;
  object_id_1?: number;
  object_id_2?: number;
  type?: string;
  active?: boolean;
  aisclass?: string;
  call_sign?: string;
  commercialowner?: string;
  count?: number;
  dimensions_a?: number;
  dimensions_b?: number;
  dimensions_c?: number;
  dimensions_d?: number;
  effectivecontrol?: string;
  financialowner?: string;
  flag?: string;
  imo?: number;
  last_query?: number;
  length?: number;
  mmsi?: number;
  primaryoperator?: string;
  registeredowner?: string;
  ship_subtype?: string;
  ship_type?: string;
  shiptype_enhanced?: string;
  speeds?: number[];
  technicalmanager?: string;
  typename?: string;
  width?: number;
  object_id?: number;
  attribution?: string;
  source_id?: string;
  provider?: string;
  status?: string;
  acquired?: number;
  dark_time?: number;
  dark?: boolean;
  spoofing?: boolean;
  longitude?: number;
  latitude?: number;
  heading?: number;
  frontend_rotation?: number;
  moving?: any;
  sts?: any;
  ship1_status?: string;
  ship2_status?: string;
  isSanctionedShip?: boolean;
  detectionData?: any;
  dynamic?: DynamicData[];
  sanctions_list?: any;
  isOpticalBunkering?: any;
  isShip1?: any;
  isObservationShip?: boolean;
  isPathShip?: boolean;
  pathColor?: string;
  parentLatitude?: any;
  parentLongitude?: any;
  bunkering_id?: string;
}

export enum RightSideNavigationSelectedFilter {
  Ships = "ships",
  Layers = "layers",
}

export interface AllDetailsTabs extends ShipDetailsInterFace {
  tabType: "ship" | "port" | "satelliteImage" | "event"; // will update later
}
export type BunkeringInterface = AllDetailsTabs & {
  [key: string]: AllDetailsTabs;
};

export interface ShipImages {
  object_id: string;
  image: string;
  longitude?: number;
  latitude?: number;
  acquired?: string;
  id: string;
  name: string;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}
export interface ShipCountsInView {
  total: number;
  ais: number;
  light: number;
  dark: number;
  unattributed: number;
  spoofing: number;
  bunkering: number;
  sanctioned: number;
}

export interface PathData {
  synmax_ship_id: string;
  timestamp: number;
  heading: number | null;
  speed: number;
  longitude: number;
  latitude: number;
}

export const updateActiveStatus = (
  object: { [key: string]: ShipDetailsInterFace | BunkeringInterface },
  activeShipId: string
) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = {
      ...object[key],
      active: key === activeShipId,
    };
    return acc;
  }, {} as { [key: string]: ShipDetailsInterFace | BunkeringInterface });
};

export interface LayerToggleSwitches {
  territories: boolean;
  ports: false;
  infrastructure: false;
  submarineCablesVisible: false;
  coverage: false;
  highRisk: false;
}

export interface FilterLayer {
  id: string;
  label: string;
  value: any;
  onChange: () => void;
}

interface DynamicData {
  synmax_ship_id: string;
  timestamp: number;
  mmsi?: number;
  imo?: number;
  name?: string;
  call_sign?: string;
  destination?: string;
  eta?: number;
  length?: number;
  width?: number;
  draught?: number;
}

export interface ShipDetail {
  dynamic: DynamicData[];
  [key: string]: string | number | DynamicData[] | undefined;
}

export interface ObjectData {
  object_id: number;
  attribution: string;
  source_id?: string;
  provider?: string;
  status?: string;
  acquired?: number;
  dark_time?: number;
  dark?: boolean;
  spoofing?: boolean;
  longitude?: number;
  latitude?: number;
  length?: number;
  width?: number;
  heading?: number;
  ship_type?: string;
  moving?: boolean;
  sts?: boolean;
  aoi?: string | null;
}

const getShipStatusByType = (type: string): string => {
  return type.toLowerCase() === "light"
    ? "blue"
    : type.toLowerCase() === "dark"
    ? "orange"
    : type.toLowerCase() === "ais"
    ? "ais"
    : "red";
};

const MainMap = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(4);
  const [mapLatLng, setMapLatLng] = useState({ lat: 51, lng: 2 });
  const [searchIsExpanded, setSearchIsExpanded] = useState<boolean>(false);
  const [showGlobeView, setShowGlobeView] = useState(false);
  const [currentEventTypeFilterOptions, setCurrentEventTypeFilterOptions] =
    useState<ICurrentEventTypeFilterOptions>({});
  const [mapSnapshot, setMapSnapshot] = useState<any>("");
  const [deckCanvas, setDeckCanvas] = useState<any>("");
  const [selectedOption, setselectedOption] = useState("");
  const [showCursor, setShowCursor] = useState(false);
  const [currentCoveraData, setCurrentCoverageData] = useState<any>(null);
  const token = localStorage.getItem("token");
  const [selectedShape, setSelectedShape] = useState<ShipSvgType | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(new Date().getTime() - 60 * 60 * 1000),
    endDate: new Date(),
  });
  // polygon features
  const [features, setFeatures] = useState({ data: {}, count: 0 }) as any;
  const [showShips, setShowShips] = useState(true);
  const [showSimilarShips, setShowSimilarShips] = useState(true);

  const [filteredDetection, setFilteredDetection] = useState<any>({
    filteredDarkShips: [],
    filteredLightShips: [],
    filteredSpoofingShips: [],
    filteredUnattributedShips: [],
  });
  const [intialStateForFilterProvider, setIntialStateForFilterProvider] =
    useState<any>();
  const [shipCountsInCurrentView, setShipCountsInCurrentView] =
    useState<ShipCountsInView>({
      total: 0,
      ais: 0,
      light: 0,
      dark: 0,
      unattributed: 0,
      spoofing: 0,
      bunkering: 0,
      sanctioned: 0,
    });
  const [droppedPins, setDroppedPins] = useState<
    {
      lat: number;
      lng: number;
      title: string;
    }[]
  >([]);
  const {
    slider: sliderforPolygonShipAnalysis,
    layers: layerforPolygonShipAnalysis,
    isOpenPolygonShipAnalysisModal,
  } = useSelector((state: RootState) => state.polygonShipAnalysis);
  const [shipCountsAreShown, setShipCountsAreShown] = useState<boolean>(false);
  const [showDeckglPolygons, setShowDeckglPolygons] = useState(true);
  const [layerToggleSwitches, setLayerToggleSwitches] =
    useState<LayerToggleSwitches>({
      territories: false,
      ports: false,
      infrastructure: false,
      submarineCablesVisible: false,
      coverage: false,
      highRisk: false,
    });
  const [liveAISShips, setLiveAISShips] = useState([]);
  const [isLiveShipLoading, setIsLiveShipLoading] = useState(false);
  const [date, setDate] = useState<any>(null);

  useEffect(() => {
    getLiveAISShips();
  }, [zoomLevel, mapLatLng]);

  const getLiveAISShips = async () => {
    if (
      date &&
      moment(date.$d).format("YYYY-MM-DD") ===
        moment(new Date()).format("YYYY-MM-DD") &&
      currentEventTypeFilterOptions.AIS.checked
    ) {
      if (zoomLevel > 9 && !isLiveShipLoading) {
        setIsLiveShipLoading(true);
        dispatch(changeLoaderState(true));
        const { _sw: _southWest, _ne: _northEast } = mapRef.current
          .getMap()
          .getBounds();
        const { lat: lat1, lng: lng1 } = _northEast;
        const { lat: lat2, lng: lng2 } = _southWest;
        const coordinates = [
          [
            [lng2, lat1],
            [lng1, lat1],
            [lng1, lat2],
            [lng2, lat2],
            [lng2, lat1],
          ],
        ];

        const liveShipsRes = await api.post(
          "/ship-tools/live-area",
          coordinates
        );
        setLiveAISShips(liveShipsRes.data);
        dispatch(changeLoaderState(false));
        setIsLiveShipLoading(false);
      }
    }
  };

  const setSwitchValue = (key: keyof LayerToggleSwitches) => {
    setLayerToggleSwitches((prevState: LayerToggleSwitches) => {
      return {
        ...prevState,
        [key]: !prevState[key],
      };
    });
  };

  const filterLayers: FilterLayer[] = [
    {
      id: "layer1",
      label: "Territories",
      value: layerToggleSwitches.territories,
      onChange: () => setSwitchValue("territories"),
    },
    {
      id: "layer2",
      label: "Ports",
      value: layerToggleSwitches.ports,
      onChange: () => setSwitchValue("ports"),
    },
    {
      id: "layer3",
      label: "Infrastructure",
      value: layerToggleSwitches.infrastructure,
      onChange: () => {
        setSwitchValue("infrastructure");
      },
    },
    {
      id: "layer4",
      label: "Submarine Cables",
      value: layerToggleSwitches.submarineCablesVisible,
      onChange: () => setSwitchValue("submarineCablesVisible"),
    },
    {
      id: "layer5",
      label: "Coverage",
      value: layerToggleSwitches.coverage,
      onChange: () => setSwitchValue("coverage"),
    },
    {
      id: "layer6",
      label: "High Risk Areas",
      value: layerToggleSwitches.highRisk,
      onChange: () => setSwitchValue("highRisk"),
    },
  ];

  function handleSearchExpand(searchIsExpanded: boolean): void {
    setSearchIsExpanded(searchIsExpanded);
    if (searchIsExpanded) {
      setShipCountsAreShown(false);
    }
  }

  const dispatch = useDispatch<ThunkDispatch<RootState, void, any>>();
  const {
    detections,
    user,
    intervalIsSet,
    changesSaved,
    spoofingEvents,
    AISSTS,
    opticalSTS,
    sanctionedShips,
  } = useSelector((state: RootState) => state.theia);

  const {
    tabs,
    selectedTabId,
    isSaveMap,
    isPrevTabId,
    prevTabId,
    isDashboardClick,
    isTabSwitch,
    isTabDelete,
  } = useSelector((state: RootState) => state.header);
  const { mapIntervalId } = useSelector(
    (state: RootState) => state.globalTheiaState
  );
  const {
    isFileAdded,
    fileData,
    loading: saveFileLoading,
    minimizePolygonModal,
  } = useSelector((state: RootState) => state.mainMap);
  const { allProjects } = useSelector((state: RootState) => state.common);
  const { futurePathData, slider, layers } = useSelector(
    (state: RootState) => state.futurePathPrediction
  );

  const [mapViewState, setMapViewState] = useState({
    latitude: 51.47,
    longitude: 0.45,
    zoom: 4,
  });
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSelectedFilter, setCurrentSelectedFilter] =
    useState<RightSideNavigationSelectedFilter | null>(null);

  useEffect(() => {
    const abortController = new AbortController();

    const getMapCoverage = async () => {
      try {
        const res = await axios.get(
          `${REACT_APP_BASE_URL}/v1/images/coverage?date=${date?.format(
            "YYYY-MM-DD"
          )}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            signal: abortController.signal,
          }
        );
        const geojsonData = {
          type: "FeatureCollection",
          features: res.data.coverage.map((feature: any) => ({
            type: "Feature",
            properties: { ...feature },
            geometry: feature.geometry,
          })),
        };

        setCurrentCoverageData(geojsonData);
      } catch (error: any) {
        console.log("error:", error?.message);
      }
    };
    if (date) {
      getMapCoverage();
    }

    return () => {
      abortController.abort();
    };
  }, [date]);

  const menuRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<any>(null);
  const [collapsed, setSidebarCollapsed] = useState<boolean>(true);
  const [shipCollapsed, setShipCollapsed] = useState<boolean>(true);
  const [active, setActive] = useState<number>(0);
  const [selectedShip, setSelectedShip] = useState<{
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  }>({});
  const [selectedEvent, setSelectedEvent] = useState<{
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  }>({});
  const [shipDetailTabValue, setShipDetailTabValue] = useState<string | null>(
    null
  );
  const [detailsTabs, setDetailsTabs] = useState<AllDetailsTabs[]>([]);
  const [satelliteImageTimeline, setSatelliteImageTimeline] = useState<{
    [key: string]: ShipImages[];
  }>({});
  const [isSatelliteImageLoading, setIsSatelliteImageLoading] =
    useState<boolean>(false);
  const [bunkeringValue, setBunkeringValue] = useState<string>("1");
  const [workStationMode, setWorkStationMode] = useState("");
  const [sanctionVessels, setSanctionVessels] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showTemporaryCircle, setShowTemporaryCircle] = useState(false);
  const uploadInputRef = useRef<any>(null);
  const [layersForFuturePathPrediction, setLayersForFuturePathPrediction] =
    useState<any>([]);
  // TODO: fix any type
  const [
    selectedShipEstimatedaLocationPath,
    setSelectedShipEstimatedaLocationPath,
  ] = useState<any>([]);
  const [estimatedLocationNotFound, setEstimatedLocationNotFound] =
    useState<boolean>(false);

  const [
    coordinatesForEstimatedLocationInfo,
    setCoordinatesForEstimatedLocationInfo,
  ] = useState<[number, number] | null>(null);

  const handleSetCoordinatesForEstimatedLocationInfo = (
    coordinatesForInfo: [[number, number], [number, number]]
  ): void => {
    const midPontLattitude =
      (coordinatesForInfo[0][0] + coordinatesForInfo[1][0]) / 2;
    const midPontLongtitude =
      (coordinatesForInfo[0][1] + coordinatesForInfo[1][1]) / 2;

    setCoordinatesForEstimatedLocationInfo([
      midPontLattitude,
      midPontLongtitude,
    ]);
  };

  const [
    selectedShipEstimatedLoactionGEOJSON,
    setSelectedShipEstimatedLocationGEOJSON,
  ] = useState<{ startDate: string; data: any }[]>([]);

  const [
    selectedShipEstimatedLoactionLayer,
    setSelectedShipEstimatedLocationLayer,
  ] = useState(null);

  useEffect(() => {
    if (
      selectedShipEstimatedaLocationPath.length > 0 &&
      selectedShipEstimatedLoactionLayer !== null
    ) {
      handleSetCoordinatesForEstimatedLocationInfo(
        selectedShipEstimatedaLocationPath
      );
    }
  }, [selectedShipEstimatedaLocationPath, selectedShipEstimatedLoactionLayer]);

  const handleEstmatedShipLayerSelect = (date: string) => {
    const selcetedShipLayer = selectedShipEstimatedLoactionGEOJSON.find(
      (layerData: any) =>
        moment(layerData?.startDate).format("MM/DD/YYYY") === date
    );
    // TODO fix starting point. This is currently using the most outward coordinates of the polygon, Need to add additional parsing to calculate the
    // center point of the coordinates with the highest confidence score
    let startingPoint =
      selcetedShipLayer?.data?.features[0].geometry.coordinates[0][0];

    if (selcetedShipLayer?.data?.features[0].geometry.type === "MultiPolygon") {
      startingPoint =
        selcetedShipLayer?.data?.features[0].geometry.coordinates[0][0][0];
    }

    let shipforLatLng =
      shipDetailTabValue && (selectedShip[shipDetailTabValue] as any);

    if (shipDetailTabValue && selectedShip[shipDetailTabValue] === undefined) {
      shipforLatLng = selectedEvent[shipDetailTabValue];
    }
    if (
      shipDetailTabValue &&
      startingPoint !== undefined &&
      startingPoint[0] !== undefined &&
      startingPoint[1] !== undefined
    ) {
      setSelectedShipEstimatedaLocationPath([
        [startingPoint[0], startingPoint[1]],
        [shipforLatLng.longitude, shipforLatLng.latitude],
      ]);
    }

    if (selcetedShipLayer === null) {
      setEstimatedLocationNotFound(false);
    }
    console.log(selcetedShipLayer?.data, ":final data point");
    setSelectedShipEstimatedLocationLayer(selcetedShipLayer?.data);
  };

  const [shipPaths, setShipPaths] = useState<{
    [key: string]: {
      path: PathData[];
      color: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
    };
  }>({});
  const [sliderValue, setSliderValue] = useState<number>(0);
  const pathRef = useRef<number | null>(null);
  const [showDropPinDialog, setshowDropPinDialog] = useState(false);
  const drawRef = useRef(null) as any;
  const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/light-v11");
  const [shipDetails, setShipDetails] = useState<{ [key: string]: ShipDetail }>(
    {}
  );
  const [extendedPathLoading, setExtendedPathLoading] = useState(false);
  const [shipObjects, setShipObjects] = useState<{
    [key: string]: {
      objects: ObjectData[];
      isOpticalShip: boolean;
      isEvent: boolean;
      parentShipObject: number;
    };
  }>({});
  const [similarShips, setSimilarShips] = useState<{
    [key: string]: ShipDetailsInterFace[];
  }>({});
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const { analyzeShape } = useSelector(
    (state: RootState) => state.polygonShipAnalysis
  );

  const [lastSelectedOption, setLastSelectedOption] = useState(
    analyzeShape.length > 0 ? analyzeShape.at(-1).value : ""
  );

  useEffect(() => {
    if (analyzeShape.length > 0) {
      setLastSelectedOption(analyzeShape.at(-1).value);
    }
  }, [analyzeShape]);

  const area = features?.data?.[lastSelectedOption]?.geometry;

  useEffect(() => {
    console.log(selectedEvent, ":selectedEvent");
  }, [selectedEvent]);

  useEffect(() => {
    console.log(selectedShip, ":selectedShip");
  }, [selectedShip]);

  useEffect(() => {
    if (allProjects && tabs && selectedTabId && tabs[selectedTabId].folderId) {
      const mode =
        allProjects[tabs[selectedTabId].folderId].workStations[
          tabs[selectedTabId].workStationId
        ].mode;
      setWorkStationMode(mode);
    }
  }, [allProjects, tabs, selectedTabId]);

  useEffect(() => {
    setDetailsTabs((prevDetailsTabs: any[]) => {
      const satelliteTimelineTabs = Object.keys(satelliteImageTimeline).length
        ? Object.values(satelliteImageTimeline).map(
            (satellite: ShipImages[]) => ({
              synmax_ship_id: satellite[0]?.id,
              name: satellite[0]?.name,
              tabType: "satelliteImage" as const,
            })
          )
        : [];
      const shipTabs = Object.keys(selectedShip).length
        ? Object.values(selectedShip).map((ship) => ({
            ...ship,
            tabType: "ship" as const,
          }))
        : [];

      const eventTabs = Object.keys(selectedEvent).length
        ? Object.values(selectedEvent).map((event) => ({
            ...event,
            tabType: "event" as const,
          }))
        : [];
      return [...shipTabs, ...eventTabs, ...satelliteTimelineTabs];
    });
    dispatch(setChangeSaved(false));
  }, [selectedShip, satelliteImageTimeline, selectedEvent]);

  //Initially default selected Tab value
  useEffect(() => {
    const fetchInitialShipDetails = async () => {
      if (shipDetailTabValue === null && detailsTabs.length > 0) {
        const firstTab = detailsTabs[0];
        const firstTabKey = detailsTabs[0].synmax_ship_id;

        if (
          detailsTabs[0].tabType === "ship" ||
          detailsTabs[0].tabType === "event"
        ) {
          setShipDetailTabValue(firstTabKey);
          const startDate = shipPaths?.[firstTabKey]?.dateRange?.startDate;
          const endDate = shipPaths?.[firstTabKey]?.dateRange?.endDate;
          const color = shipPaths?.[firstTabKey]?.color;
          const isEvent = isEventTab(firstTabKey);
          if (isEvent) {
            await fetchAndSetEventDetails(firstTabKey);
            await fetchAndSetShipPath(
              [firstTabKey],
              startDate,
              endDate,
              color,
              true
            );
          } else {
            const shipObject = {
              synmax_ship_id: firstTabKey,
              object_id: firstTab?.object_id,
              type: firstTab?.type,
              lat: firstTab?.latitude,
              lng: firstTab?.longitude,
              synmax_ship_id_1: firstTab?.synmax_ship_id_1,
              synmax_ship_id_2: firstTab?.synmax_ship_id_2,
              object_id_1: firstTab?.object_id_1,
              object_id_2: firstTab?.object_id_2,
              ship1_status: firstTab?.ship1_status,
              ship2_status: firstTab?.ship2_status,
              name: firstTab?.name,
              pathColor: firstTab?.pathColor,
            };
            await fetchAndSetShipDetails(shipObject);
            const shipTypesToInclude = ["light", "attributed", "sanctioned"];
            if (
              shipObject.type === "opticalSTS" ||
              shipObject.type === "AISSTS"
            ) {
              if (
                shipObject.synmax_ship_id_1 &&
                shipPaths[shipObject.synmax_ship_id_1]
              ) {
                await fetchAndSetShipPath(
                  [shipObject.synmax_ship_id_1],
                  shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange
                    ?.startDate,
                  shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.endDate,
                  shipPaths?.[shipObject.synmax_ship_id_1]?.color,
                  false,
                  true,
                  shipObject?.object_id_1
                );
              }
              if (
                shipObject.synmax_ship_id_2 &&
                shipPaths[shipObject.synmax_ship_id_2]
              ) {
                await fetchAndSetShipPath(
                  [shipObject.synmax_ship_id_2],
                  shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange
                    ?.startDate,
                  shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.endDate,
                  shipPaths?.[shipObject.synmax_ship_id_2]?.color,
                  false,
                  true,
                  shipObject?.object_id_2
                );
              }
            } else if (
              shipObject?.type &&
              shipTypesToInclude.includes(shipObject?.type)
            ) {
              await fetchAndSetShipPath(
                [firstTabKey],
                startDate,
                endDate,
                color
              );
            }
          }
        }
      }
    };
    fetchInitialShipDetails();
  }, [detailsTabs]);

  const handleTableNameClick = (
    shipId: string,
    type: string,
    tabIndex?: string
  ) => {
    onShipClick();
    setShipDetailTabValue(shipId);
    if (tabIndex) {
      setBunkeringValue(() => tabIndex);
    }
    setSelectedShip((prevSelectedShip) => ({
      ...prevSelectedShip,
      ...updateActiveStatus(prevSelectedShip, shipId),
    }));
    setSelectedEvent((prevSelectedEvents) => ({
      ...prevSelectedEvents,
      ...updateActiveStatus(prevSelectedEvents, shipId),
    }));
  };

  const getEventDetails = (
    eventType: string
  ): {
    name: string;
    color: string;
  } => {
    const eventData: {
      [key: string]: {
        name: string;
        color: string;
      };
    } = {
      light: {
        name: "Light",
        color: currentEventTypeFilterOptions?.light?.markerColor,
      },
      unattributed: {
        name: "Unattributed",
        color: currentEventTypeFilterOptions?.unattributed?.markerColor,
      },
      attributed: {
        name: "Dark",
        color: currentEventTypeFilterOptions?.dark?.markerColor,
      },
      dark: {
        name: "Dark",
        color: currentEventTypeFilterOptions?.dark?.markerColor,
      },
      spoofing: {
        name: "Spoofing",
        color: currentEventTypeFilterOptions?.spoofing?.markerColor,
      },
      sanctioned: {
        name: "Sanctioned",
        color: currentEventTypeFilterOptions?.sanctioned?.markerColor,
      },
      AISSTS: {
        name: "Bunkering",
        color: currentEventTypeFilterOptions?.AIS?.markerColor,
      },
      opticalSTS: {
        name: "Bunkering",
        color: currentEventTypeFilterOptions?.bunkering?.markerColor,
      },
      AIS: {
        name: "AIS",
        color: currentEventTypeFilterOptions?.AIS?.markerColor,
      },
    };

    const event = eventData[eventType];
    if (!event) return { name: "Unknown", color: "rgb(0, 0, 0)" };
    return {
      name: event.name,
      color: event.color,
    };
  };

  const myEvents: {
    [key: string]: ShipDetailsInterFace | BunkeringInterface;
  } = {
    ...selectedEvent,
    ...Object.fromEntries(
      Object.entries(selectedShip).filter(
        ([key, value]) => value.type === "opticalSTS" || value.type === "AISSTS"
      )
    ),
  };
  const menuList = [
    {
      id: "World News",
      icon: <WorldNews />,
      component: <Box sx={{ padding: "15px 5px" }}>World News</Box>,
    },
    {
      id: "Trending Ship",
      icon: <TrendingShip />,
      component: (
        <MyShips
          ships={selectedShip}
          handleTableRowClick={handleTableNameClick}
          getEventDetails={getEventDetails}
        />
      ),
    },
    {
      id: "My Ports",
      icon: <TrendingPorts />,
      component: <Box sx={{ padding: "15px 5px" }}>My Ports</Box>,
    },
    {
      id: "My Events",
      icon: <EventsIcon />,
      component: (
        <MyEvents
          events={myEvents}
          handleTableRowClick={handleTableNameClick}
          getEventDetails={getEventDetails}
        />
      ),
    },
    {
      id: "My Shapes",
      icon: <Shapes />,
      component: <Box sx={{ padding: "15px 5px" }}>My Shapes</Box>,
    },
    {
      id: "Satellite",
      icon: <Satellite />,
      component: <Box sx={{ padding: "15px 5px" }}>My Satellite Images</Box>,
    },
  ];

  const fetchAndSetShipDetails = async (myShip: {
    synmax_ship_id: string;
    object_id?: number;
    type?: string;
    lat?: number;
    lng?: number;
    synmax_ship_id_1?: string;
    synmax_ship_id_2?: string;
    object_id_1?: number;
    object_id_2?: number;
    ship1_status?: string;
    ship2_status?: string;
    name?: string;
    pathColor?: string;
  }) => {
    const {
      synmax_ship_id,
      object_id,
      type,
      lat,
      lng,
      synmax_ship_id_1,
      synmax_ship_id_2,
      object_id_1,
      object_id_2,
      ship1_status,
      ship2_status,
    } = myShip || {};
    const existingShipData = selectedShip[synmax_ship_id];

    if (existingShipData) {
      if (
        existingShipData.type === "opticalSTS" ||
        existingShipData.type === "AISSTS"
      ) {
        for (const key in existingShipData) {
          const data = existingShipData as Record<string, any>;
          if (
            data[key] &&
            typeof data[key] === "object" &&
            Object.keys(data[key]).length > 10
          ) {
            updateSelectedState(synmax_ship_id);
            return;
          }
        }
      } else {
        if (Object.keys(existingShipData).length > 10) {
          updateSelectedState(synmax_ship_id);
          return;
        }
      }
    }


    const updatedShip: {
      [key: string]: ShipDetailsInterFace | BunkeringInterface;
    } = {};

    if (type === "AISSTS") {
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        const shipDetails = await getShipInfo(
          [synmax_ship_id_1, synmax_ship_id_2],
          null
        );
        setShipDetails((prev) => ({
          ...prev,
          ...shipDetails,
        }));
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          latitude: lat,
          longitude: lng,
          ship1_status: "AIS",
          ship2_status: "AIS",
          [synmax_ship_id_1]: {
            ...shipDetails[synmax_ship_id_1],
            type,
          },
          [synmax_ship_id_2]: {
            ...shipDetails[synmax_ship_id_2],
            type,
          },
        };
      }
    } else if (type === "opticalSTS") {
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        const ship1Attributes = await getShipAttributes(object_id_1 as number);
        const ship2Attributes = await getShipAttributes(object_id_2 as number);
        const ship1Details =
          !synmax_ship_id_1.includes("unattributed") &&
          (await getShipInfo([synmax_ship_id_1], null));
        setShipDetails((prev) => ({
          ...prev,
          ...ship1Details,
        }));
        const ship2Details =
          !synmax_ship_id_2.includes("unattributed") &&
          (await getShipInfo([synmax_ship_id_2], null));
        setShipDetails((prev) => ({
          ...prev,
          ...ship2Details,
        }));
        updatedShip[synmax_ship_id] = {
          ...ship1Attributes,
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          ship1_status,
          ship2_status,
          object_id_1,
          object_id_2,
          frontend_rotation:
            ship1Attributes?.heading || ship2Attributes?.heading || 0,
          [synmax_ship_id_1]: {
            ...ship1Attributes,
            ...ship1Details[synmax_ship_id_1],
            type,
          },
          [synmax_ship_id_2]: {
            ...ship2Attributes,
            ...ship2Details[synmax_ship_id_2],
            type,
          },
        };
      }
    } else {
      const shipDetails =
        !synmax_ship_id.includes("unattributed") &&
        (await getShipInfo([synmax_ship_id], null));
      setShipDetails((prev) => ({
        ...prev,
        ...shipDetails,
      }));
      const additionalData = object_id && (await getShipAttributes(object_id));

      updatedShip[synmax_ship_id] = {
        ...shipDetails[synmax_ship_id],
        ...(additionalData && Object.keys(additionalData).length > 0
          ? additionalData
          : {}),
      };
    }
    setSelectedShip((prevShips) => ({
      ...updateActiveStatus(prevShips, synmax_ship_id),
      [synmax_ship_id]: {
        ...prevShips[synmax_ship_id],
        ...updatedShip[synmax_ship_id],
        active: true,
      },
    }));

    setSelectedEvent((prevSelectedEvents) => ({
      ...prevSelectedEvents,
      ...updateActiveStatus(prevSelectedEvents, synmax_ship_id),
    }));
  };

  const fetchAndSetEventDetails = async (synmaxShipId: string) => {
    const existingShipData = selectedEvent[synmaxShipId];
    if (existingShipData && Object.keys(existingShipData).length > 10) {
      updateSelectedState(synmaxShipId);
      return;
    }
    if (synmaxShipId.startsWith("sat:")) {
      const name = satelliteImageTimeline?.[synmaxShipId]?.[0]?.name || "";
      const id = synmaxShipId.slice(4);
      await fetchSatelliteImages(id, name);
      return;
    }
    const shipDetails = await getShipInfo([synmaxShipId], null);
    setShipDetails((prev) => ({
      ...prev,
      ...shipDetails,
    }));
    setSelectedEvent((prevSelected) => ({
      ...updateActiveStatus(prevSelected, synmaxShipId),
      [synmaxShipId]: {
        ...prevSelected[synmaxShipId],
        ...shipDetails[synmaxShipId],
        active: true,
      },
    }));

    setSelectedShip((prevSelected) => ({
      ...updateActiveStatus(prevSelected, synmaxShipId),
    }));
  };

  const fetchAndSetShipPath = async (
    shipIds: string[],
    start: string,
    end: string,
    color: string,
    isEventShip?: boolean,
    isOpticalShip?: boolean,
    objectId?: number
  ) => {
    const shipIdsToFetch = shipIds
    .filter((id) => {
      const existingPathData = shipPaths[id];
      return !(existingPathData && existingPathData.path && existingPathData.path.length > 0);
    })
    .filter((id) => !id.includes("unattributed"));

    if (shipIdsToFetch.length > 0) {
      const allShipPath: PathData[] = await getShipPath(
        shipIdsToFetch,
        start,
        end
      );
      setShipPaths((prevShipPaths) => {
        const updatedShipPaths = { ...prevShipPaths };
        allShipPath.forEach((data) => {
          if (!updatedShipPaths[data.synmax_ship_id]) {
            updatedShipPaths[data.synmax_ship_id] = {
              path: [],
              color,
              analysis: false,
              dateRange: { startDate: start, endDate: end },
            };
          }
          updatedShipPaths[data.synmax_ship_id].path.push(data);
        });
        return updatedShipPaths;
      });
      const pathObjects = await getObjectsForShips(shipIds, start, end);
      setShipObjects((prev) => ({
        ...prev,
        [shipIds[0]]: {
          ...prev[shipIds[0]],
          objects: pathObjects,
          isOpticalShip: isOpticalShip ? true : false,
          isEvent: isEventShip ? true : false,
          parentShipObject: objectId ? +objectId : 0,
        },
      }));
    }
  };

  const updateSelectedState = (synmaxShipId: string) => {
    setSelectedShip((prevSelectedShip) => ({
      ...updateActiveStatus(prevSelectedShip, synmaxShipId),
    }));
    setSelectedEvent((prevSelectedEvents) => ({
      ...updateActiveStatus(prevSelectedEvents, synmaxShipId),
    }));
  };

  const isEventTab = (synmax_ship_id: string) =>
    Object.keys(selectedEvent).includes(synmax_ship_id);

  const handleShipDetailsTabChange = async (
    event?: React.SyntheticEvent,
    newValue?: string
  ) => {
    if(!newValue) return;
    setShipDetailTabValue(newValue);
    setBunkeringValue("1");
    if (newValue.startsWith("sat:")) {
      if (
        satelliteImageTimeline.hasOwnProperty(newValue) &&
        satelliteImageTimeline[newValue].length > 1
      )
        return;
      const name = satelliteImageTimeline?.[newValue]?.[0]?.name || "";
      const id = newValue.slice(4);
      await fetchSatelliteImages(id, name);
      return;
    }
// check here if data exist then just set data
    const startDate = shipPaths?.[newValue]?.dateRange?.startDate;
    const endDate = shipPaths?.[newValue]?.dateRange?.endDate;
    const color = shipPaths?.[newValue]?.color;

    const isEvent = isEventTab(newValue);
    if (isEvent) {
      await fetchAndSetEventDetails(newValue);
      await fetchAndSetShipPath([newValue], startDate, endDate, color, true);
    } else {
      const selectedTab = selectedShip[newValue];
      const shipObject = {
        synmax_ship_id: newValue,
        object_id: selectedTab?.object_id,
        type: selectedTab?.type,
        lat: selectedTab?.latitude,
        lng: selectedTab?.longitude,
        synmax_ship_id_1: selectedTab?.synmax_ship_id_1,
        synmax_ship_id_2: selectedTab?.synmax_ship_id_2,
        object_id_1: selectedTab?.object_id_1,
        object_id_2: selectedTab?.object_id_2,
        ship1_status: selectedTab?.ship1_status,
        ship2_status: selectedTab?.ship2_status,
        name: selectedTab?.name,
        pathColor: selectedTab?.pathColor,
      };
      await fetchAndSetShipDetails(shipObject);
      const shipTypesToInclude = ["light", "attributed", "sanctioned"];
      if (shipObject.type === "opticalSTS" || shipObject.type === "AISSTS") {
        if (
          shipObject.synmax_ship_id_1 &&
          shipPaths[shipObject.synmax_ship_id_1]
        ) {
          await fetchAndSetShipPath(
            [shipObject.synmax_ship_id_1],
            shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.startDate,
            shipPaths?.[shipObject.synmax_ship_id_1]?.dateRange?.endDate,
            shipPaths?.[shipObject.synmax_ship_id_1]?.color,
            false,
            true,
            shipObject?.object_id_1
          );
        }
        if (
          shipObject.synmax_ship_id_2 &&
          shipPaths[shipObject.synmax_ship_id_2]
        ) {
          await fetchAndSetShipPath(
            [shipObject.synmax_ship_id_2],
            shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.startDate,
            shipPaths?.[shipObject.synmax_ship_id_2]?.dateRange?.endDate,
            shipPaths?.[shipObject.synmax_ship_id_2]?.color,
            false,
            true,
            shipObject?.object_id_2
          );
        }
      } else if (
        shipObject?.type &&
        shipTypesToInclude.includes(shipObject?.type)
      ) {
        await fetchAndSetShipPath([newValue], startDate, endDate, color);
      }
    }
  };

  const fetchSatelliteImages = async (id: string, name: string) => {
    setIsSatelliteImageLoading(true);
    let isDataSet = false;
    try {
      const shipObjects = await getObjectsForShips([id]);
      if (!Array.isArray(shipObjects)) {
        console.error(
          "Expected shipObjects to be an array, but got:",
          shipObjects
        );
        setIsSatelliteImageLoading(false);
        return;
      }
      await Promise.all(
        shipObjects.map(async (item) => {
          const data = await getSatelliteImage(item.object_id);

          if (data && !isDataSet) {
            setIsSatelliteImageLoading(false);
            isDataSet = true;
          }
          if (data) {
            setSatelliteImageTimeline(
              (prevState: { [key: string]: ShipImages[] }) => {
                const updatedState = { ...prevState };

                updatedState[`sat:${id}`] =
                  updatedState[`sat:${id}`]?.length === 1 &&
                  updatedState[`sat:${id}`][0].image === ""
                    ? []
                    : updatedState[`sat:${id}`] || [];

                updatedState[`sat:${id}`].push({
                  object_id: item.object_id,
                  longitude: item.longitude,
                  latitude: item.latitude,
                  acquired: item.acquired,
                  image: data,
                  name,
                  id: `sat:${id}`,
                });
                return updatedState;
              }
            );
          }
        })
      );
    } catch (error) {
      console.error("Error fetching satellite images:", error);
      setIsSatelliteImageLoading(false);
    } finally {
      if (!isDataSet) {
        setIsSatelliteImageLoading(false);
      }
    }
  };

  const handleShipDetailsTabClose = async (tabId: string, tabType: string) => {
    let activeShipId: string | null = null;
    setShipDetailTabValue(null);
    const allTabIds = detailsTabs.map((tab) => tab.synmax_ship_id);
    const indexToDelete = allTabIds.indexOf(tabId);
    if (indexToDelete !== -1) {
      allTabIds.splice(indexToDelete, 1);
      if (allTabIds.length > 0) {
        activeShipId =
          indexToDelete > 0 ? allTabIds[indexToDelete - 1] : allTabIds[0];
        setShipDetailTabValue(activeShipId);
      } else {
        setShipDetailTabValue(null);
      }
    }
    if (tabId.includes("_")) {
      const shipIds = selectedShip[tabId];
      const synmax_ship_id1 = shipIds?.synmax_ship_id_1 || "";
      const synmax_ship_id2 = shipIds?.synmax_ship_id_2 || "";
      const {
        [synmax_ship_id1]: removedPath1,
        [synmax_ship_id2]: removedPath2,
        ...remainingShipPaths
      } = shipPaths;
      setShipPaths(remainingShipPaths);
    } else {
      const { [tabId]: removedShipPath, ...restShipsPath } = shipPaths;
      const { [tabId]: removedShipObject, ...restShipObjects } = shipObjects;
      setShipPaths(restShipsPath);
      setShipObjects(restShipObjects);
    }
    if (tabType === "ship") {
      const { [tabId]: removedShip, ...updatedSelectedShip } = selectedShip;
      activeShipId
        ? setSelectedShip(updateActiveStatus(updatedSelectedShip, activeShipId))
        : setSelectedShip({ ...updatedSelectedShip });
      activeShipId
        ? setSelectedEvent(updateActiveStatus(selectedEvent, activeShipId))
        : setSelectedEvent((prev) => ({ ...prev }));
    } else if (tabType === "satelliteImage") {
      const { [tabId]: removedSatellite, ...updatedSatelliteImages } =
        satelliteImageTimeline;
      setSatelliteImageTimeline({ ...updatedSatelliteImages });
    } else if (tabType === "event") {
      const { [tabId]: removedEvent, ...updatedEvents } = selectedEvent;
      activeShipId
        ? setSelectedEvent(updateActiveStatus(updatedEvents, activeShipId))
        : setSelectedEvent({ ...updatedEvents });
      activeShipId
        ? setSelectedShip(updateActiveStatus(selectedShip, activeShipId))
        : setSelectedShip((prev) => ({ ...prev }));
    }
  };

  // useEffect(() => {
  //   if (Object.keys(tabs).length > 0 && selectedTabId) {
  //     setIntialStateForFilterProvider({
  //       light: SHIP_SERVICE_PROVIDER_LIST,
  //       dark: SHIP_SERVICE_PROVIDER_LIST.slice(0, 2),
  //       unattributed: SHIP_SERVICE_PROVIDER_LIST,
  //     });
  //   }
  // }, [selectedTabId]);

  useEffect(() => {
    if (Object.keys(tabs).length > 0 && selectedTabId) {
      const { folderId, workStationId } = tabs[selectedTabId];
      let mapFiltersRedux =
        allProjects[folderId].workStations[workStationId].mapState.mapFilters;

      // mapFiltersRedux &&
      //   Object.keys(mapFiltersRedux).forEach((data) => {
      //     if (SHIP_FILTER_PROVIDER.includes(data)) {
      //       let intialStateForProvider = {
      //         ...mapFiltersRedux[data],
      //         providerFilters:
      //           data === "dark"
      //             ? { ...INITIAL_STATE_FOR_FILTER_PROVIDER, sentinel2: false }
      //             : INITIAL_STATE_FOR_FILTER_PROVIDER,
      //       };
      //       mapFiltersRedux = { ...mapFiltersRedux, intialStateForProvider };
      //     }
      //   });

      setCurrentEventTypeFilterOptions(mapFiltersRedux);
    }
  }, [selectedTabId, tabs]);

  useEffect(() => {
    const token = localStorage.getItem("token") as string;

    if (detections && !intervalIsSet) {
      console.log("setting interval");
      const intervalInstance = setInterval(function () {
        getAllInitialShips(token, setDetections, dispatch, date, false);
      }, 50000);
      dispatch(changeIntervalStatus(true));
      dispatch(setMapIntervalID(intervalInstance));
      dispatch(setChangeSaved(true));
    }
  }, [detections]);

  useEffect(() => {
    const token = localStorage.getItem("token") as string;
    mapIntervalId && clearInterval(mapIntervalId);
    dispatch(changeIntervalStatus(false));
    date && getAllInitialShips(token, setDetections, dispatch, date, true);
  }, [date]);

  useEffect(() => {
    if (selectedTabId && !prevTabId) {
      mapIntervalId && clearInterval(mapIntervalId);
      dispatch(changeIntervalStatus(false));
      dispatch(setDetections(null));
      if (Object.keys(tabs).length > 0) {
        getStateFromFirebase();
      }
    }
  }, [selectedTabId, prevTabId]);

  useEffect(() => {
    if (
      isFileAdded &&
      fileData.folderId &&
      fileData.workStationId &&
      fileData.thumbnail
    ) {
      const updatedProjects = {
        ...allProjects,
        [fileData.folderId]: {
          ...allProjects[fileData.folderId],
          workStations: {
            ...allProjects[fileData.folderId].workStations,
            [fileData.workStationId]: {
              ...allProjects[fileData.folderId].workStations[
                fileData.workStationId
              ],
              thumbnail: fileData.thumbnail,
            },
          },
        },
      };
      dispatch(updateAllProjects(updatedProjects));
      dispatch(setIsFileAdded(false));
      if (isDashboardClick) {
        dispatch(setIsDashboard(true));
        dispatch(setIsDashboardClick(false));
      }
    }
  }, [isFileAdded, allProjects, dispatch, fileData, isDashboardClick]);

  const fetchShips = async (
    myShips: {
      synmax_ship_id: string;
      object_id: number;
      type: string;
      lat?: number;
      lng?: number;
      synmax_ship_id_1?: string;
      synmax_ship_id_2?: string;
      object_id_1?: number;
      object_id_2?: number;
      ship1_status?: string;
      ship2_status?: string;
      name?: string;
      path: { startDate: string; endDate: string; color: string };
      path2?: { startDate: string; endDate: string; color: string };
      heading: number;
    }[],
    activeShipId: string | null
  ) => {
    const vesselsData = await getSanctionVessels();
    setSanctionVessels(() => [...vesselsData]);
    if (!myShips || myShips.length === 0) return;

    const activeShipInFetchList = myShips.find(
      (ship) => ship.synmax_ship_id === activeShipId
    );
    const shipTypesToInclude = ["light", "attributed", "sanctioned"];
    if (activeShipInFetchList && activeShipId) {
      await fetchAndSetShipDetails(activeShipInFetchList);
      if (
        activeShipInFetchList.type === "opticalSTS" ||
        activeShipInFetchList.type === "AISSTS"
      ) {
        if (
          activeShipInFetchList.path &&
          activeShipInFetchList.synmax_ship_id_1
        ) {
          await fetchAndSetShipPath(
            [activeShipInFetchList.synmax_ship_id_1],
            activeShipInFetchList?.path?.startDate,
            activeShipInFetchList?.path?.endDate,
            activeShipInFetchList?.path?.color,
            false,
            true,
            activeShipInFetchList?.object_id_1
          );
        }

        if (
          activeShipInFetchList?.path2 &&
          activeShipInFetchList.synmax_ship_id_2
        ) {
          await fetchAndSetShipPath(
            [activeShipInFetchList.synmax_ship_id_2],
            activeShipInFetchList?.path2?.startDate,
            activeShipInFetchList?.path2?.endDate,
            activeShipInFetchList?.path2?.color,
            true,
            false,
            activeShipInFetchList?.object_id_2
          );
        }
      } else if (
        activeShipInFetchList?.type &&
        shipTypesToInclude.includes(activeShipInFetchList?.type)
      ) {
        await fetchAndSetShipPath(
          [activeShipInFetchList.synmax_ship_id],
          activeShipInFetchList?.path?.startDate,
          activeShipInFetchList?.path?.endDate,
          activeShipInFetchList?.path?.color
        );
      }
    }

    const updatedShip: { [key: string]: any } = {};

    const shipsToFetch = myShips.filter(
      (ship) => !selectedShip.hasOwnProperty(ship.synmax_ship_id)
    );

    const attributedShips = shipsToFetch.filter(
      (ship) =>
        ship.type !== "unattributed" &&
        ship.type !== "AISSTS" &&
        ship.type !== "opticalSTS"
    );

    attributedShips.forEach((ship) => {
      const { synmax_ship_id, type, lat, lng, name, object_id, heading } = ship;
      updatedShip[synmax_ship_id] = {
        type,
        pathColor: ship?.path?.color,
        synmax_ship_id,
        ...(lat && lng ? { latitude: lat, longitude: lng } : {}),
        name,
        object_id,
        isSanctionedShip: vesselsData?.includes(synmax_ship_id),
        heading,
      };
    });

    const unattributedShips = shipsToFetch.filter(
      (ship) => ship.type === "unattributed"
    );
    unattributedShips.forEach((ship) => {
      const { synmax_ship_id, object_id, type, lat, lng, heading } = ship;
      updatedShip[synmax_ship_id] = {
        synmax_ship_id,
        object_id,
        type,
        heading,
        ...(lat && lng ? { latitude: lat, longitude: lng } : {}),
      };
    });

    const AISSTSShips = shipsToFetch.filter((ship) => ship.type === "AISSTS");
    AISSTSShips.forEach((ship) => {
      const {
        synmax_ship_id,
        synmax_ship_id_1,
        synmax_ship_id_2,
        type,
        lat,
        lng,
        name,
      } = ship;
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          latitude: lat,
          longitude: lng,
          ship1_status: "AIS",
          ship2_status: "AIS",
          [synmax_ship_id_1]: {
            type,
          },
          [synmax_ship_id_2]: {
            type,
          },
        };
      }
    });

    const opticalSTSShips = shipsToFetch.filter(
      (ship) => ship.type === "opticalSTS"
    );
    opticalSTSShips.forEach((ship) => {
      const {
        synmax_ship_id,
        synmax_ship_id_1,
        synmax_ship_id_2,
        object_id_1,
        object_id_2,
        type,
        ship1_status,
        ship2_status,
        lat,
        lng,
        heading,
      } = ship;
      if (synmax_ship_id_1 && synmax_ship_id_2) {
        updatedShip[synmax_ship_id] = {
          synmax_ship_id,
          synmax_ship_id_1,
          synmax_ship_id_2,
          type,
          ship1_status,
          ship2_status,
          object_id_1,
          object_id_2,
          latitude: lat,
          longitude: lng,
          ship1Status: getShipStatusByType(ship1_status ? ship1_status : ""),
          ship2Status: getShipStatusByType(ship2_status ? ship2_status : ""),
          frontend_rotation: heading,
          [synmax_ship_id_1]: {
            type,
          },
          [synmax_ship_id_2]: {
            type,
          },
        };
      }
    });

    setSelectedShip((prevShips) => ({
      ...prevShips,
      ...Object.keys(updatedShip).reduce<{
        [key: string]: ShipDetailsInterFace | BunkeringInterface;
      }>((acc, key) => {
        const { synmax_ship_id_1, synmax_ship_id_2, type } = updatedShip[
          key
        ] as ShipDetailsInterFace;

        acc[key] = {
          ...(prevShips[key] || {}),
          ...updatedShip[key],
          ...(synmax_ship_id_1 && {
            [synmax_ship_id_1]: {
              ...(prevShips[key]?.[
                synmax_ship_id_1 as keyof (typeof prevShips)[typeof key]
              ] || {}),
              ...updatedShip[key][synmax_ship_id_1],
            },
          }),
          ...(synmax_ship_id_2 && {
            [synmax_ship_id_2]: {
              ...(prevShips[key]?.[
                synmax_ship_id_2 as keyof (typeof prevShips)[typeof key]
              ] || {}),
              ...updatedShip[key][synmax_ship_id_2],
            },
          }),
        };
        return acc;
      }, {}),
    }));
  };

  const fetchSatellites = async (
    mySatellite: {
      synmax_ship_id: string;
      name: string;
    }[],
    activeTabId: string | null
  ) => {
    if (!mySatellite || mySatellite.length === 0) return;
    const satellitesToFetch = mySatellite.filter(
      (satellite) =>
        !satelliteImageTimeline.hasOwnProperty(satellite.synmax_ship_id)
    );
    const activeTimelineInFetchList = mySatellite.find(
      (ship) => ship.synmax_ship_id === activeTabId
    );
    if (activeTimelineInFetchList && activeTabId) {
      const id = activeTimelineInFetchList.synmax_ship_id.slice(4);
      await fetchSatelliteImages(id, activeTimelineInFetchList.name);
    }

    satellitesToFetch.forEach((satellite) => {
      const { synmax_ship_id, name } = satellite;
      if (satelliteImageTimeline.hasOwnProperty(synmax_ship_id)) return;
      setSatelliteImageTimeline(
        (prevState: { [key: string]: ShipImages[] }) => {
          const updatedState = { ...prevState };
          if (!updatedState[synmax_ship_id]) {
            updatedState[synmax_ship_id] = [
              {
                id: synmax_ship_id,
                name,
                object_id: "",
                image: "",
              },
            ];
          }
          return updatedState;
        }
      );
    });
  };

  const fetchEvents = async (
    myEvents: {
      synmax_ship_id: string;
      type: string;
      lat?: number;
      lng?: number;
      path: { startDate: string; endDate: string; color: string };
      name?: string;
    }[],
    activeShipId: string | null
  ) => {
    if (!myEvents || myEvents.length === 0) return;

    const eventsToFetch = myEvents.filter(
      (event) => !selectedEvent.hasOwnProperty(event.synmax_ship_id)
    );

    const activeShipInFetchList = myEvents.find(
      (ship) => ship.synmax_ship_id === activeShipId
    );

    if (activeShipInFetchList && activeShipId) {
      await fetchAndSetEventDetails(activeShipId);
      await fetchAndSetShipPath(
        [activeShipId],
        activeShipInFetchList?.path?.startDate,
        activeShipInFetchList?.path?.endDate,
        activeShipInFetchList?.path?.color,
        true
      );
    }

    const updatedEvent: {
      [key: string]: ShipDetailsInterFace;
    } = {};

    const synmaxShipIds = eventsToFetch.map((event) => event.synmax_ship_id);

    try {
      for (const shipId of synmaxShipIds) {
        const shipObject = eventsToFetch.find(
          (event) => event.synmax_ship_id === shipId
        );
        if (shipObject) {
          updatedEvent[shipId] = {
            name: shipObject?.name || "",
            synmax_ship_id: shipObject.synmax_ship_id,
            type: shipObject.type,
            ...(shipObject?.lat && shipObject?.lng
              ? { latitude: shipObject.lat, longitude: shipObject.lng }
              : {}),
            pathColor: shipObject?.path?.color,
          };
        }
      }
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        ...updatedEvent,
      }));
    } catch (error) {
      console.error("Error fetching events info: ", error);
    }
  };
  const fetchShipPaths = async (
    shipPathsData: {
      synmax_ship_id: string;
      pathColor: string;
      analysis: boolean;
      dateRange: { startDate: string; endDate: string };
    }[]
  ) => {
    setShipPaths((prevShipPaths) => {
      const allShipsData = shipPathsData.reduce(
        (
          acc: {
            [key: string]: {
              path: PathData[];
              color: string;
              analysis: boolean;
              dateRange: { startDate: string; endDate: string };
            };
          },
          ship
        ) => {
          acc[ship.synmax_ship_id] = {
            path:
              prevShipPaths?.[ship.synmax_ship_id]?.path?.length > 0
                ? prevShipPaths[ship.synmax_ship_id]?.path
                : [],
            color: ship.pathColor || "#FFF",
            analysis: ship.analysis,
            dateRange: ship.dateRange,
          };
          return acc;
        },
        {}
      );
      return allShipsData;
    });
  };

  const getStateFromFirebase = async () => {
    dispatch(changeLoaderState(true));
    const { folderId, workStationId } = tabs[selectedTabId];

    const {
      showGlobeView,
      mapLatLng,
      date: savedDate,
      zoomLevel,
      mySatellite,
      myEvents,
      myShips,
      sidebarTabIndex,
      sidebarDrawer,
      shipDetailsDrawer,
      polygonFeatures,
      activeTabId,
    } = allProjects[folderId].workStations[workStationId].mapState as IMapState;

    let polygonData = null;

    if (polygonFeatures !== null) {
      polygonData = JSON.parse(polygonFeatures);
    }

    if (polygonData !== null && Object.keys(polygonData).length > 0) {
      setFeatures(polygonData);
    }
    setShipDetailTabValue(activeTabId);
    setShowGlobeView(showGlobeView);
    setMapViewState({
      latitude: mapLatLng.lat,
      longitude: mapLatLng.lng,
      zoom: zoomLevel,
    });
    setActive(sidebarTabIndex);
    setSidebarCollapsed(sidebarDrawer);
    setShipCollapsed(shipDetailsDrawer);
    const shipTypesToInclude = ["light", "attributed", "sanctioned"];

    const shipPathsData = [
      ...myEvents.map((event: any) => ({
        synmax_ship_id: event.synmax_ship_id,
        pathColor: event?.path?.color,
        dateRange: {
          startDate: event?.path?.startDate,
          endDate: event?.path?.endDate,
        },
        analysis: false,
      })),
      ...myShips.flatMap((ship: any) => {
        if (ship.type === "opticalSTS" || ship.type === "AISSTS") {
          return [
            ship?.path && ship.synmax_ship_id_1
              ? {
                  synmax_ship_id: ship.synmax_ship_id_1,
                  pathColor: ship.path.color,
                  analysis: false,
                  dateRange: {
                    startDate: ship.path.startDate,
                    endDate: ship.path.endDate,
                  },
                }
              : null,
            ship?.path2 && ship.synmax_ship_id_2
              ? {
                  synmax_ship_id: ship.synmax_ship_id_2,
                  pathColor: ship.path2.color,
                  analysis: false,
                  dateRange: {
                    startDate: ship.path2.startDate,
                    endDate: ship.path2.endDate,
                  },
                }
              : null,
          ].filter(
            (
              item
            ): item is {
              synmax_ship_id: string;
              pathColor: string;
              analysis: boolean;
              dateRange: { startDate: string; endDate: string };
            } => item !== null
          );
        }
        if (shipTypesToInclude.includes(ship.type)) {
          return [ship.synmax_ship_id]
            .filter((id): id is string => id !== undefined)
            .map((id) => ({
              synmax_ship_id: id,
              pathColor: ship?.path?.color,
              analysis: false,
              dateRange: {
                startDate: ship?.path?.startDate,
                endDate: ship?.path?.endDate,
              },
            }));
        }
        return [];
      }),
    ];
    await fetchShips(myShips, activeTabId);
    await fetchEvents(myEvents, activeTabId);
    await fetchSatellites(mySatellite, activeTabId);
    await fetchShipPaths(shipPathsData);

    if (date === null) {
      savedDate !== "" ? setDate(dayjs(savedDate)) : setDate(dayjs());
    }
    dispatch(changeLoaderState(false));
  };

  const dataURLToImage = (dataURL: any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = dataURL;
    });
  };

  const mergeImages = useCallback(async (dataURL1: any, dataURL2: any) => {
    try {
      const img1: any = await dataURLToImage(dataURL1);
      const img2: any = await dataURLToImage(dataURL2);

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = Math.max(img1.width, img2.width);
      canvas.height = Math.max(img1.height, img2.height);

      ctx?.drawImage(img1, 0, 0);
      ctx?.drawImage(img2, 0, 0);

      return canvas.toDataURL("image/png");
    } catch (error) {
      console.error("deckCanvas Error merging images:", error);
    }
  }, []);

  const saveFileToStorage = useCallback(
    ({ dataURL, workStationId, folderId }: any) => {
      const res = fetch(dataURL)
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], workStationId, {
            type: "image/png",
          });
          const formData = new FormData();
          formData.append("userId", user.uid);
          formData.append("folderId", folderId);
          formData.append("workStationId", workStationId);

          const storage = getStorage()
          const storageRef = ref(storage, `map/${file.name}`);
          const snapshot = await uploadBytesResumable(
            storageRef,
            blob,
            {contentType: blob.type}
          );
        
          const downloadURL = await getDownloadURL(snapshot.ref);
          formData.append("downloadURL", downloadURL);

          const response = await dispatch(addFileToStorageData(formData));

          return response;
        });
      return res;
    },
    [dispatch, user.uid]
  );

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const mapElement = document.createElement("a");
    mapElement.download = fileName;
    mapElement.href = window.URL.createObjectURL(blob);
    const uploadEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    mapElement.dispatchEvent(uploadEvent);
    mapElement.remove();
  };

  const exportToJson = (data: any, folderId: string, workStationId: string) => {
    const workStationName =
      allProjects[folderId].workStations[workStationId].name;
    downloadFile({
      data: JSON.stringify(data),
      fileName: `${workStationName}.json`,
      fileType: "text/json",
    });
  };

  const downloadStateInJson = () => {
    const currentTab = tabs[prevTabId ? prevTabId : selectedTabId];
    const { folderId, workStationId } = currentTab;
    const mapState = {
      ...allProjects[folderId].workStations[workStationId].mapState,
      showGlobeView,
      zoomLevel,
      mapLatLng,
      date: date?.format("YYYY-MM-DD"),
    };
    exportToJson(mapState, folderId, workStationId);
  };

  const uploadMapState = (e: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      const mapState: string = e?.target?.result as string;
      const mapStateObj = JSON.parse(mapState);
      setShowGlobeView(mapStateObj.showGlobeView);
      setZoomLevel(mapStateObj.zoomLevel);
      setMapViewState({
        latitude: mapStateObj.mapLatLng.lat,
        longitude: mapStateObj.mapLatLng.lng,
        zoom: mapStateObj.zoomLevel,
      });
    };
  };

  const saveStateToFirebase = useCallback(async () => {
    const shipsArray = Object.values(selectedShip).map((ship) => ({
      synmax_ship_id: ship.synmax_ship_id,
      object_id: ship.object_id || null,
      type: ship.type,
      name: ship.name,
      ...(shipPaths?.[ship.synmax_ship_id] && {
        path: {
          startDate: shipPaths[ship.synmax_ship_id].dateRange?.startDate,
          endDate: shipPaths[ship.synmax_ship_id].dateRange?.endDate,
          color: shipPaths[ship.synmax_ship_id].color,
        },
      }),

      heading: ship.heading,
      ...(ship.latitude && ship.longitude
        ? { lat: ship.latitude, lng: ship.longitude }
        : {}),
      ...(ship.type === "AISSTS"
        ? {
            synmax_ship_id_1: ship.synmax_ship_id_1,
            synmax_ship_id_2: ship.synmax_ship_id_2,
            ...(ship?.synmax_ship_id_1 &&
              shipPaths?.[ship.synmax_ship_id_1] && {
                path: {
                  startDate:
                    shipPaths[ship.synmax_ship_id_1].dateRange?.startDate,
                  endDate: shipPaths[ship.synmax_ship_id_1].dateRange?.endDate,
                  color: shipPaths[ship.synmax_ship_id_1].color,
                },
              }),
            ...(ship?.synmax_ship_id_2 &&
              shipPaths?.[ship.synmax_ship_id_2] && {
                path2: {
                  startDate:
                    shipPaths[ship.synmax_ship_id_2].dateRange?.startDate,
                  endDate: shipPaths[ship.synmax_ship_id_2].dateRange?.endDate,
                  color: shipPaths[ship.synmax_ship_id_2].color,
                },
              }),
          }
        : {}),
      ...(ship.type === "opticalSTS"
        ? {
            synmax_ship_id_1: ship.synmax_ship_id_1,
            synmax_ship_id_2: ship.synmax_ship_id_2,
            object_id_1: ship.object_id_1,
            object_id_2: ship.object_id_2,
            ship1_status: ship.ship1_status,
            ship2_status: ship.ship2_status,
            heading: ship.frontend_rotation,
            ...(ship?.synmax_ship_id_1 &&
              shipPaths?.[ship.synmax_ship_id_1] && {
                path: {
                  startDate:
                    shipPaths[ship.synmax_ship_id_1].dateRange?.startDate,
                  endDate: shipPaths[ship.synmax_ship_id_1].dateRange?.endDate,
                  color: shipPaths[ship.synmax_ship_id_1].color,
                },
              }),
            ...(ship?.synmax_ship_id_2 &&
              shipPaths?.[ship.synmax_ship_id_2] && {
                path2: {
                  startDate:
                    shipPaths[ship.synmax_ship_id_2].dateRange?.startDate,
                  endDate: shipPaths[ship.synmax_ship_id_2].dateRange?.endDate,
                  color: shipPaths[ship.synmax_ship_id_2].color,
                },
              }),
          }
        : {}),
    }));

    const eventsArray = Object.values(selectedEvent).map((event) => ({
      synmax_ship_id: event.synmax_ship_id,
      type: event.type,
      name: event.name,
      ...(shipPaths?.[event.synmax_ship_id] && {
        path: {
          startDate: shipPaths[event.synmax_ship_id].dateRange?.startDate,
          endDate: shipPaths[event.synmax_ship_id].dateRange?.endDate,
          color: shipPaths[event.synmax_ship_id].color,
        },
      }),
      ...(event.type === "spoofing" && event.latitude && event.longitude
        ? { lat: event.latitude, lng: event.longitude }
        : {}),
    }));
    const satelliteImagesArray = Object.values(satelliteImageTimeline).map(
      (image) => ({
        synmax_ship_id: image[0].id,
        name: image[0].name,
      })
    );
    const currentTab = tabs[prevTabId ? prevTabId : selectedTabId];
    const { folderId, workStationId } = currentTab;

    setLoading(true);

    const mapState = {
      ...allProjects[folderId].workStations[workStationId].mapState,
      showGlobeView,
      zoomLevel,
      mapLatLng,
      date: date?.format("YYYY-MM-DD"),
      myShips: shipsArray,
      myEvents: eventsArray,
      mySatellite: satelliteImagesArray,
      sidebarTabIndex: active,
      sidebarDrawer: collapsed,
      shipDetailsDrawer: shipCollapsed,
      polygonFeatures: JSON.stringify(features),
      activeTabId: shipDetailTabValue,
    };

    const res = await api.put(`/work-stations/updateMapState`, {
      userId: user.uid,
      folderId: currentTab.folderId,
      workStationId: currentTab.workStationId,
      mapState,
    });
    // Update the local state as well
    const updatedProjects: any = {
      ...allProjects,
      [folderId]: {
        ...allProjects[folderId],
        workStations: {
          ...allProjects[folderId].workStations,
          [workStationId]: {
            ...allProjects[folderId].workStations[workStationId],
            mapState: {
              ...allProjects[folderId].workStations[workStationId].mapState,
              showGlobeView,
              zoomLevel,
              mapLatLng,
              date: date?.format("YYYY-MM-DD"),
              myShips: shipsArray,
              myEvents: eventsArray,
              mySatellite: satelliteImagesArray,
              sidebarTabIndex: active,
              sidebarDrawer: collapsed,
              shipDetailsDrawer: shipCollapsed,
            },
          },
        },
      },
    };
    dispatch(updateAllProjects(updatedProjects));
    setLoading(false);
    dispatch(setChangeSaved(true));
    let response: any;
    if (res.data.msg) {
      if (mapRef.current !== null) {
        const { folderId, workStationId } = currentTab;
        if (showGlobeView) {
          response = await saveFileToStorage({
            dataURL: mapSnapshot,
            workStationId,
            folderId,
          });
        } else {
          deckCanvas?._deck?.redraw(true);
          await mergeImages(
            mapSnapshot,
            deckCanvas?._deck?.canvas?.toDataURL("image/png")
          ).then(async (mergedDataURL: any) => {
            response = await saveFileToStorage({
              dataURL: mergedDataURL,
              workStationId,
              folderId,
            });
          });
        }
      }
      if (response) {
        setSnackbarMessage(res.data.msg);
        setIsSnackBarOpen(true);
        setMapViewState({
          latitude: mapLatLng.lat,
          longitude: mapLatLng.lng,
          zoom: zoomLevel,
        });
        dispatch(setPrevTabId(""));
        if (isTabSwitch) {
          dispatch(setIsSwitchTabUpdated(true));
        } else if (isTabDelete) {
          dispatch(setIsDeleteTabUpdated(true));
        }
      }
    }
  }, [
    allProjects,
    date,
    deckCanvas?._deck,
    dispatch,
    mapLatLng,
    mapSnapshot,
    mergeImages,
    selectedTabId,
    showGlobeView,
    tabs,
    user.uid,
    zoomLevel,
    saveFileToStorage,
    prevTabId,
    isTabSwitch,
    isTabDelete,
    selectedShip,
    selectedEvent,
    satelliteImageTimeline,
    collapsed,
    active,
    shipCollapsed,
    features,
    shipPaths,
  ]);

  useEffect(() => {
    if (isSaveMap && !isPrevTabId) {
      saveStateToFirebase();
    }

    dispatch(setIsSaveMap(false));
  }, [dispatch, isSaveMap, saveStateToFirebase, isPrevTabId]);

  useEffect(() => {
    if (isSaveMap && isPrevTabId) {
      saveStateToFirebase();
    }
    dispatch(setIsSaveMap(false));
  }, [dispatch, isSaveMap, isPrevTabId, saveStateToFirebase]);

  const handleClose = () => setIsSnackBarOpen(false);
  const onShipClick = (tabId?: string) => {
    if (!shipCollapsed) setShipCollapsed(true);
    if (tabId) {
      handleShipDetailsTabChange(undefined, tabId);
    }
  };

  useEffect(() => {
    setLayersForFuturePathPrediction(layers[slider] ? layers[slider] : []);
  }, [slider, futurePathData]);
  const [colorArr, setColorArr] = useState<string[]>([]);
  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    if (luma < 40) {
      return getRandomColor();
    } else {
      return color;
    }
  };
  const getUniqueColor = (): string => {
    let candidateColor = "";
    const maxIterations = 20;
    let iterationCounter = 0;
    do {
      candidateColor = getRandomColor(); // get a new random color
    } while (
      iterationCounter++ < maxIterations &&
      colorArr.includes(candidateColor)
    ); // repeat if the color is already used
    setColorArr((pastColors: string[]) => {
      const newColors = [...pastColors, candidateColor];
      return newColors;
    });
    return candidateColor || "#ccc"; // Return the color that has not been used already or a default color
  };

  const selectedPath = useMemo(() => {
    if (!shipDetailTabValue) return [];

    if (
      shipDetailTabValue.includes("_") &&
      !selectedShip[shipDetailTabValue]?.isObservationShip
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      return bunkeringValue === "1"
        ? shipPaths[synmax_ship_id_1]?.path || []
        : shipPaths[synmax_ship_id_2]?.path || [];
    }
    return shipPaths[shipDetailTabValue]?.path || [];
  }, [shipPaths, shipDetailTabValue, bunkeringValue, selectedShip]);

  const deckGlLayers = getDeckGLLayers(
    detections,
    zoomLevel,
    spoofingEvents,
    opticalSTS,
    AISSTS,
    currentEventTypeFilterOptions,
    sanctionedShips,
    setSelectedShip,
    shipDetailTabValue,
    setShipDetailTabValue,
    selectedShip,
    setShowCursor,
    currentCoveraData,
    layerToggleSwitches,
    setSelectedEvent,
    selectedEvent,
    onShipClick,
    shipPaths,
    setShipPaths,
    getUniqueColor,
    selectedPath,
    sliderValue,
    pathRef,
    setShipDetails,
    shipObjects,
    similarShips,
    showSimilarShips,
    area,
    hoveredId,
    droppedPins,
    features.data,
    showTemporaryCircle,
    coordinatesForEstimatedLocationInfo,
    setFilteredDetection,
    layersForFuturePathPrediction,
    drawRef,
    showDeckglPolygons,
    setShowDeckglPolygons,
    showGlobeView,
    layerforPolygonShipAnalysis[sliderforPolygonShipAnalysis],
    showShips,
    selectedShipEstimatedLoactionLayer,
    selectedShipEstimatedaLocationPath,
    liveAISShips
  );
  useEffect(() => {
    if (currentEventTypeFilterOptions && detections) {
      const { spoofing, light, dark, unattributed } =
        currentEventTypeFilterOptions;
      const filteredLightShips = filterShips(
        detections?.lightGeoJson,
        light,
        "light",
        true
      );

      const filteredDarkShips = filterShips(
        detections?.attributedGeoJson,
        dark,
        "dark",
        true
      );
      const filteredSpoofingShips = filterShips(
        spoofingEvents,
        spoofing,
        "spoofing",
        true
      );
      const filteredUnattributedShips = filterShips(
        detections?.unattributedGeoJson,
        unattributed,
        "unattributed",
        true
      );
      setFilteredDetection({
        filteredDarkShips,
        filteredLightShips,
        filteredSpoofingShips,
        filteredUnattributedShips,
      });
    }
  }, [currentEventTypeFilterOptions, detections, spoofingEvents]);
  const calculateShipCounts = (filterToApply: (ship: any) => any): void => {
    const filteredLightDetections = currentEventTypeFilterOptions?.light
      ?.checked
      ? filteredDetection?.filteredLightShips.filter(filterToApply).length
      : 0;
    const filteredUnattributed = currentEventTypeFilterOptions?.unattributed
      ?.checked
      ? filteredDetection?.filteredUnattributedShips.filter(filterToApply)
          .length
      : 0;
    const sanctionedShipsInView = currentEventTypeFilterOptions?.sanctioned
      ?.checked
      ? sanctionedShips.features.filter(filterToApply).length
      : 0;
    const spoofingShipsInCurrentView = currentEventTypeFilterOptions?.spoofing
      ?.checked
      ? filteredDetection?.filteredSpoofingShips.filter(filterToApply).length
      : 0;
    const bunkeringAISShipsIncCurrentView = currentEventTypeFilterOptions?.AIS
      ?.checked
      ? AISSTS.features.filter(filterToApply).length
      : 0;
    const darkShipsInCurrentView = currentEventTypeFilterOptions?.dark?.checked
      ? filteredDetection?.filteredDarkShips.filter(filterToApply).length
      : 0;
    const opticalSTSBunnkeringInCurrentView = currentEventTypeFilterOptions
      ?.bunkering?.checked
      ? opticalSTS.features.filter(filterToApply).length
      : 0;

    const totalShipsInCurrentView =
      filteredLightDetections +
      filteredUnattributed +
      sanctionedShipsInView +
      spoofingShipsInCurrentView +
      bunkeringAISShipsIncCurrentView +
      darkShipsInCurrentView +
      opticalSTSBunnkeringInCurrentView;

    setShipCountsInCurrentView({
      total: totalShipsInCurrentView,
      light: filteredLightDetections,
      unattributed: filteredUnattributed,
      sanctioned: sanctionedShipsInView,
      spoofing: spoofingShipsInCurrentView,
      bunkering: opticalSTSBunnkeringInCurrentView,
      ais: bunkeringAISShipsIncCurrentView,
      dark: darkShipsInCurrentView,
    });
  };

  useEffect(() => {
    if (
      showGlobeView &&
      detections &&
      sanctionedShips &&
      spoofingEvents &&
      AISSTS &&
      opticalSTS
    ) {
      calculateShipCounts((ship: any) => ship);
    }
  }, [
    detections,
    sanctionedShips,
    spoofingEvents,
    AISSTS,
    opticalSTS,
    showGlobeView,
    currentEventTypeFilterOptions,
  ]);

  const mapProps = {
    zoomLevel,
    setZoomLevel,
    setMapLatLng,
    mapViewState,
    key: mapLatLng,
    selectedTabId,
    setChangeSaved,
    deckGlLayers,
    currentEventTypeFilterOptions,
    mapRef,
    setMapSnapshot,
    setDeckCanvas,
    deckCanvas,
    showCursor,
    setShowCursor,
    mapLatLng,
    detections,
    calculateShipCounts,
    coordinatesForEstimatedLocationInfo,
    droppedPins,
    setDroppedPins,
    setMapViewState,
    shipPaths,
    mapStyle,
  };

  const onSidebarClick = (index: number) => {
    setActive(index);
    if (!collapsed) setSidebarCollapsed(true);
    dispatch(setChangeSaved(true));
  };

  const handleViewExtendedPath = async (dateRange: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }) => {
    if (shipDetailTabValue === null) return;

    setExtendedPathLoading(true);
    try {
      let shipId = "";
      let currentShipObject: number = 0;
      let isOpticalShip: boolean = false;
      let isEvent: boolean = Object.keys(selectedEvent)?.includes(
        shipDetailTabValue
      )
        ? true
        : false;
      if (
        shipDetailTabValue.includes("_") &&
        !selectedShip[shipDetailTabValue]?.isObservationShip
      ) {
        const shipIds = selectedShip[shipDetailTabValue];
        shipId =
          bunkeringValue === "1"
            ? shipIds?.synmax_ship_id_1 || ""
            : shipIds?.synmax_ship_id_2 || "";
        currentShipObject = +(shipDetails?.[shipId]?.object_id || 0);
        isOpticalShip = true;
      } else {
        shipId = shipDetailTabValue || "";
        if (!shipId && Object.keys(selectedShip || {}).length <= 0) return;
        currentShipObject = +(
          selectedShip?.[shipDetailTabValue]?.object_id || 0
        );
      }

      const start = moment
        .utc(`${dateRange.startDate.format("YYYY-MM-DD")} 00:00:00`)
        .format("YYYY-MM-DD HH:mm:ss");
      const end = moment
        .utc(`${dateRange.endDate.format("YYYY-MM-DD")} 23:59:59`)
        .format("YYYY-MM-DD HH:mm:ss");

      const shipPath = await getShipPath([shipId], start, end);
      const pathObjects = await getObjectsForShips([shipId], start, end);

      setShipObjects((prev) => ({
        ...prev,
        [shipId]: {
          ...prev[shipId],
          objects: pathObjects,
          isOpticalShip,
          isEvent,
          parentShipObject: currentShipObject,
        },
      }));
      setShipPaths((prev) => ({
        ...prev,
        [shipId]: {
          ...prev[shipId],
          path: shipPath,
          showPath: true,
          dateRange: { startDate: start, endDate: end },
        },
      }));
    } catch (error) {
      console.error("Error fetching ship path:", error);
    } finally {
      setExtendedPathLoading(false);
    }
  };
  const handleViewPathAnalysis = async () => {
    let shipId = "";
    if (shipDetailTabValue === null) return;
    if (shipDetailTabValue.includes("_")) {
      const shipIds = selectedShip[shipDetailTabValue];
      shipId =
        bunkeringValue === "1"
          ? shipIds?.synmax_ship_id_1 || ""
          : shipIds?.synmax_ship_id_2 || "";
    } else {
      shipId = shipDetailTabValue;
    }

    setShipPaths((prev) => {
      const currentAnalysisState = prev[shipId]?.analysis || false;
      return {
        ...prev,
        [shipId]: {
          ...prev[shipId],
          analysis: !currentAnalysisState,
        },
      };
    });
  };

  const handleDownloadXLS = () => {
    if (!shipDetailTabValue) return;
    const shipPath: PathData[] = selectedPath;
    let shipId: string = "";
    if (
      shipDetailTabValue.includes("_") &&
      !selectedShip[shipDetailTabValue]?.isObservationShip
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      shipId = bunkeringValue === "1" ? synmax_ship_id_1 : synmax_ship_id_2;
    } else {
      shipId = shipDetailTabValue;
    }
    const ship = shipDetails[shipId];
    const { dynamic, ...shipData } = ship || {};

    const updatedShipPath = shipPath?.map((f) => ({
      ...f,
      timestamp: `${moment
        .utc(new Date(f.timestamp * 1000))
        .format("YYYY-MM-DD HH:mm:ss")}`,
    }));
    const shipSheet = updatedShipPath
      ? XLSX.utils.json_to_sheet(updatedShipPath)
      : XLSX.utils.json_to_sheet([]);

    const updatedDynamicShipData = dynamic?.map((f) => ({
      ...f,
      timestamp: `${moment
        .utc(new Date(f?.timestamp * 1000))
        .format("YYYY-MM-DD HH:mm:ss")}`,
    }));
    const dynamicShipDataSheet = updatedDynamicShipData
      ? XLSX.utils.json_to_sheet(updatedDynamicShipData)
      : XLSX.utils.json_to_sheet([]);

    const lastQuery =
      typeof shipData?.last_query === "string" ||
      typeof shipData?.last_query === "number"
        ? moment
            .utc(new Date(shipData.last_query))
            .format("YYYY-MM-DD HH:mm:ss")
        : undefined;

    const updatedStaticShipData = {
      ...shipData,
      last_query: lastQuery,
      speeds: Array.isArray(shipData?.speeds)
        ? shipData.speeds.join(",")
        : undefined,
    };

    const staticShipDataSheet = XLSX.utils.json_to_sheet([
      updatedStaticShipData,
    ]);

    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, shipSheet, "Path Data");
    XLSX.utils.book_append_sheet(
      workBook,
      dynamicShipDataSheet,
      "Dynamic Data"
    );
    XLSX.utils.book_append_sheet(workBook, staticShipDataSheet, "Static Data");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    const fileName = selectedPath[0]?.synmax_ship_id;
    XLSX.writeFile(workBook, `${fileName}-pathdata.xlsx`);
  };

  const handleSatelliteImageClick = async (objectId: number, name: string) => {
    try {
      const isSimilarShip = name === "Similar Ships";
      const shipData = await getShipObject([objectId]);
      let { synmax_ship_id, timestamp, attribution, acquired, day, dark } =
        shipData;
      if (!synmax_ship_id) {
        synmax_ship_id = attribution;
      }
      const shipId: string = isSimilarShip
        ? `unattributed-${objectId}`
        : `${objectId}_${synmax_ship_id}`;
      setSelectedShip((prevShips) => ({
        ...(prevShips as { [key: string]: ShipDetailsInterFace }),
        [shipId]: {} as ShipDetailsInterFace,
      }));

      setShipDetailTabValue(shipId);

      if (!acquired) {
        acquired = timestamp * 1000;
      }
      if (!acquired && !timestamp) {
        acquired = day;
      }
      let type;
      if (attribution === "None") {
        type = "unattributed";
      } else if (attribution && !dark) {
        type = "light";
      } else if (attribution && dark) {
        type = "attributed";
      }

      const dateSting = moment.utc(acquired).format("YYYY-MM-DD");
      const startTimestamp = acquired - 24 * 60 * 60 * 1000;
      const shipInfo = await getShipInfo([synmax_ship_id], dateSting);
      setShipDetails((prev) => ({
        ...prev,
        [shipId]: {
          ...shipInfo[synmax_ship_id],
        },
      }));
      setMapLatLng({
        lat: shipData?.latitude,
        lng: shipData?.longitude,
      });
      setMapViewState((prev: any) => ({
        zoom: zoomLevel,
        longitude: shipData?.longitude,
        latitude: shipData?.latitude,
      }));
      const startDate = moment
        .utc(startTimestamp)
        .format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment.utc(acquired).format("YYYY-MM-DD HH:mm:ss");
      const shipPath = await getShipPath([synmax_ship_id], startDate, endDate);
      const shipPathColor = getUniqueColor();
      setShipPaths((prev) => ({
        ...prev,
        [shipId]: {
          color: shipPathColor,
          analysis: false,
          path: shipPath,
          dateRange: { startDate, endDate },
        },
      }));

      const updatedShipDetails = {
        [shipId]: {
          ...shipInfo[synmax_ship_id],
          ...shipData,
          synmax_ship_id: shipId,
          type,
          active: true,
          ...(isSimilarShip
            ? { isSimilarShip: true }
            : { isObservationShip: true }),
        },
      };
      setSelectedShip((prevShips) => ({
        ...updateActiveStatus(prevShips, shipId),
        ...updatedShipDetails,
      }));
    } catch (error) {
      console.error("Error fetching ship data:", error);
    }
  };

  const handleSimilarShips = async () => {
    if (!shipDetailTabValue) return;
    let shipId: string = "";
    if (
      shipDetailTabValue.includes("_") &&
      !selectedShip[shipDetailTabValue]?.isObservationShip
    ) {
      const shipIds = selectedShip[shipDetailTabValue];
      const { synmax_ship_id_1 = "", synmax_ship_id_2 = "" } = shipIds || {};
      shipId = bunkeringValue === "1" ? synmax_ship_id_1 : synmax_ship_id_2;
    } else {
      shipId = shipDetailTabValue;
    }
    const { startDate, endDate } = dateRange;
    const start =
      moment.utc(new Date(startDate)).format("YYYY-MM-DD") + " 00:00:00";
    const end =
      moment.utc(new Date(endDate)).format("YYYY-MM-DD") + " 23:59:59";
    const shipData = await getSimilarShips(shipId, start, end, area);
    setSimilarShips((prevShips) => ({
      ...prevShips,
      [shipId]: shipData,
    }));
    let isDataSet = false;
    shipData?.length > 0 && setShipDetailTabValue(`sim:${shipId}`);
    await Promise.all(
      shipData.map(async (item: ShipImages) => {
        const data = await getSatelliteImage(+item.object_id);
        if (data && !isDataSet) {
          setIsSatelliteImageLoading(false);
          isDataSet = true;
        }
        if (data) {
          setSatelliteImageTimeline(
            (prevState: { [key: string]: ShipImages[] }) => {
              const updatedState = { ...prevState };
              const id = `sim:${shipDetailTabValue}`;
              if (!updatedState[id]) {
                updatedState[id] = [];
              }
              updatedState[id].push({
                object_id: item.object_id,
                longitude: item.longitude,
                latitude: item.latitude,
                acquired: item.acquired,
                image: data,
                name: "Similar Ships",
                id,
              });
              return updatedState;
            }
          );
        }
      })
    );
  };
 
  return (
    <MapContext.Provider
      value={{
        drawRef,
        features,
        setFeatures,
        showTemporaryCircle,
        setShowTemporaryCircle,
        detections,
        showShips,
        setShowShips,
        shipDetailTabValue,
        selectedShip,
        selectedEvent,
        setSelectedShipEstimatedLocationGEOJSON,
        selectedShipEstimatedLoactionLayer,
        handleEstmatedShipLayerSelect,
        setSelectedShipEstimatedLocationLayer,
        bunkeringValue,
        setShipDetailTabValue,
        setSelectedShip,
        getUniqueColor,
        setShipPaths,
        updateActiveStatus,
        setSelectedEvent,
        onShipClick,
        showSimilarShips,
        setShowSimilarShips,
        date,
        setShipDetails,
        setMapViewState
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        className={styles.subHeaderMain}
        key={selectedTabId}
      >
        <Box display="flex" alignItems="center" gap="15px">
          <span className={styles.subHeaderText}>Work</span>
          <EditOutlined fontSize="small" />
        </Box>
        <Box display="flex" alignItems="center" gap="24px">
          <Box display="flex" alignItems="center" gap="8px">
            <MainSearch
              searchIsExpanded={searchIsExpanded}
              handleSearchExpand={handleSearchExpand}
            />
          </Box>
          <Divider className={styles.divider} orientation="vertical" flexItem />
          {searchIsExpanded ? null : (
            <>
              <Box display="flex" alignItems="center" gap="8px">
                <LanguageOutlined fontSize="small" />
                <span className={styles.subHeaderFilterText}>Travel to</span>
              </Box>
              <Divider
                className={styles.divider}
                orientation="vertical"
                flexItem
              />
              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                className={styles.datePickerContainer}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="date picker"
                    value={date}
                    onChange={(newValue) => {
                      dispatch(setChangeSaved(false));
                      setDate(newValue);
                    }}
                    onClose={() => setShowDatePicker(false)}
                    open={showDatePicker}
                    slots={{
                      openPickerIcon: (params) => (
                        <CalendarTodayOutlined
                          {...params}
                          fontSize="small"
                          className={styles.replayIcon}
                          onClick={() => {
                            menuRef?.current?.click();
                            setShowDatePicker(true);
                          }}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
                <span className={styles.subHeaderFilterText}>
                  {!date?.isSame(dayjs(), "day") && date !== null
                    ? `${date?.format("YYYY/MM/DD")}`
                    : "Live"}
                </span>
                <ReplayOutlined
                  fontSize="small"
                  onClick={() => setDate(null)}
                  className={styles.replayIcon}
                />
              </Box>
              <Divider
                className={styles.divider}
                orientation="vertical"
                flexItem
              />
              <Box display="flex" alignItems="center" gap="8px" width={280}>
                <SignalCellularAltOutlined fontSize="small" />
                {showGlobeView ? (
                  <span className={styles.subHeaderFilterText}>
                    Total ships in globe view: {shipCountsInCurrentView.total}
                  </span>
                ) : (
                  <span className={styles.subHeaderFilterText}>
                    Ships in current view: {shipCountsInCurrentView.total}
                  </span>
                )}
                {shipCountsAreShown ? (
                  <ExpandLess
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setShipCountsAreShown(false);
                    }}
                  />
                ) : (
                  <ExpandMore
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setShipCountsAreShown(true);
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="24px">
          <Button
            className={styles.stateButton}
            onClick={() => {
              setShowGlobeView(!showGlobeView);
              dispatch(setChangeSaved(false));
              setShowDeckglPolygons(true);
            }}
            startIcon={<VisibilityOutlined />}
          >
            Change View
          </Button>

          {!searchIsExpanded &&
            workStationMode !== "incognito" &&
            workStationMode !== "local" && (
              <Button
                className={`${styles.stateButton} ${styles.savedButton}`}
                classes={{
                  disabled: !saveFileLoading
                    ? styles.disabled
                    : `${styles.stateButton} ${styles.savedButton}`,
                }}
                disabled={changesSaved}
                onClick={saveStateToFirebase}
                startIcon={
                  loading || saveFileLoading ? (
                    <CircularProgress
                      classes={{ circle: styles.circularSpiner }}
                      size={20}
                    />
                  ) : (
                    <SaveOutlined />
                  )
                }
              >
                {loading || saveFileLoading ? "Saving" : "Saved"}
              </Button>
            )}
          {workStationMode === "local" && (
            <>
              <Button
                className={`${styles.stateButton} ${styles.downloadButton}`}
                onClick={downloadStateInJson}
                startIcon={<FileDownload />}
              >
                Download
              </Button>

              <input
                type="file"
                style={{ display: "none" }}
                id="upload"
                ref={uploadInputRef}
                onChange={(e) => uploadMapState(e)}
              />
              <Button
                className={`${styles.stateButton} ${styles.savedButton}`}
                style={{ position: "relative", zIndex: 10001 }}
                startIcon={<Publish />}
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current?.click()
                }
              >
                Upload
              </Button>
            </>
          )}

          <ContentCopyOutlined fontSize="small" />
          <ShareOutlined fontSize="small" />
          <MoreHorizOutlined fontSize="small" />
        </Box>
        <MainMapHeaderShipCounts
          shipsInCurrentViewCountIsShown={shipCountsAreShown}
          shipCountsInCurrentView={shipCountsInCurrentView}
          showGlobeView={showGlobeView}
        />
      </Box>
      <Sidebar
        menuList={menuList}
        active={active}
        onClick={(index) => onSidebarClick(index)}
      >
        <Drawer
          collapsed={collapsed}
          setCollapsed={setSidebarCollapsed}
          width={collapsed ? "385px" : "29px"}
          left={"50px"}
        >
          {collapsed && menuList[active]?.component}
        </Drawer>
      </Sidebar>
      {detailsTabs.length > 0 && (
        <Drawer
          collapsed={shipCollapsed}
          setCollapsed={setShipCollapsed}
          width={shipCollapsed ? "500px" : "29px"}
          left={collapsed ? "435px" : "79px"}
          hideBackdrop={true}
        >
          <ShipDetails
            shipDetailTabValue={shipDetailTabValue}
            setShipDetailTabValue={setShipDetailTabValue}
            handleShipDetailsTabChange={handleShipDetailsTabChange}
            shipDetailsTabs={detailsTabs}
            setShipDetailsTabs={setDetailsTabs}
            handleShipDetailsTabClose={handleShipDetailsTabClose}
            satelliteImageTimeline={satelliteImageTimeline}
            fetchSatelliteImages={fetchSatelliteImages}
            isSatelliteImageLoading={isSatelliteImageLoading}
            bunkeringValue={bunkeringValue}
            setBunkeringValue={setBunkeringValue}
            getEventDetails={getEventDetails}
            shipPaths={shipPaths}
            setShipPaths={setShipPaths}
            pathRef={pathRef}
            handleViewPathAnalysis={handleViewPathAnalysis}
            handleDownloadXLS={handleDownloadXLS}
            handleSatelliteImageClick={handleSatelliteImageClick}
            setHoveredId={setHoveredId}
            hoveredId={hoveredId}
          />
        </Drawer>
      )}
      <FilterArea
        currentEventTypeFilterOptions={currentEventTypeFilterOptions}
        setCurrentEventTypeFilterOptions={setCurrentEventTypeFilterOptions}
        currentSelectedFilter={currentSelectedFilter}
        setCurrentSelectedFilter={setCurrentSelectedFilter}
        filterLayers={filterLayers}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        intialStateForFilterProvider={intialStateForFilterProvider}
        setIntialStateForFilterProvider={setIntialStateForFilterProvider}
        setMapStyle={setMapStyle}
        showGlobeView={showGlobeView}
      />
      <div id="map" style={{ width: "100%", height: "100%", position: "fixed" }}>
        {showGlobeView ? (
          <DeckglRender {...mapProps} setMapViewState={setMapViewState} />
        ) : (
          <>
          <RightNavigationBar
            currentSelectedFilter={currentSelectedFilter}
            setCurrentSelectedFilter={setCurrentSelectedFilter}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            selectedShape={selectedShape}
            setSelectedShape={setSelectedShape}
            setshowDropPinDialog={setshowDropPinDialog}
            mapRef={mapRef}
            setMapViewState={setMapViewState}
            />
          <MapboxRender {...mapProps} filteredDetection={filteredDetection} />
          </>
        )}
      </div>
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        gap={1}
        className={styles.shipToolsModalMain}
      >
        <ShipTools
          shipDetailTabValue={shipDetailTabValue}
          shipDetailsTabs={detailsTabs}
          bunkeringValue={bunkeringValue}
          handleViewExtendedPath={handleViewExtendedPath}
          setSelectedShipEstimatedLocationLayer={
            setSelectedShipEstimatedLocationLayer
          }
          selectedShipEstimatedLoactionLayer={
            selectedShipEstimatedLoactionLayer
          }
          selectedPath={selectedPath}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          pathRef={pathRef}
          loading={extendedPathLoading}
        />

        {isOpenPolygonShipAnalysisModal && (
          <PolygonShipAnalysis
            setSelectedShape={setSelectedShape}
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleSimilarShips={handleSimilarShips}
            showSimilarShips={
              (Object.keys(selectedEvent).length > 0 ||
                Object.keys(selectedShip).length > 0) &&
              shipDetailTabValue &&
              !selectedShip[shipDetailTabValue]?.isObservationShip
                ? true
                : false
            }
            lastSelectedOption={lastSelectedOption}
            setLastSelectedOption={setLastSelectedOption}
          />
        )}
      </Box>
      {minimizePolygonModal && (
        <PolygonShipAnalysisMinimize
          setSelectedShape={setSelectedShape}
          setDateRange={setDateRange}
        />
      )}
      {showDropPinDialog && (
        <DropPin
          setshowDropPinDialog={setshowDropPinDialog}
          setMapLatLng={setMapLatLng}
          setDroppedPins={setDroppedPins}
          mapRef={mapRef}
          setMapViewState={setMapViewState}
        />
      )}

      {isSnackBarOpen && (
        <Snackbar
          open={isSnackBarOpen}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </MapContext.Provider>
  );
};

export default MainMap;

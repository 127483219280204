import React, { createContext, useContext, useState, ReactNode } from 'react';
import { AlertColor } from '@mui/material';

interface SnackbarContextType {
  message: string;
  severity: AlertColor; 
  isOpen: boolean;
  setSnackbarMessage: (msg: string) => void;
  setSnackbarSeverity: (severity: AlertColor) => void; 
  setIsSnackBarOpen: (isOpen: boolean) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('info');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setSnackbarMessage = (msg: string) => setMessage(msg);
  const setSnackbarSeverity = (sev: AlertColor) => setSeverity(sev);
  const setIsSnackBarOpen = (open: boolean) => setIsOpen(open);

  return (
    <SnackbarContext.Provider
      value={{ message, severity, isOpen, setSnackbarMessage, setSnackbarSeverity, setIsSnackBarOpen }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

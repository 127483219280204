import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  styled,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import styles from "./AllSearchResultsDialog.module.scss";
import Pagination from "@mui/material/Pagination";
import { CurrentSearchResults, FilterOption, FilterType } from "./MainSearch";
import SynMaxIcon, { IconType } from "../../Shared/SynMaxIcon";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

const JumpToPageButton = styled(Button)`
  height: 35px;
  width: 52px;
  background-color: #00a3e3;
  color: white;
  margin-left: 12px;
`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "40px",
  backgroundColor: "#24263C",
  color: "#ffffff",
  border: 0,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1a4c6e",
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#1b1d2d",
  color: "#ffffff",
  border: 0,
  borderRadius: 4,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: "100px",
  color: "#ffffff",
  border: 0,
  fontSize: 14,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const SelectFilterButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: 0,
  minWidth: "max-content",
  whiteSpace: "nowrap",
}));

const StyledNameCell = styled(TableCell)(({ theme }) => ({
  color: "#ffffff",
  border: 0,
  fontSize: 14,
  display: "flex",
  alignItems: "center",
}));

const initialSearchResultsState = {
  vessels: [],
  objects: [],
};
const AllSearchReusltHeader: React.FC<{ columns: string[] }> = ({
  columns,
}) => (
  <StyledTableHeader>
    {columns.map((column, index) => (
      <StyledTableCell align={index === 0 ? "left" : "right"} key={column}>
        {column}
      </StyledTableCell>
    ))}
  </StyledTableHeader>
);

const SEARCH_RESULTS_LIMIT = 20;

const AllResultsTable: React.FC<{
  columns: { title: string; field: any }[];
  icon: IconType;
  selectedFilter: FilterOption;
  currentSearchValue: string;
  searchTermRefValue: string | null;
  loadShipsOnMap: any;
}> = ({ columns, icon, selectedFilter, searchTermRefValue,loadShipsOnMap }) => {
  const [currentSearchResults, setCurrentSearchResults] = useState<any>(
    initialSearchResultsState
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [jumoToPageValue, setJumpToPageValue] = useState("");
  const [vessselTotalPageCount, setVesselsTotalPageCount] = useState(0);
  const [objectTotalPageCount, setObjectTotalPageCount] = useState(0);
  const [loadingSpinnerIsShown, setLoadingSpinnerIsShown] = useState(false);
  const searchResultsTableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setJumpToPageValue(`${currentPage}`);
  }, [currentPage]);

  useEffect(() => {
    setLoadingSpinnerIsShown(true);
    const abortController = new AbortController();
    const getNewOptions = async () => {
      const token = localStorage.getItem("token");

      const numberOfNewRows = searchResultsTableRef?.current?.clientHeight
        ? Math.floor(searchResultsTableRef?.current?.clientHeight / 40) - 1
        : SEARCH_RESULTS_LIMIT;

      const offset =
        currentPage === 1 ? 0 : numberOfNewRows * (currentPage - 1);

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}searchShips?q=${searchTermRefValue}&offset=${offset}&limit=${numberOfNewRows}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            signal: abortController.signal,
          }
        );

        const searchResultsFormatted: CurrentSearchResults = {
          vessels: res.data.vessels.results.map((item: any) => item.attributes),
          objects: res.data.objects.results.map((item: any) => ({object_id: item.object_id, ...item.attributes})),
          vesselsTotal: res.data.vessels.paging.total,
          objectsTotal: res.data.objects.paging.total,
        };

        setCurrentSearchResults(searchResultsFormatted);

        setVesselsTotalPageCount(
          Math.ceil(res.data.vessels.paging.total / numberOfNewRows)
        );
        setObjectTotalPageCount(
          Math.ceil(res.data.objects.paging.total / numberOfNewRows)
        );
      } catch (err) {
        console.log(err, "err");
      } finally {
        setLoadingSpinnerIsShown(false);
      }
    };
    if (searchTermRefValue !== null) {
      getNewOptions();
    }
    return () => {
      abortController.abort();
    };
  }, [currentPage, searchResultsTableRef, searchTermRefValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedFilter]);

  const currentDisplayedResults =
    selectedFilter.filterType === FilterType.Vessel
      ? currentSearchResults?.vessels
      : currentSearchResults?.objects;

  const currentResultsCount =
    selectedFilter.filterType === FilterType.Vessel
      ? currentSearchResults?.vesselsTotal
      : currentSearchResults?.vesselsTotal;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {loadingSpinnerIsShown ? (
        <CircularProgress
          sx={{ color: "#ffffff", margin: "auto" }}
          size={100}
        />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ flex: 1, backgroundColor: "#24263C" }}
          ref={searchResultsTableRef}
        >
          <Table
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="search-results-table"
          >
            <TableHead>
              <AllSearchReusltHeader
                columns={columns.map((column) => column.title)}
              />
            </TableHead>
            <TableBody>
              {currentDisplayedResults.map((row: any, rowIndex: number) => (
                <StyledTableRow key={JSON.stringify(row)} onClick={() => loadShipsOnMap(true, row.synmax_ship_id)}>
                  {columns.map((column: any, columnIndex) => {
                    if (column.field === "flag" && row[column.field]) {
                      return (
                        <StyledTableCell align="right">
                          <img
                            src={`https://flagcdn.com/48x36/${row[
                              column?.field
                            ].toLowerCase()}.png`}
                            width="30"
                            alt=""
                          />
                        </StyledTableCell>
                      );
                    }
                    if (
                      column.field === "name" ||
                      column.field === "acquired"
                    ) {
                      return (
                        <StyledNameCell component="th" scope="row">
                          <SynMaxIcon icon={icon} size={20} />
                          <div className={styles.allResultsRowName}>
                            {row[column.field]}
                          </div>
                        </StyledNameCell>
                      );
                    }
                    return (
                      <StyledTableCell
                        align={columnIndex === 0 ? "left" : "right"}
                      >
                        {row[column.field] === null
                          ? "No info"
                          : row[column.field]}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className={styles.allResultsPagination}>
        <Pagination
          count={
            selectedFilter.filterType === FilterType.Vessel
              ? vessselTotalPageCount
              : objectTotalPageCount
          }
          page={currentPage}
          classes={{
            root: styles.pagination,
            ul: styles.paginationNavigationOption,
          }}
          variant="outlined"
          shape="rounded"
          size="large"
          showFirstButton
          showLastButton
          onChange={(event, page) => {
            setCurrentPage(page);
          }}
        />
        <div className={styles.pageLabel}>Page</div>
        <TextField
          type="number"
          style={{
            backgroundColor: "white",
            color: "white",
          }}
          inputProps={{
            style: {
              height: "10px",
              width: "60px",
              color: "#ffffff",
              backgroundColor: "#000000",
            },
          }}
          value={jumoToPageValue}
          onChange={(event) => {
            setJumpToPageValue(event.target.value);
          }}
        />
        <JumpToPageButton
          variant="contained"
          color="primary"
          onClick={() => {
            const newPageValue = parseInt(jumoToPageValue);
            if (newPageValue <= currentResultsCount) {
              setCurrentPage(parseInt(jumoToPageValue));
            } else {
              setCurrentPage(currentResultsCount);
            }
          }}
        >
          Go
        </JumpToPageButton>
      </div>
    </div>
  );
};

const AllSearchResultsDialog: React.FC<{
  showResultsDialogIsOpen: boolean;
  handleCloseAllResultsDialog: () => void;
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  setSelectedFilter: (newState: FilterOption) => void;
  currentSearchValue: string;
  searchTermRefValue: string | null;
  loadShipsOnMap: any;
  currentSearchCounts: { vesselsTotal: number; objectsTotal: number };
}> = ({
  showResultsDialogIsOpen,
  handleCloseAllResultsDialog,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  currentSearchValue,
  searchTermRefValue,
  currentSearchCounts,
  loadShipsOnMap
}) => {
  return (
    <Draggable>
      <Dialog
        open={showResultsDialogIsOpen}
        onClose={handleCloseAllResultsDialog}
        PaperProps={{
          style: {
            backgroundColor: "#24263C",
            borderRadius: 0,
          },
        }}
        componentsProps={{
          backdrop: {
            style: { backgroundColor: "transparent" },
          },
        }}
        className={styles.allResultsDialog}
        maxWidth="md"
        fullWidth
        disableRestoreFocus
      >
        <DialogTitle className={styles.dialogTitle}>
          <div className={styles.dialogTitleHeader}>
            <h3 className={styles.dialogTitleText}>
              Search Results For "{searchTermRefValue}"
            </h3>
            <IconButton
              sx={{ ml: "auto", color: "#ffffff" }}
              onClick={handleCloseAllResultsDialog}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>

          <div className={styles.dialogContentButtons}>
            {filterOptions.map((option: FilterOption) => {
              const selectedButtonColor =
                selectedFilter === option
                  ? { borderBottom: "2px solid #ffffff", color: "#ffffff" }
                  : { borderBottom: "1px solid gray", color: "gray" };
              return (
                <SelectFilterButton
                  type="button"
                  variant="text"
                  sx={selectedButtonColor}
                  onClick={() => {
                    setSelectedFilter(option);
                  }}
                  key={JSON.stringify(option)}
                >
                  {`${option.filterType} (${
                    option.filterType === "Vessels"
                      ? currentSearchCounts.vesselsTotal
                      : currentSearchCounts.objectsTotal
                  })`}
                </SelectFilterButton>
              );
            })}
            <div className={styles.dialogContentDivider}></div>
          </div>
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <AllResultsTable
            searchTermRefValue={searchTermRefValue}
            selectedFilter={selectedFilter}
            loadShipsOnMap={() => loadSearchShipOnMap(selectedFilter.filterType === FilterType.Vessel, currentSearchValue, loadShipsOnMap)}
            columns={
              selectedFilter.filterType === FilterType.Vessel
                ? [
                    { title: "Name", field: "name" },
                    { title: "Ctry", field: "flag" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "MMSI", field: "mmsi" },
                    { title: "Call Sign", field: "call_sign" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
                : [
                    { title: "Acquired", field: "acquired" },
                    { title: "Type", field: "ship_type" },
                    { title: "IMO", field: "imo" },
                    { title: "Status", field: "status" },
                    { title: "Provider", field: "provider" },
                    { title: "Length", field: "length" },
                    { title: "Width", field: "width" },
                  ]
            }
            icon={
              selectedFilter.filterType === FilterType.Vessel
                ? "shipBow"
                : "satellite"
            }
            currentSearchValue={currentSearchValue}
          />
        </DialogContent>
      </Dialog>
    </Draggable>
  );
};

const loadSearchShipOnMap = (isAISSearch: any, searchValue: any, loadShipsOnMap: any) => {
  const reqObj = {} as any

  if(isAISSearch) {
    reqObj.synmax_ship_id = searchValue
  } else {
    reqObj.object_id = searchValue
  }
  loadShipsOnMap(isAISSearch, reqObj)
}

export default AllSearchResultsDialog;

import React from "react";

const PathAnalysisIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3.5"
        cy="12.5"
        r="1.5"
        transform="rotate(-90 3.5 12.5)"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="12.5"
        cy="3.5"
        r="1.5"
        transform="rotate(-90 12.5 3.5)"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="9.5"
        cy="6.5"
        r="1.5"
        transform="rotate(-90 9.5 6.5)"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="6.5"
        cy="9.5"
        r="1.5"
        transform="rotate(-90 6.5 9.5)"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PathAnalysisIcon;

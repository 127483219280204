import React from "react";
import "./spinner.scss";

const Spinner = () => {
  return (
    <>
      <div className="backdrop"></div>
      <div className="iconBody">
        <svg
          id="spinner"
          xmlns="http://www.w3.org/2000/svg"
          width="499.565"
          height="497.48"
          viewBox="0 0 499.565 497.48"
        >
          <g id="global-communication" transform="translate(-5.999 505.48)">
            <g id="Group_18" data-name="Group 18">
              <path
                id="Path_36"
                data-name="Path 36"
                d="M404.5-505c-1.1.4-7.7,6.6-14.7,13.7-12.9,13.1-14,14.9-12.2,19.8.7,1.8,5.4,4.5,7.9,4.5.8,0,3.6-1.9,6.1-4.2l4.5-4.1,5,5.6c2.8,3.2,5.4,5.7,5.8,5.7s3.2-2.2,6.1-4.9l5.3-4.9-2.1-2.8a49.854,49.854,0,0,0-5.6-6.3l-3.5-3.4,3.9-4.3c4.7-5.1,5.2-8.9,1.8-12.1C409.9-505.3,407.1-506.1,404.5-505Z"
                fill="#23263c"
              />
              <path
                id="Path_37"
                data-name="Path 37"
                d="M233.7-502.8c-4.1,3.2-3.9,11.5.3,13.8,1,.6,10.2,1,20.3,1,17.1,0,18.6-.1,21.1-2.1,2-1.6,2.6-2.9,2.6-5.9s-.6-4.3-2.6-5.9c-2.5-2-4-2.1-21.3-2.1C240.7-504,234.9-503.6,233.7-502.8Z"
                fill="#23263c"
              />
              <path
                id="Path_38"
                data-name="Path 38"
                d="M190.9-495.5c-9.2,2.4-17.6,4.8-18.8,5.5-5.1,2.7-4.5,9.8,1.1,12.9,2.5,1.4,3.7,1.3,16.5-1.8,22-5.4,23.1-5.8,24.8-9.1,1.4-2.6,1.4-3.4.1-6.5a8.71,8.71,0,0,0-4.1-4.5,10.289,10.289,0,0,0-2.8-.9C207.6-499.9,200-497.9,190.9-495.5Z"
                fill="#23263c"
              />
              <path
                id="Path_39"
                data-name="Path 39"
                d="M296.1-497.3a10.115,10.115,0,0,0-2.7,5c-1.1,5.6,1.9,7.3,20.6,12.1,9.1,2.3,17.8,4.2,19.3,4.2,8,0,9.1-12.4,1.3-14.9-8.6-2.8-30.5-8.1-33.2-8.1A10.107,10.107,0,0,0,296.1-497.3Z"
                fill="#23263c"
              />
              <path
                id="Path_40"
                data-name="Path 40"
                d="M429.7-464.2,407-441.5l16.8,16.8C433-415.5,441-408,441.5-408c1.3,0,44.5-43.2,44.5-44.5,0-.6-7.5-8.6-16.8-17.8L452.5-487Z"
                fill="#23263c"
              />
              <path
                id="Path_41"
                data-name="Path 41"
                d="M131-471.5c-8,4.6-15.3,9.4-16.2,10.6-4.1,5-.6,12.9,5.8,12.9,3.2,0,30.7-15.6,33.7-19.1,3.9-4.8.1-12.9-6-12.9C146.6-480,139.8-476.7,131-471.5Z"
                fill="#23263c"
              />
              <path
                id="Path_42"
                data-name="Path 42"
                d="M90.4-442.1c-2.1,1.5-8.5,7.6-14.1,13.4-10.5,10.8-11.7,13-9.7,18.2.9,2.2,6.2,4.8,8.8,4.1,1.1-.3,8.1-6.5,15.4-13.9,14.7-14.9,15.5-16.5,10.3-21.8C97.4-445.7,95.3-445.7,90.4-442.1Z"
                fill="#23263c"
              />
              <path
                id="Path_43"
                data-name="Path 43"
                d="M374.2-423.4c-5.7,1.5-11.2,4-11.2,5.2,0,1,53.3,54.2,54.3,54.2.4,0,2-2.6,3.5-5.8,2.3-5,2.7-7,2.7-15.7,0-8.9-.3-10.7-2.8-16-3.2-6.8-11.3-15.7-17.2-18.7A40.355,40.355,0,0,0,374.2-423.4Z"
                fill="#23263c"
              />
              <path
                id="Path_44"
                data-name="Path 44"
                d="M469-413.5l-5.4,5.5,5.9,6,5.8,5.9-4.1,4.5c-4.6,5-4.9,6.3-2.7,10.6,1.6,3.2,5.1,4.6,8.8,3.6,1.2-.3,8-6.4,15.1-13.5,14.3-14.3,15.4-16.5,10.6-21.6-3.6-3.8-7.6-3.3-12.6,1.6l-4.1,4-5.2-6c-2.9-3.4-5.6-6.1-6-6.1S472-416.5,469-413.5Z"
                fill="#23263c"
              />
              <path
                id="Path_45"
                data-name="Path 45"
                d="M50.2-396.4c-1.2.8-6.1,8.1-10.9,16.2-9.9,16.7-10.6,19.8-5.8,23.9,2.2,1.9,3.5,2.3,5.9,1.9,4.2-.9,5.1-2,14.6-18.1,9.4-15.9,10.2-19.2,5.6-23.1C56.4-398.4,53.4-398.6,50.2-396.4Z"
                fill="#23263c"
              />
              <path
                id="Path_46"
                data-name="Path 46"
                d="M369.7-381.1c-4.5,5-5,9.6-1.4,12.9,3.6,3.4,8,2.9,12.8-1.6l4-3.7-5.9-5.8-5.9-5.8Z"
                fill="#24a0ca"
              />
              <path
                id="Path_49"
                data-name="Path 49"
                d="M478.2-341c-4.8,2-4.8,4.5-.1,23.7,4.8,19.5,6.3,22.3,11.9,22.3,3.4,0,8-3.8,8-6.6,0-2.7-8.1-33.7-9.6-36.5C486.5-342,483.1-342.9,478.2-341Z"
                fill="#23263c"
              />
              <path
                id="Path_50"
                data-name="Path 50"
                d="M21.7-338.8c-1.7,1.7-3.5,7-6.7,20.1-4.2,16.4-4.4,17.9-3,20.4,3.1,5.4,11.6,5.1,13.9-.5,1.9-4.9,8.3-31.6,8.3-34.6C34.1-339.9,26.2-343.3,21.7-338.8Z"
                fill="#23263c"
              />
              <path
                id="Path_52"
                data-name="Path 52"
                d="M9.7-278.8A11.7,11.7,0,0,0,7-274.7c-1.4,3.8-1.3,33.5.1,37.6,1.9,5.4,7.5,6.8,12.3,3l2.6-2v-39.8l-2.6-2C16.4-280.3,12.2-280.6,9.7-278.8Z"
                fill="#23263c"
              />
              <path
                id="Path_53"
                data-name="Path 53"
                d="M489.7-278.8a11.7,11.7,0,0,0-2.7,4.1c-1.4,3.8-1.3,33.5.1,37.6,1.9,5.4,7.5,6.8,12.3,3l2.6-2v-39.8l-2.6-2C496.4-280.3,492.2-280.6,489.7-278.8Z"
                fill="#23263c"
              />
              <path
                id="Path_55"
                data-name="Path 55"
                d="M486.4-216.8c-3,1.4-3.8,3.6-8.5,22.1-3.8,15.4-4,16.3-2.5,19.4a7.246,7.246,0,0,0,13,.4c2.4-4.5,9.8-35.2,9.1-37.8-.5-2.2-5.1-5.4-7.7-5.2A9.46,9.46,0,0,0,486.4-216.8Z"
                fill="#23263c"
              />
              <path
                id="Path_56"
                data-name="Path 56"
                d="M14.2-216c-4.6,1.9-4.6,4.8.3,23.8,4.8,19,6,21.2,11.7,21.2,3.1,0,7.8-3.8,7.8-6.4,0-2.3-6.9-31.6-8.2-34.9C24.1-216.7,19.6-218.1,14.2-216Z"
                fill="#23263c"
              />
              <path
                id="Path_57"
                data-name="Path 57"
                d="M465.1-155.3c-3.7,3.9-19.1,30.5-19.1,33.2,0,1.2,1.4,3.6,3,5.3,2.6,2.5,3.6,2.9,6.3,2.4,4.3-.9,5.2-2,14.6-18,9.1-15.4,9.9-18.9,5.6-23.1C472.3-158.8,468.5-158.7,465.1-155.3Z"
                fill="#23263c"
              />
              <path
                id="Path_58"
                data-name="Path 58"
                d="M92.2-144.3c-2.4,5.2-2.7,7-2.7,16.3,0,9.9.2,11,3.2,17.1a41.2,41.2,0,0,0,19.1,18.7c5,2.4,6.9,2.7,16.2,2.7,9.4,0,11.1-.3,16.5-2.8l5.9-2.8L123-122.5C107.9-137.6,95.4-150,95.2-150S93.7-147.4,92.2-144.3Z"
                fill="#23263c"
              />
              <path
                id="Path_59"
                data-name="Path 59"
                d="M133.2-145.1a28.5,28.5,0,0,0-4.7,4.1c-.4.6,1.9,3.5,5.2,6.6l5.8,5.5,3.8-4c4.5-4.9,4.9-9,1.2-12.6C141.3-148.8,138.2-148.6,133.2-145.1Z"
                fill="#24a0ca"
              />
              <path
                id="Path_60"
                data-name="Path 60"
                d="M34.5-136c-1.1.4-7.5,6.4-14.2,13.2C6.6-109,5.1-106.2,9.2-101.7c3.9,4.4,6.8,4.4,12.4-.1l4.9-4,5.7,5.6L38-94.6l5.5-5.4,5.5-5.4L44.7-110c-7.6-8-7.5-7.6-3.2-12,4.8-4.9,5.2-9.2,1.1-12.7C39.8-137.1,37.8-137.4,34.5-136Z"
                fill="#23263c"
              />
              <path
                id="Path_61"
                data-name="Path 61"
                d="M49.7-83.2,27-60.5,44.3-43.2,61.5-26,84.3-48.8,107-71.5,89.7-88.8,72.5-106Z"
                fill="#23263c"
              />
              <path
                id="Path_62"
                data-name="Path 62"
                d="M432.5-105c-1.1.4-7.7,6.6-14.7,13.7C407-80.4,405-77.8,405-75.3c0,4.7,3,7.7,7.6,7.6,3.5-.1,4.8-1.2,17.1-13.5C440.8-92.5,443-95.1,443-97.6,443-102.6,436.9-106.9,432.5-105Z"
                fill="#23263c"
              />
              <path
                id="Path_63"
                data-name="Path 63"
                d="M371-55.5c-8,4.6-15.3,9.4-16.2,10.6-4.6,5.5,0,13.7,7,12.5,4.1-.6,31.8-17,33.2-19.5,2.7-5.2-1.1-12.1-6.7-12.1C386.6-64,379.8-60.7,371-55.5Z"
                fill="#23263c"
              />
              <path
                id="Path_64"
                data-name="Path 64"
                d="M100.1-44.6l-5.4,5.5,5.1,5.6c2.9,3.1,5.2,6,5.2,6.4s-1.6,2.2-3.5,4.1c-4.5,4.4-4.9,9.7-.9,12.9,5.1,4,6.7,3.2,21.6-11.7,15-15,15.9-16.7,11.6-21.7-3.6-4.1-8-3.7-12.9,1.1L117-38.6l-5.8-5.7L105.4-50Z"
                fill="#23263c"
              />
              <path
                id="Path_65"
                data-name="Path 65"
                d="M172.5-34.8c-2.7,1.5-3.5,2.8-3.5,6.1,0,6.3,1.5,7.1,23.7,12.7,14.5,3.6,18.3,3.8,20.8,1,2.6-2.9,2.5-8.8-.2-11-2.1-1.6-33.2-10-37-10A9.841,9.841,0,0,0,172.5-34.8Z"
                fill="#23263c"
              />
              <path
                id="Path_66"
                data-name="Path 66"
                d="M312.6-31.5c-12.4,3.3-16.4,4.7-17.9,6.6-3.6,4.5.2,12.3,6.1,12.1,5.5-.1,35.5-8.5,37.4-10.4,4.4-4.4.2-12.9-6.4-12.7C330-35.8,321.3-33.8,312.6-31.5Z"
                fill="#23263c"
              />
              <path
                id="Path_67"
                data-name="Path 67"
                d="M233.7-22.8c-4.1,3.2-3.9,11.5.3,13.8,1,.6,10.2,1,20.3,1,17.1,0,18.6-.1,21.1-2.1,2-1.6,2.6-2.9,2.6-5.9s-.6-4.3-2.6-5.9c-2.5-2-4-2.1-21.3-2.1C240.7-24,234.9-23.6,233.7-22.8Z"
                fill="#23263c"
              />
            </g>
          </g>
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="230.324"
          height="230.348"
          viewBox="0 0 230.324 230.348"
          id="earthIcon"
        >
          <g
            id="world_1_"
            data-name="world (1)"
            transform="translate(-0.238 511.669)"
          >
            <path
              id="Path_68"
              data-name="Path 68"
              d="M138.8-511.548a160.869,160.869,0,0,0-19.541,3.377A117.607,117.607,0,0,0,75.04-485.883c-3.827,3.107-16.209,15.4-15.849,15.714.09.09,3.6-.856,7.79-2.071a104.611,104.611,0,0,1,18.326-3.782l10.671-1.486,4.593-2.567,4.638-2.567,5.268,1.441,5.268,1.486,8.465-.405,8.51-.405,2.972-4.5a24.657,24.657,0,0,1,3.377-4.5c.225,0,2.251.27,4.458.63,3.647.54,5.088.5,14.138-.405l10.086-.946,2.927-3.2a50.847,50.847,0,0,1,6.844-5.763l3.917-2.612,5.763.856c3.872.585,6.349.765,7.564.54l1.846-.36-3.332-1.531a126.563,126.563,0,0,0-29.942-8.42A195.742,195.742,0,0,0,138.8-511.548Zm29.4,6.844a17.868,17.868,0,0,1,3.467,2.251c0,.315-8.78,7.069-10.266,7.925a31.861,31.861,0,0,1-5.763.9l-5.043.54-2.341-1.531-2.341-1.486,3.287-2.071c4.368-2.7,14.994-8.645,15.264-8.555C164.6-506.685,166.263-505.784,168.2-504.7Zm-47.548,6.484,2.386.991,3.377-1.081a13.066,13.066,0,0,1,3.512-.856,42.043,42.043,0,0,1,3.332,6.124c-.135.09-3.647,1.081-7.79,2.161L117.86-488.9l-3.737-2.116-3.737-2.116,1.441-1.126c.765-.63,2.476-1.981,3.737-3.017a18.834,18.834,0,0,1,2.521-1.891A16.739,16.739,0,0,1,120.651-498.22Z"
              transform="translate(-32.405 0)"
              fill="#23263c"
            />
            <path
              id="Path_69"
              data-name="Path 69"
              d="M267.376-457.614v2.341l4.548,4.5c5.313,5.268,10.761,11.3,10.761,11.932a8.291,8.291,0,0,1-2.7,1.8c-2.7,1.351-2.747,1.351-5.043.675-1.261-.36-3.917-1.306-5.853-2.116l-3.512-1.441v-5.989l-2.341-.946c-1.306-.5-3.422-1.306-4.683-1.8l-2.3-.946-1.531,6.754-1.531,6.709-4.458.991c-2.431.54-4.5,1.261-4.638,1.576a15.484,15.484,0,0,0,.18,3.827l.36,3.287,6.079,1.171c3.332.63,6.124,1.171,6.169,1.126s.54-2.7,1.081-5.9c.54-3.242,1.036-5.9,1.081-5.988.045-.045,2.3.225,5,.63,3.6.54,5.538,1.081,7.2,2.026,2.071,1.171,2.747,1.306,6.214,1.306h3.872l2.521,5.043c2.386,4.773,2.927,5.448,9.5,12.247l6.934,7.2-.27,1.846c-.63,3.872-.135,3.692-7.2,2.747l-4.5-.585-9.5,4.773-9.546,4.773-6.934,8.33c-6.934,8.33-6.979,8.375-7.835,11.932l-.9,3.6-2.116.135c-1.8.135-2.972-.225-7.114-2.116l-4.953-2.251-3.917,1.936c-2.161,1.036-4.187,2.026-4.458,2.206-.36.225-.27,1.261.315,3.557.45,1.8.946,3.872,1.126,4.638l.315,1.351,1.936-2.251,1.936-2.251,3.467-.135,3.467-.18-.315,4.277-.315,4.323,2.792.81c2.521.675,3.062,1.081,5.718,4.007l2.882,3.2,4.548-1.306,4.5-1.306,4.818.72c2.612.405,7.835,1.531,11.572,2.521,3.737.946,8.06,1.891,9.636,2.071a7.31,7.31,0,0,1,3.557.991c.405.405,2.792,3.107,5.358,5.988l4.638,5.223,9.861,5.808c5.448,3.152,9.861,5.988,9.816,6.214-.09.27-2.972,5.9-6.439,12.472L309.97-297.5l-6.8,3.107-6.754,3.107-2.657,7.069-2.657,7.069-9.455,6.394c-5.223,3.512-9.636,6.529-9.906,6.664-.54.405-2.521,7.339-2.116,7.519.54.225,8.33-2.116,13.283-4.007,39.488-14.9,67.99-50.7,73.393-92.259a114.428,114.428,0,0,0-10.4-65.243c-2.927-6.078-7.609-14-8.285-14a6.228,6.228,0,0,0-1.486.405,90.747,90.747,0,0,1-9.816,1.171,86.474,86.474,0,0,0-9.636,1.126c-.5.18-2.026,2.071-3.332,4.232-1.351,2.116-2.476,3.872-2.521,3.872s-.946-.315-2.026-.675c-1.261-.45-4.142-2.7-8.915-7.024l-7.069-6.349-1.846-5.268c-3.2-9.05-3.152-8.96-8.06-13.373l-4.5-4.052-4.953-.991c-2.7-.54-5.223-.991-5.493-.991C267.556-460,267.376-459.235,267.376-457.614Z"
              transform="translate(-126.763 -28.404)"
              fill="#23263c"
            />
            <path
              id="Path_70"
              data-name="Path 70"
              d="M20.617-388.127c-1.216.315-2.792.72-3.467.855-1.351.315-3.332,3.647-6.844,11.437a114.707,114.707,0,0,0-5.9,77.67,116.491,116.491,0,0,0,19.271,38.993c4.187,5.583,16.435,17.83,22.063,22.063,23.1,17.245,51.42,25.53,78.661,22.963,2.837-.27,5.223-.54,5.313-.63.09-.045-.135-3.152-.45-6.889l-.54-6.8,2.611-10.806,2.612-10.806-2.566-9.14c-2.431-8.645-2.792-9.591-6.844-17.11l-4.232-7.925,3.6-7.609c2.026-4.187,3.647-7.79,3.647-8.06s-1.576-.946-3.467-1.531c-3.377-.946-3.692-1.171-8.015-5.448l-4.5-4.413-9.546-2.116-9.5-2.161-3.2-6.529-3.2-6.574v7.925L84.69-320.9c-1.306-.135-2.161-1.126-9.681-11.3l-8.24-11.167v-18.416l-5.9-9.591c-3.242-5.223-5.988-9.681-6.169-9.816-.18-.18-4.548.5-9.771,1.441-8.1,1.486-10.4,1.756-15.894,1.756H22.6l-3.152-2.026a14.12,14.12,0,0,1-3.107-2.341,32.122,32.122,0,0,1,3.917-3.422C24.265-388.938,24.31-389.073,20.617-388.127Z"
              transform="translate(0 -67.633)"
              fill="#23263c"
            />
          </g>
        </svg>
      </div>
    </>
  );
};

export default Spinner;

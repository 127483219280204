import React, { Dispatch, SetStateAction, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Link } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import styles from "../Auth.module.scss";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router";
import { useSnackbar } from "../../../context/SnackbarContext";
import { useSearchParams } from "react-router-dom";

const EmailCheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setSnackbarMessage, setSnackbarSeverity, setIsSnackBarOpen } =
    useSnackbar();
  const auth = getAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  const sendResetEmail = () => {
    if (!email) return;
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        setSnackbarMessage("Reset Email Sent!");
        setSnackbarSeverity("success");
        setIsSnackBarOpen(true);
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMessage = error.message;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setIsSnackBarOpen(true);
      });
  };

  return (
    <Box className={styles.loginDetail}>
      <h5 className={styles.loginDetailHead}>Check Your Email</h5>
      <Box className={styles.loginDetailDescription}>
        We've sent a password reset link to: {email}
      </Box>
      <form className={styles.detailInputWrapper}>
        <LoadingButton
          variant="outlined"
          className={styles.buttonWrap}
          loading={isLoading}
          type="submit"
        >
          Open Email App
        </LoadingButton>
        {email && (
          <p className={styles.accountWrapper}>
            Didn't receive the email?
            <Link
              onClick={sendResetEmail}
              className={styles.inputLink}
              underline="none"
            >
              Click to Resend
            </Link>
          </p>
        )}
        <Box
          className={styles.backButtonWrapper}
          onClick={(e) => {
            navigate("/login");
          }}
        >
          <ArrowBackOutlined sx={{ fill: "#fff" }} />
          <Link className={styles.inputLink} underline="none">
            Back to Log In
          </Link>
        </Box>
      </form>
    </Box>
  );
};

export default EmailCheck;

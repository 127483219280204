import { Box } from "@mui/material";
import { Clear, Minimize } from "@mui/icons-material";
import styles from "./Header.module.scss";

interface HeaderProps {
  headerTitle: string;
  handleClose?: any;
  onClickMinimize?: any;
  showMinimize?: boolean;
}

const Header = ({
  headerTitle,
  handleClose,
  onClickMinimize,
  showMinimize = true,
}: HeaderProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={styles.modelHeadWrap}
    >
      <h6 className={styles.modelHead}>{headerTitle}</h6>
      <Box display="flex" alignItems="center" gap={3}>
        {showMinimize && (
          <Minimize
            fontSize="small"
            className={styles.headerIcon}
            onClick={onClickMinimize}
          />
        )}
        <Clear
          fontSize="small"
          onClick={handleClose}
          className={styles.headerIcon}
        />
      </Box>
    </Box>
  );
};

export default Header;

import { api } from "../../../../services/api";

export const fetchFuturePathPrediction = async (data: any) => {
  const response = await api.post("/ship-tools", data);
  return response.data;
};

export const fetchFuturePathProgress = async (data: any) => {
  const response = await api.post("/ship-tools/path-progress", data);
  return response.data;
};

export const fetchFuturePath = async (data: any) => {
  const response = await api.post("/ship-tools/path-progress", data);
  return response.data;
};

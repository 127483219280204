import ShipBowIcon from "../../assets/icons/shipBowIcon.svg";
import SatelliteIcon from "../../assets/icons/satelliteIcon.svg";

export type IconType = "shipBow" | "satellite";

const SynMaxIcon: React.FC<{ icon: IconType; size: number }> = ({
  icon,
  size,
}) => {
  function getIcon() {
    switch (icon) {
      case "shipBow": {
        return ShipBowIcon;
      }
      case "satellite": {
        return SatelliteIcon;
      }
    }
  }

  return <img src={getIcon()} alt={icon} height={`${size}px`} />;
};

export default SynMaxIcon;

import React from "react";

const PlayBackIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 3.32569C3.3335 2.67825 3.3335 2.35453 3.46849 2.17608C3.58609 2.02062 3.76584 1.92442 3.96043 1.9128C4.18379 1.89947 4.45314 2.07903 4.99184 2.43817L12.0022 7.11175C12.4473 7.4085 12.6699 7.55688 12.7475 7.74389C12.8153 7.90739 12.8153 8.09115 12.7475 8.25465C12.6699 8.44167 12.4473 8.59004 12.0022 8.88679L4.99184 13.5604C4.45314 13.9195 4.18379 14.0991 3.96043 14.0857C3.76584 14.0741 3.58609 13.9779 3.46849 13.8225C3.3335 13.644 3.3335 13.3203 3.3335 12.6729V3.32569Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PlayBackIcon;

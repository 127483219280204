import React from "react";
import styles from "./Table.module.scss";
import {
  TableContainer,
  TableHead,
  TableRow,
  Table as MUITable,
  TableCell,
  TableBody,
} from "@mui/material";

interface TableProps {
  rows: { [key: string]: string | number | React.ReactNode }[];
  columns: string[];
  onRowClick: (id: string, type: string, tabIndex?: string) => void;
  type: string;
}

const Table: React.FC<TableProps> = ({ rows, columns, onRowClick , type}) => {
  return (
    <TableContainer
      sx={{
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <MUITable stickyHeader className={styles.tableWrap} size="small">
        <TableHead className={styles.tableHeader}>
          <TableRow className={styles.tableHeaderRow}>
            {columns.map((column, index) => (
              <TableCell
                className={styles.tableHeaderCell}
                sx={{
                  borderRadius:
                    index === 0
                      ? "4px 0 0 4px"
                      : index === columns.length - 1
                      ? "0 4px 4px 0"
                      : "none",
                }}
                align="left"
                key={index}
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              className={styles.tableRow}
              onClick={() => onRowClick(row.id as string, type, row.tabIndex as string)}
            >
              {columns.map((column, colIndex) => (
                <TableCell
                  className={styles.tableCell}
                  key={colIndex}
                  align="left"
                >
                  {row[column as keyof typeof row]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

export default Table;

import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./MainSearch.module.scss";
import { debounce } from "lodash";
import SearchOptionRow from "./SearchOptionRow";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AllSearchResultsDialog from "./AllSearchResultsDialog";
import axios from "axios";
import SynMaxIcon from "../../Shared/SynMaxIcon";
import CircularProgress from "@mui/material/CircularProgress";
import { frontendAPIURL } from "../../../App";
import { MapContext, ShipDetailsInterFace } from "../MainMap";
import { useContext } from "react";
import { getShipInfo, getShipPath } from "../RenderShips/apiCalls";
import moment from "moment";

const SEARCH_OPTIONS_LIMIT = 5;

const filterOptions = (options: any, state: any): OptionType[] => {
  return options;
};

export enum FilterType {
  Vessel = "Vessels",
  Object = "Satellite Images",
}

export interface FilterOption {
  filterType: FilterType;
  value: string;
}

const defaultOptions: FilterOption[] = [
  { filterType: FilterType.Vessel, value: "shipId" },
  { filterType: FilterType.Object, value: "objectId" },
];

const initialSearchResultsState: CurrentSearchResults = {
  vessels: [],
  objects: [],
  vesselsTotal: 0,
  objectsTotal: 0,
};

export interface ObjectSearchOptionResult {
  name?: string;
  acquired?: string;
  dark?: boolean;
  heading?: number;
  imo?: number;
  latitude?: number;
  length?: number;
  longitude?: number;
  mmsi?: number;
  moving?: boolean;
  object_id?: string;
  provider?: string;
  ship_type?: string;
  spoofing?: any;
  status?: string;
  sts?: any;
  synmax_ship_id?: string;
  width?: number;
}

export interface VesselSearcOptionResult {
  name?: string;
  call_sign?: string;
  imo?: string;
  length?: number;
  width?: number;
  mmsi: number;
  ship_type?: string;
  flag?: string;
}

export interface CurrentSearchResults {
  vessels: VesselSearcOptionResult[];
  objects: ObjectSearchOptionResult[];
  vesselsTotal: number;
  objectsTotal: number;
}

type OptionType = VesselSearcOptionResult | ObjectSearchOptionResult;

const MainSearch: React.FC<{
  searchIsExpanded: boolean;
  handleSearchExpand: (newState: boolean) => void;
}> = ({ searchIsExpanded, handleSearchExpand }) => {
  const {
    setShipDetailTabValue,
    setSelectedShip,
    getUniqueColor,
    setShipPaths,
    updateActiveStatus,
    setSelectedEvent,
    onShipClick,
    setShipDetails,
    setMapViewState
  } = useContext(MapContext);
  const [showResultsDialogIsOpen, setShowResultsDialogIsOpen] =
    useState<boolean>(false);

  const [loadingSpinnerIsShown, setLoadinSpinnerIsShown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption>(
    defaultOptions[0]
  );
  const [searchDropdownIsOpen, setSearchDropdownIsOpen] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState("");

  const searchTermRefValue = useRef<string | null>(null);
  const [currentSearchResults, setCurrentSearchResults] =
    useState<CurrentSearchResults>(initialSearchResultsState);

  function handleCloseAllResultsDialog(): void {
    setShowResultsDialogIsOpen(false);
  }

  const getOptionsDelayed = useCallback(
    debounce((query) => {
      getNewOptions(query);
    }, 200),
    []
  );

  useEffect(() => {
    setSelectedFilter(defaultOptions[0]);
  }, []);

  const getNewOptions = async (searchValue: string) => {
    setLoadinSpinnerIsShown(true);
    searchTermRefValue.current = searchValue;
    const token = localStorage.getItem("token");

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}searchShips?q=${searchValue}&limit=${SEARCH_OPTIONS_LIMIT}&offset=0`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const searchReslutsFormatted: CurrentSearchResults = {
        vessels: res.data.vessels.results.map((item: any) => item.attributes),
        objects: res.data.objects.results.map((item: any) => ({object_id: item.object_id, ...item.attributes})),
        vesselsTotal: res.data.vessels.paging.total,
        objectsTotal: res.data.objects.paging.total,
      };
      setCurrentSearchResults(searchReslutsFormatted);
      setLoadinSpinnerIsShown(false);
    } catch (err) {
      setLoadinSpinnerIsShown(false);
      console.log(err, "err");
    }
  };

  const currentSearchHasNoResults =
    currentSearchResults.vesselsTotal === 0 &&
    currentSearchResults.objectsTotal === 0;

  const getSearchInputEndAdronment = () => {
    if (currentSearchValue.length > 0 && !loadingSpinnerIsShown) {
      return (
        <IconButton
          aria-label="clear search value"
          onMouseDown={(event) => {
            event.preventDefault();
            setCurrentSearchValue("");
            setCurrentSearchResults(initialSearchResultsState);
          }}
          className={styles.searchClearButton}
        >
          <CloseIcon className={styles.searchClearIcon} fontSize="small" />
        </IconButton>
      );
    } else if (loadingSpinnerIsShown) {
      return <CircularProgress sx={{ color: "#ffffff" }} size={10} />;
    }
  };

  const loadShipsOnMap = async (isAISSearch: boolean, searchValue: any) => {
    const token = localStorage.getItem("token");
  
    if (isAISSearch) {
      const shipId = searchValue.synmax_ship_id;
      onShipClick();
      const res = await axios.post(
        `${frontendAPIURL}/live_ships`,
        { id: "frontend", shipids: [shipId] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const { timestamp,latitude,longitude} = res.data[0];
      setMapViewState({ latitude,longitude, zoom: 6 })
      setShipDetailTabValue(shipId);
      setSelectedShip((prevShips: any) => ({
        ...prevShips,
        [shipId]: {},
      }));
      const shipDetails = await getShipInfo(
        [shipId],
        moment(timestamp * 1000).format("YYYY-MM-DD")
      );
      const startTimestamp = timestamp * 1000 - 24 * 60 * 60 * 1000;
      const startDate = `${moment
        .utc(startTimestamp)
        .format("YYYY-MM-DD HH:mm:ss")}`;
      const endDate = `${moment
        .utc(timestamp * 1000)
        .format("YYYY-MM-DD HH:mm:ss")}`;
      const shipPath = await getShipPath([shipId], startDate, endDate);
      const shipPathColor = getUniqueColor();
      setShipPaths((prev: any) => ({
        ...prev,
        [shipId]: {
          path: shipPath,
          color: shipPathColor,
          analysis: false,
          dateRange: { startDate, endDate },
        },
      }));
      const updatedShipDetails = {
        ...shipDetails,
        [shipId]: {
          ...res.data[0],
          ...shipDetails[shipId],
          type: "AIS",
          active: true,
        },
      };
      setSelectedShip((prevShips: any) => ({
        ...updateActiveStatus(prevShips, shipId),
        ...updatedShipDetails,
      }));
      setSelectedEvent((prevEvents: any) => ({
        ...prevEvents,
        ...updateActiveStatus(prevEvents, shipId),
      }));
    } else {

      const res = await axios.post(
        `${frontendAPIURL}/objects`,
        { id: "frontend", objects: [searchValue.object_id] },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const data = res.data[0];
      setMapViewState({ latitude: data.latitude, longitude: data.longitude, zoom: 6 })

      // return res;
      let shipCategory;

      if (data.attribution === "None") {

        onShipClick();
        const shipId = `unattributed-${data.object_id}`;
        setShipDetailTabValue(shipId);
        setSelectedShip((prevShips: any) => ({
          ...(prevShips as { [key: string]: ShipDetailsInterFace }),
          [shipId]: {} as ShipDetailsInterFace,
        }));
        const updatedShipDetails = {
          [shipId]: {
            ...data,
            synmax_ship_id: shipId,
            active: true,
            type: "unattributed"
          },
        };
        setSelectedShip((prevShips: any) => ({
          ...updateActiveStatus(prevShips, shipId),
          ...updatedShipDetails,
        }));
        setSelectedEvent((prevEvents: any) => ({
          ...prevEvents,
          ...updateActiveStatus(prevEvents, shipId),
        }));

      } else if (data.attribution && !data.dark) {
        onShipClick();
        const shipId = data.attribution;
        if (shipId) {
          setShipDetailTabValue(shipId);
          setSelectedShip((prevShips: any) => ({
            ...prevShips,
            [shipId]: {},
          }));
          const startTimestamp =
          data.acquired -
            24 * 60 * 60 * 1000;
          const dateSting = moment
            .utc( data.acquired)
            .format("YYYY-MM-DD");
          const shipDetails = await getShipInfo([shipId], dateSting);
          setShipDetails((prev: any) => ({
            ...prev,
            ...shipDetails,
          }));
          const startDate = `${moment
            .utc(startTimestamp)
            .format("YYYY-MM-DD HH:mm:ss")}`;
          const endDate = `${moment
            .utc(data.acquired)
            .format("YYYY-MM-DD HH:mm:ss")}`;
          const shipPath = await getShipPath(
            [shipId],
            startDate,
            endDate
          );
          const shipPathColor = getUniqueColor();
          setShipPaths((prev: any) => ({
            ...prev,
            [shipId]: {
              path: shipPath,
              color: shipPathColor,
              analysis: false,
              dateRange: { startDate, endDate },
            },
          }));
          const updatedShipDetails = {
            ...shipDetails,
            [shipId]: {
              ...data,
              ...shipDetails[shipId],
              type: 'light',
              active: true,
            },
          };
          setSelectedShip((prevShips: any) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
          setSelectedEvent((prevEvents: any) => ({
            ...prevEvents,
            ...updateActiveStatus(prevEvents, shipId),
          }));
        }
     
      } else if (data.attribution && data.dark) {
        onShipClick();
        const shipId = data.attribution;
        if (shipId) {
          setShipDetailTabValue(shipId);
          setSelectedShip((prevShips: any) => ({
            ...prevShips,
            [shipId]: {},
          }));
          const startTimestamp =
          data.acquired -
            24 * 60 * 60 * 1000;
          const dateSting = moment
            .utc( data.acquired)
            .format("YYYY-MM-DD");
          const shipDetails = await getShipInfo([shipId], dateSting);
          setShipDetails((prev: any) => ({
            ...prev,
            ...shipDetails,
          }));
          const startDate = `${moment
            .utc(startTimestamp)
            .format("YYYY-MM-DD HH:mm:ss")}`;
          const endDate = `${moment
            .utc(data.acquired)
            .format("YYYY-MM-DD HH:mm:ss")}`;
          const shipPath = await getShipPath(
            [shipId],
            startDate,
            endDate
          );
          const shipPathColor = getUniqueColor();
          setShipPaths((prev: any) => ({
            ...prev,
            [shipId]: {
              path: shipPath,
              color: shipPathColor,
              analysis: false,
              dateRange: { startDate, endDate },
            },
          }));
          const updatedShipDetails = {
            ...shipDetails,
            [shipId]: {
              ...data,
              ...shipDetails[shipId],
              type: 'attributed',
              active: true,
            },
          };
          setSelectedShip((prevShips: any) => ({
            ...updateActiveStatus(prevShips, shipId),
            ...updatedShipDetails,
          }));
          setSelectedEvent((prevEvents: any) => ({
            ...prevEvents,
            ...updateActiveStatus(prevEvents, shipId),
          }));
        }
      }

      console.log(shipCategory, ':shipCategory')
    }
  };

  return (
    <div className={styles.mainSearchBar}>
      <Collapse
        orientation="horizontal"
        in={searchIsExpanded}
        collapsedSize={50}
        timeout={400}
      >
        <Autocomplete
          ListboxProps={{ style: { maxHeight: "450px", overflow: "hidden" } }}
          inputValue={currentSearchValue}
          id="main-search"
          options={
            selectedFilter.filterType === FilterType.Vessel
              ? currentSearchResults.vessels
              : currentSearchResults.objects
          }
          filterOptions={filterOptions}
          getOptionLabel={(option: any) => option.name}
          className={styles.searchAutoComplete}
          open={searchDropdownIsOpen}
          onOpen={() => {
            setSearchDropdownIsOpen(true);
          }}
          onClose={() => {
            setSearchDropdownIsOpen(false);
          }}
          onInputChange={async (event, newInputValue) => {
            if (event?.type === "change") {
              setCurrentSearchValue(newInputValue);
              if (newInputValue.length > 2) {
                getOptionsDelayed(newInputValue);
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search by name, IMO, ShipId, objectId or MMSI"
              variant="outlined"
              className={styles.searchTextField}
              onClick={() => {
                if (!searchIsExpanded) {
                  handleSearchExpand(true);
                }
              }}
              onBlur={() => {
                handleSearchExpand(false);
              }}
              onChange={() => {
                if (!searchIsExpanded) {
                  handleSearchExpand(true);
                }
              }}
              sx={{ input: { color: "#fff", width: "100%" } }}
              size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={styles.searchIcon}
                  >
                    <SearchOutlined
                      fontSize="small"
                      onClick={() => {
                        if (!searchDropdownIsOpen && searchIsExpanded) {
                          setSearchDropdownIsOpen(false);
                          handleSearchExpand(false);
                        } else if (searchDropdownIsOpen) {
                          setSearchDropdownIsOpen(false);
                          handleSearchExpand(false);
                        } else {
                          setSearchDropdownIsOpen(true);
                          handleSearchExpand(true);
                        }
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: getSearchInputEndAdronment(),
              }}
              fullWidth
            />
          )}
          renderOption={(props: any, value: any) => {
            if (selectedFilter.filterType === FilterType.Vessel) {
              return (
                <SearchOptionRow
                  key={JSON.stringify(value)}
                  searchValue={value}
                  isAISSearch={true}
                  loadShipsOnMap={loadShipsOnMap}
                  columns={[
                    { title: "Vessel Type", data: value.ship_type },
                    {
                      title: "IMO",
                      data: value.imo,
                    },
                    {
                      title: "MMSI",
                      data: value.mmsi,
                    },
                    { title: "Call Sign", data: value.name },
                    { title: "Length", data: value.length },
                    { title: "Width", data: value.width },
                  ]}
                  rowHeader={
                    <>
                      <SynMaxIcon icon="shipBow" size={20} />
                      <div className={styles.searchItemName}>{value.name}</div>
                      <img
                        src={`https://flagcdn.com/48x36/${value?.flag?.toLowerCase()}.png`}
                        width="30"
                        alt=""
                      />
                    </>
                  }
                  props={props}
                />
              );
            }
            if (selectedFilter.filterType === FilterType.Object) {
              return (
                <SearchOptionRow
                  key={JSON.stringify(value)}
                  isAISSearch={false}
                  searchValue={value}
                  loadShipsOnMap={loadShipsOnMap}
                  columns={[
                    { title: "Vessel Type", data: value.ship_type },
                    { title: "IMO", data: value.imo },
                    { title: "Status", data: value.status },
                    { title: "Provider", data: value.provider },
                    { title: "Length", data: value.length },
                    { title: "Width", data: value.width },
                  ]}
                  rowHeader={
                    <>
                      <SynMaxIcon icon="satellite" size={20} />
                      <div className={styles.searchItemName}>
                        {value.acquired}
                      </div>
                    </>
                  }
                  props={props}
                />
              );
            }
          }}
          PaperComponent={({ children }) => {
            return (
              <Paper
                sx={{
                  width: "100%",
                  backgroundColor: "#000000",
                  color: "#fff",
                }}
              >
                <div className={styles.searchHeader}>
                  {defaultOptions.map((option) => {
                    const selectedButtonColor =
                      selectedFilter === option
                        ? {
                            borderBottom: "2px solid #ffffff",
                            color: "#ffffff",
                          }
                        : { borderBottom: "1px solid gray", color: "gray" };
                    return (
                      <Button
                        key={option.filterType}
                        type="button"
                        variant="text"
                        className={styles.searchFilterButton}
                        sx={selectedButtonColor}
                        onMouseDown={(event) => {
                          event.preventDefault();
                          setSelectedFilter(option);
                        }}
                      >
                        {`${option.filterType} (${
                          option.filterType === "Vessels"
                            ? currentSearchResults.vesselsTotal
                            : currentSearchResults.objectsTotal
                        })`}
                      </Button>
                    );
                  })}
                  <div className={styles.headerDivider}></div>
                </div>
                {children}
                {currentSearchHasNoResults &&
                !loadingSpinnerIsShown &&
                currentSearchValue.length > 2 ? (
                  <div className={styles.noResultsFound}>
                    We were not able to find a match
                  </div>
                ) : null}
                {(selectedFilter.filterType === FilterType.Vessel &&
                  currentSearchResults.vessels.length > 0) ||
                (selectedFilter.filterType === FilterType.Object &&
                  currentSearchResults.objects.length > 0) ? (
                  <div className={styles.showAllResults}>
                    <Link
                      component="button"
                      variant="body2"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        setShowResultsDialogIsOpen(true);
                      }}
                      className={styles.showResultsLink}
                    >
                      <div className={styles.showAllResultsText}>
                        See all results (
                        {selectedFilter.filterType === FilterType.Vessel
                          ? currentSearchResults.vesselsTotal
                          : currentSearchResults.objectsTotal}
                        )
                      </div>
                      <ArrowForwardIcon fontSize="small" />
                    </Link>
                  </div>
                ) : null}
              </Paper>
            );
          }}
        />
      </Collapse>
      <AllSearchResultsDialog
        showResultsDialogIsOpen={showResultsDialogIsOpen}
        handleCloseAllResultsDialog={handleCloseAllResultsDialog}
        currentSearchValue={currentSearchValue}
        filterOptions={defaultOptions}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        searchTermRefValue={searchTermRefValue.current}
        currentSearchCounts={{
          vesselsTotal: currentSearchResults.vesselsTotal,
          objectsTotal: currentSearchResults.objectsTotal,
        }}
        loadShipsOnMap={loadShipsOnMap}
      />
    </div>
  );
};

export default MainSearch;
